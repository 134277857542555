import { useState } from "react"
import { Modal } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import useData, { Currency, Spinner } from "../utils/data"
import { money } from "../utils"
import { useToast } from "../utils/toast"
import Util from "../utils/util"

type Model = {
    sku: string,
    quantity: number,
    address: string,
    addressIndex: number,
    stateId: number,
    total: money,
    notes: string
}

type order = {
    quantity: number,
    subtotal: number,
    deliveryAmount: number,
    total: money
}

export default function OrderSummary(props: any) {
    const util = Util()
    const toast = useToast()
    const data = useData()
    const { register, watch, handleSubmit, formState: { errors } } = useForm<Model>()
    const wallet = props.wallet
    const product = props.selectedProduct
    const addresses = props.addresses
    const addressIndex = watch("addressIndex")
    const address = (addressIndex > -1) ? addresses[addressIndex] : addresses[0]
    let order: order = { quantity: 0, subtotal: 0, deliveryAmount: 0, total: { value: 0, currency: wallet?.curr } }
    const [errorMessage, setErrorMessage] = useState('')
    const INSUFFICIENT_WALLET_BALANCE = 'Insufficient wallet balance.'

    // compute order details and total
    if (product) {
        order.quantity = props?.selectedProduct?.quantity
        order.subtotal = product.amount * order.quantity
        order.deliveryAmount = address?.state?.deliveryAmount
        order.total = { value: order.subtotal + order.deliveryAmount, currency: wallet?.curr }
    }

    const handleNewAddress = () => {
        if (props.setShowOrderSummary) props.setShowOrderSummary(false)
        if (props.setShowAddress) props.setShowAddress(true)
    }

    const onSubmit: SubmitHandler<Model> = model => {
        setErrorMessage('')
        if (addresses.length === 0) {
            toast.show('No shipping address found. Please add an address.', 'error')
            if (props.setShowAddress) props.setShowAddress(true)
            return false;
        }
        const address = addresses[model.addressIndex]
        model.sku = product.sku
        model.quantity = product.quantity
        model.address = address.line1
        model.stateId = address.state?.id
        model.total = order.total

        console.log('model', model)

        data.post(process.env.REACT_APP_API_ORDERS, model, (resp: any) => {
            console.log(wallet.balance)
            if (wallet.balance < order.total.value) {
                setErrorMessage(INSUFFICIENT_WALLET_BALANCE)
                return false
            }
            // place order
            if (resp && resp.errors) {
                Object.keys(resp.errors).map(key => resp.errors[key].map((error: string) => setErrorMessage(error)))
            }
            else if (resp && resp.data) {
                toast.show("Thanks for your order. We will let you know immediately it begins processing.", 'success')

                // close order summary
                props.setShowOrderSummary(false)
            }

        })
    }

    const closeModal = () => {
        setErrorMessage('')
        props.setShowOrderSummary(false)
    }

    return (
        <Modal show={props.showOrderSummary} onHide={() => closeModal()} className="p-2" centered>
            <Modal.Header closeButton>
                <Modal.Title className="p-4">Order summary</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-5 pt-4">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <p className="mb-3">
                        Please confirm your order
                    </p>
                    <div className="mb-3">
                        <label className="form-label">{order.quantity} {order.quantity > 1 ? ' Items' : ' Item'}</label>
                        {
                            product && <div className="row">
                                <div className="col-12 fw-bold">{product.name}</div>
                                <div className="col-6">Quantity <small className="form-text">x</small> {order.quantity}</div>
                                <div className="col-6 text-end">{util.money(order.subtotal)}</div>
                                <div className="col-6">Shipping</div>
                                <div className="col-6 text-end">{util.money(address?.state?.deliveryAmount)} </div>
                                <div className="col-6">Total <small className="form-text">plus VAT</small></div>
                                <div className="col-6 text-end"><Currency symbol={wallet?.currency?.html} />{util.money(order.total.value)} </div>
                                <div className="form-text text-end d-none">VAT Inclusive</div>
                            </div>
                        }
                        {
                            !product && <div>No product selected.</div>
                        }

                    </div>
                    <div className="mb-3">
                        <div className="input-group">
                            <select id="state" className="form-select" {...register("addressIndex", {
                                required: 'Choose an address'
                            })} aria-label="Shipping address select with button for new address.">
                                {
                                    addresses.map((item: any, index: number) => {
                                        if (index === 0) return <option key={index} value={index}>Ship to: {item.tag}</option>
                                        return <option key={index} value={index}>{item.tag}</option>
                                    })
                                }
                            </select>
                            <button className="btn btn-outline-secondary" type="button" onClick={() => handleNewAddress()}>New</button>
                            {errors.addressIndex && <small className="text-danger">{errors.addressIndex.message}</small>}
                        </div>
                    </div>
                    <div className="mb-3">
                        <span>{address?.line1} {address?.state?.name}.</span>
                        <div className="fw-bold">{address?.mobile}</div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="notes" className="form-label">Notes</label>
                        <input type="text" id="notes" className="form-control" {...register("notes")} />
                        {errors.notes && <small className="text-danger">{errors.notes.message}</small>}
                        {!errors.notes && <div className="form-text">Any other thing we should note?</div>}

                    </div>
                    {
                        errorMessage &&
                        <div className="mb-4">
                            <small className="text-danger">{errorMessage}</small> {errorMessage === INSUFFICIENT_WALLET_BALANCE && <Link to="/fund">Fund my wallet</Link>}
                        </div>
                    }

                    <div className="d-grid gap-2">
                        <button type="submit" className="btn btn-primary" disabled={data.isBusy()}>
                            Okay, finish <Spinner visibility={data.isBusy()} isCentered={false} />
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}