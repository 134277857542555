import { Link, useHistory, useParams } from "react-router-dom";
import DashNav from "../home/dashnav";
import { useState } from "react";
import { useEffect } from "react";
import useData, { Spinner } from "../utils/data";
import PreviewBanter from "./previewbanter";
import useAnalytics from "../utils/analytics";
import FindBanter from "./findbanter";
import { NavActions, Tabs } from "../utils/enum";
import BanterGroup from "./bantergroup";
import { useToast } from "../utils/toast";
import { bantter, response } from "../utils";
import { useTheme } from "../context/theme";
import Util, { Pluralize } from "../utils/util";
import { FaGem } from "react-icons/fa";
import { useAuth } from "../context/auth";
import { useFavTeam } from "../home/favteam";
import BanterButton from "../home/banterbutton";
import { useStartBanter } from "./startbanter2";
import { usePopup } from "../utils/popup";
import Messages from "../utils/messages";

export default function Banters() {

    const util = Util()
    const themeContext = useTheme()
    const theme = themeContext.theme
    const favTeamPop = useFavTeam()
    const startBanter = useStartBanter()
    const messages =  Messages()

    const auth = useAuth()
    const authContext = auth.context
    const favTeam = authContext.favTeam
    let user = authContext.getUser()
    const history = useHistory()

    const data = useData()
    const isBusy = data.isBusy();
    let title = ""
    const toast = useToast()
    const popup = usePopup()
    const analytics = useAnalytics();
    const [banters, setBanters] = useState<bantter[]>([])
    const [selectedBanter, setSelectedBanter] = useState<bantter>()
    const [recentBanters, setRecentBanters] = useState<bantter[]>([])
    const [recentCount, setRecentCount] = useState(0)
    const [viewedBanterStrs, setviewedBanterStrs] = useState<string[]>([])

    let [count, setCount] = useState(0)
    const pageSize = 2
    const [query, setQuery] = useState('')

    const [showPreviewBanter, setShowPreviewBanter] = useState(false)
    const [showFindBanter, setShowFindBanter] = useState(false)
    const [activeTab, setActiveTab] = useState(Tabs.First)
    const [isSubscribed, setIsSubscribed] = useState(true)
    const { banterRef, action } = useParams<any>()
    const { tab, refresh } = useParams<any>()

    //const randomShareIcon = Math.floor(Math.random() * 2) === 1 ? <FaWhatsapp /> : <FaTwitter />
    const [userPoints, setUserPoints] = useState<number | undefined>()
    //const worldCupChallengeInfo = 'Simply use the Refer Friends option to invite new friends to sign up. If your friends win, you both win trophies. You must all have social media on your Fantastake profile for the verification to be completed. Good luck!'
    let dailyBanterCount = parseInt(data.getCookie('dailyBanterCount'))
    if(dailyBanterCount > 2) dailyBanterCount = 2
    const dailyShareCount = parseInt(data.getCookie('dailyShareCount'))

    const switchTab = (tab: Tabs)=>{

        if(activeTab === tab) return

        // set active tab
        setActiveTab(tab)

        if(tab === Tabs.Second){
            fetchRecentBanters(0, pageSize)
        }

        // clear search
        //clearSearch()
    }

    const handleStartNewBanter = ()=>{

        // if user email is not verified,
        // redirect to verify email

        if(!user?.profile?.isEmailVerified){

            popup.show('You need to complete your signup to start a banter.')
            history.push('/profile')
            return

        }else if(!favTeam && favTeamPop){

            // User must have set a fav team
            favTeamPop.show()
            return
        }

        //setShowNewBanter(true)

        startBanter.show()
    }

    const clearSearch = () => {
        setQuery('')
        fetchBanters(0, pageSize, '')
    }

    const handleBanterClick = (banter: bantter) => {

        console.log('banter', banter)
        console.log('banter.id', banter.id)

        setSelectedBanter(banter)

        // Register new banter view
        if(banter.id){
            let views = banter.views < 1 ? 1 : banter.views
            analytics.banterView(banter.id, () => setSelectedBanter({ ...banter, views: views }))
            //setShowPreviewBanter(true)
        }

        // Only remove banter if the first tab is active

        if(activeTab !== Tabs.First) removeBanter(banter)
    }

    // const updateRecentBanters = (item: bantter) => {
    //     if (!item) return

    //     let newRecentBanters = [item]
    //     for(let i = 0; i < recentBanters.length; i++){
    //         newRecentBanters.push(recentBanters[i])
    //         if(newRecentBanters.length === pageSize) break;
    //     }

    //     setRecentBanters(newRecentBanters)
    // }

    const fetchRecentBanters = (skip: number, pageSize: number) => {
        const url = process.env.REACT_APP_API_RECENTBANTERS + '?skip=' + skip + '&pageSize=' + pageSize
        data.get(url, (resp: response<any>) => {
            if (resp && resp.data && resp.data.items) {
                let banters = resp.data.items
                if(isSubscribed){
                    setRecentCount(resp.data.count)
                    setRecentBanters(banters)
                }
            }
        })
    }

    const fetchBanters = (skip: number, pageSize: number, query: string = '') => {
        const url = process.env.REACT_APP_API_BANTERS + '?skip=' + skip + '&pageSize=' + pageSize + '&query=' + query
        data.get(url, (resp: response<any>) => {
            let banters = resp?.data?.items
            if(banters && isSubscribed){
                setBanters(banters)
                setviewedBanterStrs(banters.map((b : bantter) => b.id?.toString() ))
                setCount(resp?.data?.count)

                // fetch user recent banters
                fetchRecentBanters(0, pageSize)
            }
        }, () => { })
    }

    const popUpBanter = ()=>{
        if(banterRef && isSubscribed){
            const url = process.env.REACT_APP_API_BANTERS + '?banterref=' + banterRef
            data.get(url, (resp: any) => {
                if (resp) {
                    if (resp.errors) {
                        let error: any = Object.values(resp.errors)[0]
                        toast.show(error, 'error');
                    } else if(resp.data.count === 1) {
                        let banter = resp.data.items[0]
                        if(banter) handleBanterClick(banter)
                    }
                }
            })
        }
    }

    const removeBanter = (banter:bantter)=>{

        if(!banter) return

        // remove the selected banter
        let reducedBanters : bantter[] = []
        for(let i = 0; i < banters.length; i++){
            if(i + 1 < banters.length){
                reducedBanters.push(banters[i + 1])
            }
        }

        setBanters(reducedBanters)

        // Replace the removed banter

        fetchUserNextBanter(reducedBanters)
    }

    const fetchUserNextBanter = (banters: bantter[])=>{

        if(banters.length === 0) return

        let viewedBanters = viewedBanterStrs.join()
        //console.log('viewedBanters', viewedBanterStrs)

        let url = process.env.REACT_APP_API_USERSNEXTBANTER +'?previousbanterids=' + viewedBanters

        data.get(url, (resp:any)=>{
            if(resp && resp.data){
                let updateBanters = [...banters]
                if(resp.data.length >= 1){
                    let nextBanter = resp.data[0]
                    // Replace whatever the last banter is with the next banter
                    updateBanters[updateBanters.length] = nextBanter
                    viewedBanterStrs.push(nextBanter.id.toString())
                }

                setBanters(updateBanters)
            }
        })
    }

    const init = ()=>{

        // fetch fan banters
        fetchBanters(0, pageSize, query)

        // pop up a banter
        popUpBanter()

        // start a new banter
        if(action === NavActions.StartBanter.toString()) handleStartNewBanter()
    }

    useEffect(() => {
        if(tab){
            // switch to appropriate tab
            switchTab(parseInt(tab))
        }

        return () => {
            // unsubscribe to prevent updating state if unmounted
            setIsSubscribed(false)
        }

    }, [refresh]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        document.title = process.env.REACT_APP_WEBSITE_TITLE + util.titleSeparator() + "Banters"
    })

    return (
        <div className="mb-5">
            <DashNav title={title} initCallback={()=>init()} pointsCallback={(points : number)=>setUserPoints(points)}>
                {/* {
                    (favTeam && favTeam?.name) &&
                    <div>
                        <Link to="#" onClick={()=>favTeamPop?.show()}><img src={favTeam?.logo} style={{ width: 50 }} className="mb-3" alt="team logo" loading="lazy" /></Link>
                        <h4 className="mb-4">
                            I <i className="bi bi-heart-fill fs-5" aria-hidden="true" /> <Link to="#" onClick={()=> { return favTeamPop?.show() }}>{favTeam.name}</Link>
                        </h4>
                    </div>
                } */}

                <div className="list-group mb-4">

                    <Link to="#" onClick={()=>popup.show(messages.soonerFixtureTip,'Secret tip')} className="list-group-item list-group-item-action active" aria-current="true">

                        <div className="d-flex justify-content-between">
                            <div className="flex-fill">Steps to earn 3 points</div>
                            <i className="bi bi-info-circle-fill"></i>
                        </div>
                        
                    </Link>                    

                    <Link to="#" onClick={() => startBanter.startNewBanter()} className={"ps-2 pt-3 pb-0 list-group-item " + theme.color}>
                        <div className="border border-0 d-flex justify-content-between">

                            <div>
                                <i className={"me-2 bi " + (dailyBanterCount >= 2 ? 'bi-check-circle text-success' : 'bi-1-circle text-danger') }></i>
                            </div>

                            <div className="list-group flex-fill">
                                <span className={"list-group-item p-0 " + theme.color} aria-current="true">
                                    <div>
                                        <span className={"text-decoration-underline text-light " + theme.color}>Start 2 banters</span> everyday
                                    </div>
                                    { 
                                        dailyBanterCount > 0 && 
                                        <small>You started {dailyBanterCount} today</small>
                                    }
                                </span>
                            </div>

                        </div>

                    </Link>
                    
                    <Link to="#" onClick={() => startBanter.startNewBanter()} className={"d-none pt-3 fs-5 list-group-item " + theme.color}>
                        <span><i className={"me-2 bi " + (dailyBanterCount >= 2 ? 'bi-check-circle text-success' : 'bi-1-circle text-danger') }></i>
                        <span className={"text-light " + theme.color}>Start</span> 2 banters everyday</span>{ dailyBanterCount > 0 && <span>. {dailyBanterCount} of 2</span> }
                    </Link>
                    <div className={"ps-2 list-group-item " + theme.color}>
                        <i className={"me-2 bi " + (dailyShareCount >= 1 ? 'bi-check-circle text-success' : 'bi-2-circle text-danger')}></i><span className="me-2">Share with a friend</span>
                    </div>
                    {
                        // dailyShareCount >= 1 && dailyBanterCount >= 2 &&
                        // <div className={"ps-2 fs-5 list-group-item " + theme.color}>
                        //     <i className="me-2 bi bi-check-circle text-success"></i><Link to="/league">You got 3 points</Link> today
                        // </div>
                    }

                    {
                        dailyShareCount >= 1 && dailyBanterCount >= 2 &&
                        <div className="ms-2 border border-0 d-flex justify-content-between">

                            <div>
                                <i className={"me-2 bi " + (dailyBanterCount >= 2 ? 'bi-check-circle text-success' : 'bi-1-circle text-danger') }></i>
                            </div>

                            <div className="list-group flex-fill">
                                <span className={"list-group-item p-0 " + theme.color} aria-current="true">
                                    <div>
                                        <Link to="/league">You got 3 points</Link> today
                                    </div>
                                    { 
                                        dailyBanterCount > 0 && 
                                        <small>Good job!</small>
                                    }
                                </span>
                            </div>

                        </div>
                    }                    

                </div>

                <p className="mb-3 pb-3 d-none">
                    Brag about <Link to="#" onClick={()=>favTeamPop.show()}>any team</Link>, share it with friends to earn points and rank on the <Link to="/league">Heroes League</Link>.
                </p>

                {
                    userPoints !== undefined && userPoints === 0 &&
                    <p>
                        No points yet? Start your first banter this week for  a bonus point <FaGem className="fs-6 text-warning" />
                    </p>
                }

                <div className="row">

                {
                    <div className="col-12">
                        <div className="d-flex justify-content-between">
                            <div className="flex-grow-1">
                                <ul className={'nav nav-tabs'} id="bantertabs" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button onClick={() => switchTab(Tabs.First)} className={activeTab === Tabs.First ? 'nav-link active text-light ' + theme.background + ' ' + theme.color : 'nav-link text-light ' + theme.color} data-bs-toggle="tab" data-bs-target="#fansbanters" type="button" role="tab" aria-controls="fansbanters" aria-selected="true">
                                            Fan Banters
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button onClick={() => switchTab(Tabs.Second)} className={activeTab === Tabs.Second ? 'nav-link text-light ' + theme.background + ' ' + theme.color : 'nav-link text-light ' + theme.color} data-bs-toggle="tab" data-bs-target="#mybanters" type="button" role="tab" aria-controls="mybanters" aria-selected="false">
                                            Your Banters
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <ul className="nav nav-tabs d-none" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" onClick={()=>setShowFindBanter(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                        </svg>
                                    </button>
                                </li>
                            </ul>

                        </div>

                        {
                            query !== '' &&
                            <div className="list-group" aria-label="Banter search results found">
                                <div className={"pt-3 ps-0 pe-0 list-group-item border-top-0 border-bottom-0 list-group-item-action " + theme.background}>
                                    <div className="d-flex justify-content-between">
                                        <Link className={"nav-link active " + theme.color} to="#">
                                            {count > 1 ? count + ' search results for ' + query : count + ' result for ' + query}
                                        </Link>
                                        <Link className={"nav-link active " + theme.color} onClick={() => clearSearch()} to="#">
                                            clear search
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="tab-content pt-3">

                            <div className={activeTab === Tabs.First ? 'tab-pane fade show active' : 'tab-pane fade'} id="fansbanters" role="tabpanel" aria-labelledby="fansbanterstab">

                                {/* List of banters by Fans */}                                

                                {
                                    banters.length === 0 &&
                                    <div className="col-12 text-center">
                                        <div className="mt-5 ms-5 me-5 mb-5 pt-5 pb-5 lh-1">
                                            
                                            <Spinner visibility={isBusy}></Spinner>

                                            {
                                                isBusy === false &&
                                                <>Banters for this week will show up here.</> 
                                            }
                                            
                                            {/* You can <Link to="#" onClick={() => handleStartNewBanter()}>start a banter</Link> too. */}
                                        </div>
                                    </div>
                                }

                                <BanterGroup
                                    banters={banters}
                                    handleBanterClick={handleBanterClick}
                                    banterPosted={startBanter.banterPosted}
                                    setBanterPosted={startBanter.setBanterPosted}
                                    count={count}
                                    removeBanter={removeBanter}
                                    showCount={1}
                                    showNextArrow={true} />

                            </div>

                            <div className={activeTab === Tabs.Second ? 'tab-pane fade show active' : 'tab-pane fade'} id="mybanters" role="tabpanel" aria-labelledby="mybanterstab">

                                {
                                    recentBanters.length === 0 &&
                                    <div className="col-12 text-center">
                                        <div className="mt-5 ms-5 me-5 mb-5 pt-5 pb-5 lh-1">

                                            <Spinner visibility={isBusy}></Spinner>

                                            {
                                                isBusy === false &&
                                                <>When you <Link to="#" onClick={() => handleStartNewBanter()}>start a banter</Link>, your own recent banters will show up here.</>
                                            }                                            

                                        </div>
                                    </div>
                                }

                                {/* List of banters by You */}

                                <BanterGroup
                                    banters={recentBanters}
                                    handleBanterClick={handleBanterClick}
                                    setRecentBanters={setRecentBanters}
                                    fetchRecentBanters={fetchRecentBanters}
                                    recentCount={recentCount}
                                    banterPosted={startBanter.banterPosted}
                                    setBanterPosted={startBanter.setBanterPosted}
                                    count={count}
                                    handleStartNewBanter={handleStartNewBanter}  />
                            </div>

                        </div>

                    </div>
                }
                </div>

                {
                    //count > pageSize &&
                }

                {
                    activeTab === Tabs.First && banters.length > 0 && 
                    <div className='p-3 pt-0  text-center'>
                        {count} upcoming <Pluralize count={count} plural="banters" single="banter"/>
                    </div>
                }

                {
                    // <div className="col-sm-12 mt-4 text-center">
                    //     <Link to="#">
                    //         <Share
                    //             channel={ShareChannel.Whatsapp}
                    //             showShareIcon={false}
                    //             placeChildrenRight={false}
                    //             message={"Join the Fantastake Heroes League Challenge. Sign up now using my link https://"+ process.env.REACT_APP_URL + '/signup/'  + user?.profile?.referralCode}>
                    //                 <span className="me-2">Invite a friend</span>
                    //         </Share>
                    //         <span className="ms-3 d-none"><Share
                    //             channel={ShareChannel.Twitter}
                    //             message={"Join the #FantastakeHeroes League Challenge. Sign up now using my @fantastakeo link https://"+ process.env.REACT_APP_URL + '/signup/'  + user?.profile?.referralCode}>
                    //         </Share></span>

                    //     </Link>
                    // </div>
                }

                {/* {
                    (count >= pageSize || recentCount >= pageSize)  &&
                    <nav className={count <= pageSize ? '' : 'mt-3'} aria-label="Banters navigation">
                        <ul className="pagination justify-content-center">
                            <li className="page-item">
                                <Link to="#" className={"page-link " + theme.background} onClick={() => goNext(-1)}><Spinner visibility={data.isBusy() && previousStep === -1} /> Back</Link>
                            </li>
                            <li className="page-item">
                                <Link to="#" className={"page-link " + theme.background} onClick={() => goNext(1)}>Next <Spinner visibility={data.isBusy() && previousStep === 1} /></Link>
                            </li>
                        </ul>
                    </nav>
                } */}

            </DashNav>

            <FindBanter
                showFindBanter={showFindBanter}
                setShowFindBanter={setShowFindBanter}
                fetchBanters={fetchBanters}
                setQuery={setQuery} />

            <PreviewBanter selectedBanter={selectedBanter}
                showPreviewBanter={showPreviewBanter}
                setShowPreviewBanter={setShowPreviewBanter}
                removeBanter={removeBanter} />

            <BanterButton callback={switchTab} banterStarted={showPreviewBanter} />

        </div>
    )
}