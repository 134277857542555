import React, { useContext } from "react"
import { useState } from "react"
import { team, userr } from "../utils"
import { Cookie } from "../utils/cookie"

const cookie = Cookie()

// Get user from cookie token

const getUser = (token?:string | undefined) => {
    
    if(!token) token = cookie.getCookie('token')
    //let userPoints = cookie.getCookie('points');
    let parsedUser : userr | undefined;
    if(token) {
        let userFromToken = cookie.parseJwt(token)
        let avatartFile = userFromToken.avatar_file
        let avatar = process.env.REACT_APP_API_AVATARBASEURL + userFromToken.sex?.toLowerCase() + '/' + avatartFile + '.svg?background=%237D36D2&mouth=variant05'
        parsedUser = { 
            id : parseInt(userFromToken.id),
            username: userFromToken.unique_name,
            profile: { 
                firstName : userFromToken.given_name,
                surname : userFromToken.family_name,
                isEmailVerified : userFromToken.is_email_verified,
                instagram : userFromToken.instagram,
                twitter : userFromToken.twitter,
                sex :  userFromToken.sex,
                avatarFile : avatartFile,
                avatar : avatar,
                referralCode : userFromToken.referral_code,
                mobile : userFromToken.mobile,
                countryCode: userFromToken.country_code
            }
        }
    }
    
    return parsedUser;
}

const appendSex = (sex: string)=>{
    
    if(sex){
        sex = sex.toString()
        if(sex === '1') return 'female/'
        if(sex === '2') return 'male/'
    }
    
    return ''
}

const logout = (callback: Function) => {
    //window.localStorage.clear()
    cookie.expireCookie('token')
    cookie.expireCookie('refreshToken')
    cookie.expireCookie('points')   

    // deprecated cookies
    cookie.expireCookie('lastcouponwhatsapp')
    cookie.expireCookie('lastmobilecountrycode')

    if(callback) callback()
}

type auth = { 
    getUser: Function,
    favTeam: team|undefined,
    //getFavTeam : Function | undefined,
    //updateAuthFavTeam: Function,
    logout: Function,    
    appendSex : Function,
 }

const configureAuth = () => {
    let authContext : auth | any = {  // change type from 'auth' to 'any'
        user: getUser(),
        getUser: getUser,
        favTeam: undefined,
        getFavTeam : () => authContext.favTeam,
        // updateAuthFavTeam: (favTeam: team)=>{
        //     if(favTeam) authContext.favTeam = favTeam
        // },
        logout : logout,
        appendSex : appendSex
    }

    return authContext
}

let authContext = configureAuth()

// Create the auth context

const AuthContext = React.createContext(authContext);

// Custom hook for auth provider

export const useAuth = ()=>{
    
    return useContext(AuthContext)
}

export default function AuthProvider({ children } : any){    
    
    const [context, setAuthContext] = useState(authContext)

    const updateContext = (newContext:any)=>{
        setAuthContext(newContext)
    }

    const updateWallet = (debitAmount:number, user:userr)=>{
        if(debitAmount){

            let walletBalance = user.wallet.balance - +(debitAmount * 100).toFixed(0)
            if(walletBalance < 0){
                walletBalance = user.wallet.balance
            }
            
            const updatedUser = { 
                ...user, 
                wallet: { ...user.wallet, balance : walletBalance, friendlyBalance: (walletBalance/100).toLocaleString() } 
            }
            const updatedContext = { ...authContext, user : updatedUser }
            updateContext(updatedContext)
        }        
    }

    return(
        <AuthContext.Provider value={{ context, updateContext, updateWallet }}>
            {children}
        </AuthContext.Provider>
    )
}