export enum ShareChannel {
    Whatsapp = "Whatsapp",
    Twitter = "Twitter"
}

export enum Tabs{
    First, Second
}

export enum FanActivity{
    Support = 1,
    Challenge = 2,
    Share = 3,
    StartBanter = 4
}

export enum LeaderBoardCode {
    Today = "Today",
    LastWeek = "LastWeek"
}

export enum NavActions{
    StartBanter = 1
}

export enum Themes {
    Dark = 1,
    Light = 2
}

export enum Sex {
    Female = 1,
    Male = 2
}