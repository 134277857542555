import useData, { Spinner } from "../utils/data";
import { SubmitHandler, useForm } from "react-hook-form";
import { useToast } from "../utils/toast";
import Util from "../utils/util";
import { useTheme } from "../context/theme";
import { usePopup } from "../utils/popup";
import { useAuth } from "../context/auth";
import { ShareChannel } from "../utils/enum";
import Share from "../utils/share";
import { Link } from "react-router-dom";
import { billerr } from "../utils";
import { ButtonGroup, Dropdown, InputGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Cookie } from "../utils/cookie";

type Model = {
    billerCode?: string,
    productId?: number,
    mobile: string,
    amount?: number,
    product?: string,
    productField?: string,
    bundleIndex: number,
    itemType?: string,
    itemName?: string,
    biller: billerr,
}

export default function Airtime(props: any) {

    const auth = useAuth()
    const authContext = auth.context
    const user = authContext.user
    const cookie = Cookie()

    const themeContext = useTheme()
    const theme = themeContext.theme
    const util = Util()
    const convert = require('number-to-words')
    const toast = useToast()
    const popup = usePopup()
    const data = useData()
    const { register, watch, handleSubmit, setValue, formState: { errors } } = useForm<Model>()
    const mobilePattern = /^[0-9]{11,11}$/i
    const amountPattern = /^[0-9]{2,4}$/i

    const watchAmount = watch("amount")
    const [biller, setBiller] = useState<billerr>()
    const submitButton = props.submitButton
    const submitButtonText = submitButton?.text ?? 'OK, send'
    const billers : billerr[] = props.billers ?? []
    const lastUsedBillerCode = cookie.getCookie('lastbillercode')
    const lastUsedMobile = cookie.getCookie('lastairtimemobile')

    const onSubmit: SubmitHandler<Model> = model => {

        const biller = billers.filter(b=>b.code === model.billerCode)[0]
        if (!biller) { toast.show('Choose a partner.', 'error') }
        model.billerCode = biller?.code
        model.productId = biller?.productId
        model.product = biller?.product
        model.productField = biller?.productField
        if(!model.amount){
            toast.show('Enter amount.', 'error')
            return            
        }

        // Enforce minimum amount 50
        if(model.amount < 50) model.amount = 50;

        // Remove before going live
        //model.amount = 10
        //if (model.billerCode === 'mtn') model.amount = 50
        //if (model.billerCode === 'airtel') model.amount = 50
        // Remove before going live

        data.post(process.env.REACT_APP_API_VASAIRTIME, model, (resp: any) => {
            if (resp.data) {

                const updatedUser = { ...user, wallet: resp.data }
                const updatedContext = { ...authContext, user : updatedUser }
                auth.updateContext(updatedContext)

                // set purchased in order to trigger a wallet update
                if(props.setPurchased){
                    props.setPurchased(true)
                }

                // clear amount
                setValue("amount", undefined)

                //let randGreeting = Math.floor((Math.random() * 3) + 0)
                if(submitButton && submitButton.callback){
                    submitButton.callback()
                }

                // Cache user inputs for subsequent use
                cookie.setCookie('lastbillercode', model.billerCode)
                cookie.setCookie('lastairtimemobile', model.mobile)

                //popup.show(['Awesome!', 'Nice!', 'Cool!'][randGreeting] + ' Mobile number will be credited shortly.', 'Complete')
                popup.show('Mobile number will be credited shortly.', undefined, { okayButton : { text : 'Okay' }})
            } else {
                if (resp.message) {
                    popup.show(resp.message)
                }
                else
                    popup.show('Request failed. Please confirm your connection and try again.')
            }
        })

    }

    useEffect(() => {
        // // fetch all bundles once
        // let bunds = [
        //     { name: 'MTN N100 Bundle', amount: 100, itemType: '9', billerDataCode: 'data', productId: 1127, productField: 'Product type' },
        //     { name: 'Airtel N100 Bundle', amount: 100, itemType: '99', billerDataCode: 'airtel-data', productId: 1124, productField: 'Product Type' },
        //     { name: 'Glo N100 Bundle', amount: 100, itemType: 'DATA-21', billerDataCode: 'glo-data', productId: 52, productField: 'Product Type' },
        //     { name: '9 Mobile N100 Bundle', itemName: '9MOBILE 1-day (100MB)', amount: 100, billerDataCode: '9mobiledata1', productId: 1144, productField: 'Product' }
        // ]
        // setAllBundles(bunds)

        // Choose last used airtime provider if any
        if(lastUsedBillerCode){
            const lastBiller = billers.filter(b=>b.code === lastUsedBillerCode)[0]
            if(lastBiller){
                setBiller(lastBiller)
                setValue("billerCode", lastBiller.code)
            }
        }

        
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="mb-3 mt-3">
                    <label htmlFor="billerCode" className="form-label lh-1">Partner</label>
                    <InputGroup size="lg">
                        <Dropdown as={ButtonGroup} {...register("billerCode", { 
                        required : "Partner is required",
                    })}>
                            <Dropdown.Toggle className={theme.background + " border border-secondary text-start d-flex align-items-center"} style={{width:'100%'}}>
                                <div className={"flex-fill text-secondary " + theme.color }>{ biller ? biller.name : <span className="text-secondary">Select a partner</span> }</div> 
                            </Dropdown.Toggle>
                            <Dropdown.Menu  className={"border border-secondary " + theme.background} style={{maxHeight:250, overflow:'scroll'}}>
                                {
                                    billers.map((billa:billerr, index)=>{
                                        return <Dropdown.Item className="pt-3 pb-2" onClick={()=>{setValue("billerCode", billa.code); setBiller(billa)}} key={index}>{billa.name}</Dropdown.Item>
                                    })
                                }                                    
                            </Dropdown.Menu>

                        </Dropdown> 

                    </InputGroup>
                    {/* <select defaultValue="" id="billerCode" {...register("billerCode", { 
                                required : "Partner is required",
                            })} className={"form-select form-select-lg " + theme.background + ' ' + theme.color} aria-label="Select partner">
                        <option>- Select partner -</option>
                        {
                            billers.map((billa, index) => {
                                let billerOption = <option value={billa.code} key={index}>{billa.name}</option>
                                //if(biller?.code === billa.code) billerOption = <option value={billa.code} key={index} selected>{billa.name}</option>
                                return billerOption
                            })
                        }
                    </select> */}
                    {errors.billerCode && !biller && <small className="text-danger">{errors.billerCode.message}</small>}
                </div>

                <div className="mb-3 lh-1">
                    <label htmlFor="mobile" className="form-label">Mobile</label>
                    <input defaultValue={lastUsedMobile} type="text" {...register("mobile", {
                        required: 'Mobile number is required',
                        pattern: { value: mobilePattern, message: 'Enter a valid mobile number' }
                    })} autoComplete="off" maxLength={11} className={"form-control form-control-lg " + theme.background + ' ' + theme.color} id="mobile" placeholder="Enter mobile number" />
                    {errors.mobile && <small className="text-danger">{errors.mobile.message}</small>}
                </div>
                <div className="lh-1">
                    <label htmlFor="amount" className="form-label">Amount</label>
                    <input defaultValue="" type="text" {...register("amount", {
                        required: 'Amount is required',
                        pattern: { value: amountPattern, message : 'Required amount between 50 and 500.' },
                    })} autoComplete="off" maxLength={3} className={"form-control form-control-lg " + theme.background + ' ' + theme.color} id="amount" placeholder="Enter minimum 50" />
                    {errors.amount && <small className="text-danger">{errors.amount.message}</small>}
                </div>
                <div className="mb-2 mt-2 lh-1" style={{minHeight:35}}>
                    {
                        watchAmount && watchAmount > 49 &&
                            <small className="form-text lh-1">Please confirm <strong>{util.titleCase(convert.toWords(watchAmount))}</strong>.</small>
                    }
                </div>
                <div className="d-grid gap-2">
                    <button type="submit" className="btn btn-lg btn-primary" disabled={data.isBusy()}>
                        {submitButtonText} {data.isBusy()} <Spinner visibility={data.isBusy()} isCentered={false} />
                    </button>
                </div>
                <p className="fs-6 mt-4 lh-1">
                    This option is fulfilled by third parties. If you need help, send us a message.                        
                    <Link to="#" className="ms-2 me-3">
                        <Share 
                            channel={ShareChannel.Twitter} 
                            message={"Hello @fantastakeo, I tried to redeem my coins and received this message: [insert message received here]. What should I do next?"}>
                        </Share>                            
                    </Link>                              
                    <Link to="#" onClick={()=>window.open('https://www.instagram.com/fantastakeo')}> <i className="bi bi-instagram"></i></Link>
                </p>
            </form>
        </>
    )

}