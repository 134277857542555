import React, { useContext, useEffect, useState } from 'react'
import { ButtonGroup, CloseButton, Dropdown, InputGroup, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth } from '../context/auth';
import { useTheme } from '../context/theme';
import { clique, contest, contestant, response } from '../utils';
import useData, { Spinner } from '../utils/data';
import { usePopup } from '../utils/popup';
import Util, { Pluralize } from '../utils/util';
import { useContestJoin } from './joincontest';

type init = {

    clique?: clique|null,
    title:any,
    visible:boolean,
    show: Function,
    hide: any,
}

let modal:init = {
    clique: null,
    visible: false,
    title: null,
    show: () => { },
    hide: () => { }, 
}

const useModal = ()=>{

    const [visible, setVisible] = useState(modal.visible)
    const [title, setTitle] = useState(modal.title)
    const [clique, setClique] = useState<any>()
    
    modal.visible = visible
    modal.title = title
    modal.clique = clique
    modal.show = (clique:clique)=> {
        
        setVisible(true)
        setTitle(clique.name)
        setClique(clique)
    }
    modal.hide = ()=> setVisible(false)

    return modal
}

let ModalContext = React.createContext(modal)

export const useCliqueDetails = ()=>{

    ModalContext = React.createContext(modal)

    return useContext(ModalContext)
}

export default function CliqueDetailsProvider(){

    const util = Util()
    //const cookie = Cookie()
    const data = useData()
    const themeContext = useTheme()
    const theme = themeContext.theme
    const auth = useAuth()
    const authContext = auth.context
    const user = authContext.getUser()
    const maxDisplayFriends = 5
    const [contests, setContests] = useState<contest[]>([])
    const [isSubscribed, setIsSubscribed] = useState(true)
    const popup = usePopup()
    const [selectedContest, setSelectedContest] = useState<contest|null>()
    //const startContestPopup = useStartContest()
    const contestJoin = useContestJoin()

    const mod = useModal()

    const whatsAppNumber = process.env.REACT_APP_WHATSAPP_SERVICENUMBER
    const whatsAppShareUrl = 'https://wa.me/' + whatsAppNumber + '?text='

    const handleRequestInvite = (clique:any)=>{
        let requestMessage = 'Hello, send me my clique invite for ' + clique.name + '.'
        window.open(whatsAppShareUrl + encodeURIComponent(requestMessage))
        mod.hide()
    }

    let friendsList = mod.clique?.friends.filter((friend, index)=> index < maxDisplayFriends && user?.id !== friend.userId).map(friend=> friend.firstName)
    if(friendsList){
        friendsList.unshift('You')
    }

    const getContestantList = (contestants:contestant[])=>{

        const contestantNames : string[] = []
        const contestantUserIds = contestants.map(c=>c.userId)
        mod.clique?.friends.filter((friend, index) => {
            
            let isFound = contestantUserIds.indexOf(friend.userId) > -1
            if(isFound){
                if(friend.userId === user?.id){
                    contestantNames.unshift('You')
                }else{
                    contestantNames.push(friend.firstName)                    
                }              
            }
            return isFound
        })

        if(contestantNames.length > 1){
            contestantNames[contestantNames.length - 1] = 'and ' + contestantNames[contestantNames.length - 1]
        }

        return contestantNames
    }

    const isUserContestant = ()=>{
        
        if(selectedContest?.contestants){
            const userContestant: contestant = selectedContest?.contestants.filter(c => c.userId === user?.id)[0]
            if(userContestant){
                return true
            }
        }
        return false;
    }

    // const startContest = ()=>{

    //     // Set this clique as default in a cookie

    //     if(mod.clique?.id){
    //         cookie.setCookie('selectedCliqueId', mod.clique.id)
    //     }

    //     // show start contest modal
        
    //     startContestPopup.show(()=>window.location.reload())

    //     // hide clique details modal

    //     mod.hide()        
    // }

    const fetchContests = (cliqueId:number)=>{

        if(cliqueId){

            const url = util.composeUrl(process.env.REACT_APP_API_CLIQUECONTESTS, [cliqueId])
            data.get(url, (resp:response<contest[]>)=>{

                if(resp){
                    if(isSubscribed){

                        if(resp.message){
                            popup.show(resp.message)
                        }

                        if(resp.data){
                            const results = resp.data
                            const firstContest = results[0]
                            setContests(results)
                            if(firstContest){
                                setSelectedContest(firstContest)
                            }                            
                        }                        
                    }
                }

            }, ()=>{})

        }
    }

    const joinContest = ()=>{

        if(selectedContest){
            mod.hide()
            contestJoin.show(selectedContest)            
        }             
    }

    useEffect(()=>{

        setIsSubscribed(true)

        if(mod.clique?.id){
            fetchContests(mod.clique.id)
        }
        
        return ()=>{
            setIsSubscribed(false)
            setSelectedContest(null)
        }

    }, [mod.clique?.id]) //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ModalContext.Provider value={mod}>

            <Modal show={mod.visible} onHide={mod.hide} contentClassName={"border border-secondary " + theme.background} className="p-2 pb-0" centered>
                <Modal.Header>
                    <Modal.Title className="p-4 lh-1 fw-bold"><i className="bi bi-people-fill"></i> {mod.title}</Modal.Title>
                    <CloseButton className={theme.name === 'dark' ? 'btn-close-white' : ''} onClick={()=>mod.hide()} />
                </Modal.Header>
                <Modal.Body className="p-5 pt-0 pb-5">
                    <div className="mb-4">
                        <div>
                            { 
                                friendsList?.join(', ')
                            }
                            
                            {
                                mod.clique && mod.clique.friends.length > maxDisplayFriends && maxDisplayFriends > 0 && <>...</>
                            }
                        </div> 
                        {
                            user && mod.clique &&
                            <div className="lh-1">
                                {
                                    mod.clique.creatorUserId === user.id && 
                                    <span>
                                        <Link to="#" onClick={()=>handleRequestInvite(mod.clique)}>
                                            Add friends
                                        </Link> to your clique.
                                    </span>
                                }
                                {
                                    mod.clique.creatorUserId !== user.id && <span>Friends in this clique.</span>
                                }

                            </div>
                        }
                        
                    </div>                    

                    {
                        mod.clique && mod.clique.contests.length === 0 && 
                        <p className="lh-1">
                            No active <Pluralize count={0} single="contest" plural="contests" /> in your clique, start a contest. {/*<Link to="#" onClick={startContest}>start a contest</Link>*/}
                        </p>
                    }
                    
                    {
                        selectedContest &&
                        <div className="mb-3">
                            <label htmlFor="contestId" className="form-label">Contests</label>                            
                            <InputGroup size="lg">
                                <Dropdown as={ButtonGroup} id="contestId">
                                    <Dropdown.Toggle className={theme.background + " border border-secondary text-start d-flex align-items-center"} style={{width:'100%'}}>
                                        
                                        <div className={"flex-fill text-secondary " + theme.color }>
                                            <div>
                                                <div>
                                                    <img loading="lazy" className="rounded-circle" style={{ width: 20, height:20 }} src={selectedContest.fixture?.homeTeam.logo} alt="" /> {selectedContest.fixture?.homeTeam.name}
                                                </div>   
                                                <div>
                                                    <img loading="lazy" className="rounded-circle" style={{ width: 20, height:20 }} src={selectedContest.fixture?.awayTeam.logo} alt="" /> {selectedContest.fixture?.awayTeam.name}
                                                </div>
                                            </div>                                        
                                        </div>
                                        
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className={"border border-secondary " + theme.background} style={{maxHeight:250, overflow:'scroll'}}>
                                        {
                                            contests.map((contest:contest, index)=>{
                                                return <Dropdown.Item style={{whiteSpace:'break-spaces'}} onClick={()=>setSelectedContest(contest)} className="pt-3 pb-2" key={index}>
                                                        <div>{contest.banter?.name}</div>
                                                        <div>{contest.banter?.phrase}</div>                                                    
                                                    </Dropdown.Item>
                                            })
                                        }                                    
                                    </Dropdown.Menu>
                                </Dropdown>
                            </InputGroup>
                        </div>
                    }

                    {
                        selectedContest?.trophy && 
                        <div className="mb-4">
                            <div className={"lh-1 fs-6 pb-2 " + theme.color} style={{whiteSpace:'break-spaces'}}><i className="bi bi-clock"></i> { util.getFriendlyFixtureDate(selectedContest.fixture?.startDate) } </div>
                            <label htmlFor="contestId" className="form-label fs-2 lh-1">{selectedContest.banter?.phrase}</label>
                            
                            <div className={theme.background + " border-0 border-secondary fs-3 lh-1 ps-0 pt-2 pb-2 rounded"}>
                                <div className="d-flex align-items-center">
                                    {/* <i className={"fs-1 bi me-2 " + selectedContest.trophy.icon}></i> */}
                                    <i className="fs-1 bi-award me-2"></i> 
                                    <div className="fs-5">
                                        {
                                            //selectedContest.trophy.name
                                        }                                        
                                        <div><span className="fs-4"> {selectedContest.trophy.amount.toLocaleString()} coins reward {/*<i className="bi bi-coin fs-6"></i>*/}</span></div>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    }
                    
                    {
                        selectedContest?.contestants && selectedContest.contestants.length > 1 &&
                        <div className="d-grid mb-4 fs-6 lh-1">
                            {
                                getContestantList(selectedContest.contestants).join(', ')
                            } have joined this contest.
                        </div>
                    }

                    {
                        selectedContest && user && isUserContestant() === false && 
                        <div className="d-grid gap-2 mt-3">
                            <button type="submit" className="btn btn-lg btn-primary" disabled={data.isBusy()} onClick={()=>joinContest()}>
                                Join contest <Spinner visibility={data.isBusy()} isCentered={false} />
                            </button>
                        </div>
                    }
                    
                </Modal.Body>
                
            </Modal>
            
        </ModalContext.Provider>        
    )

}