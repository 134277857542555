import { useState } from 'react'
import { useHistory } from 'react-router-dom'
//import useLocalStorage from './storage'
import { useToast } from "./toast"

type dataService = {
    isBusy : Function,
    get : Function,
    post : Function,
    setCookie: Function,
    getCookie :Function,
    expireCookie: Function,
    parseJwt : Function
}

export default function useData() : dataService {

    const base = process.env.REACT_APP_API_BASE
    const CONNECTION_ERROR = 'Please check your network connection or wait a short while and try again.'
    const toast = useToast()
    const history = useHistory()
    const [busy, setBusy] = useState(false)

    const getHeaders = (endpoint:string) => {
        let cookies = document.cookie.split(';')
        let keyPairs = cookies.filter(c => c.indexOf('token=') > -1)
        let headers = { 'Content-Type': 'application/json', 'Authorization': '' }
        if (keyPairs.length > 0) {
            let bearerToken = keyPairs[0].split('=')[1]
            if (bearerToken) headers.Authorization = 'Bearer ' + bearerToken
        }

        // Only these endpoints do not require the Authorization header
        let anonymousEndpoints = [
            'auth/token', 'auth/signup', 'auth/requestreset', 'auth/verifyrecaptcha', 'auth/resetpassword', 'util/countries'
        ];

        if(!headers.Authorization){
            let registeredEndpoint = anonymousEndpoints.filter(a=> a === endpoint)[0]
            if(!registeredEndpoint){
                // Endpoint was not found in array containing anonymous endpoints
                console.log('Missing authorization -', endpoint)
                return
            }            
        }

        return headers;
    }

    const handleUnauthorized = () => {

        let historyState:any = history.location
        if(historyState.pathname !== '/'){
            //popup.show('Please login to your account.')
            history.push('/login', history.location)
        }else{

            console.log('What action should I take here?...........')

        }
    }

    const attemptRefreshToken = () => {
        console.log('Attempting to refresh token')

        let refreshToken = getCookie('refreshToken')
        if(!refreshToken) handleUnauthorized()
        
        // update token to refresh token
        setCookie('token', refreshToken)

        let tokenUser = parseJwt(getCookie('refreshToken'))
        if(!tokenUser) handleUnauthorized()

        let username = tokenUser.unique_name        
        const url = process.env.REACT_APP_API_REFRESHTOKEN + '?username=' + username
        data.post(url, {}, (resp: any) => {
            if(resp.data.token){
                // Update expired tokens
                setCookie('token', resp.data.token)
                setCookie('refreshToken', resp.data.refreshToken)

                // retry endpoint again
                history.replace(history.location)
            }else{
                handleUnauthorized()                
            }

        }, ()=>handleUnauthorized())
    }

    const isBusy = () => { return busy}

    const get = (endpoint: string, callback?: Function, failback?: Function) => {    
        const api = base + '' + endpoint
        const headers = getHeaders(endpoint)
        if(!headers) return
        setBusy(true)
        fetch(api, {
            method: 'GET',
            headers: headers
        }).then(resp => {
            setBusy(false)
            if (resp.status === 401) {

                // attempt to refresh token
                attemptRefreshToken()
                
            } else {
                return resp.json()
            }
        }).then(result => {
            // call success callback
            if (result) {
                if (result.errors && result.errors.message) {
                    toast.show(result.errors.message[0], 'error')
                } else if (callback) callback(result)
            }
        }).catch(error => {
            setBusy(false)
            toast.show(CONNECTION_ERROR)
            // call failure callback
            if (failback) failback();
        })
    }

    const post = (endpoint: string, body: any, callback?: Function, failback?: Function) => {
        const api = base + '' + endpoint
        const headers = getHeaders(endpoint)
        if(!headers) return
        setBusy(true)
        fetch(api, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        }).then(resp => {
            setBusy(false)
            if (resp.status === 401) {
                handleUnauthorized()
            } else {
                //if(resp.status === 200) setIsAuthorized(true)
                return resp.json()
            }
        }).then(result => {
            if (result) {
                if (callback) callback(result)
                // if (result.errors && result.errors.message) {
                //     toast.show(result.errors.message[0], 'error')
                // }
            }
        }).catch(error => {
            setBusy(false)
            toast.show(CONNECTION_ERROR)
            // call failure callback
            if (failback) failback();
        })
        return true
    }

    let data : dataService = {        
        isBusy : isBusy,
        get : get,
        post : post,
        setCookie: setCookie,
        getCookie: getCookie,
        expireCookie: expireCookie,
        parseJwt : parseJwt     
    }

    return data
}

export const Spinner = ({ visibility, isCentered }: any) => {

    let center = true
    if(isCentered !== undefined && isCentered === false) center = false
    
    return (
        <>
            {
                center === false && 
                <span className={`spinner-border ${visibility ? '' + center : 'd-none '}`} role="status">
                    <span className="visually-hidden">Loading...</span>
                </span>
            }

            {
                center === true &&
                <div className="text-center">
                    <div className={`spinner-border ${visibility ? '' + center : 'd-none '}`} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>  
                </div>
            }
        </>
        
    )
}

export const parseJwt = (token: string) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const getCookie = (name: string) => {
    let cookies = document.cookie.split(';')
    let keyPairs = cookies.filter(c => c.indexOf(name + '=') > -1)
    if (keyPairs.length === 0) return '';
    let cookie = keyPairs[0].split('=')[1]
    return cookie;
}

export const setCookie = (name: string, value:string, expires?: number) => {

    if(!expires){

        document.cookie = name + '=' + value + '; path=/; SameSite=Lax'

    }else{

        document.cookie = name + '=' + value + '; path=/; SameSite=Lax; max-age=' + (60 * expires)

    }
}

export const expireCookie = (name: string, path?:string) => {
    if(!path) path = '/'

    var date = new Date('1970-01-01')
    if(document.cookie){
        //console.log('document.cookie', document.cookie)
        let cookiePairs = document.cookie.split(';')
        for(let i = 0; i < cookiePairs.length; i++){            
            let cookie = cookiePairs[i]
            //console.log('cookie', cookie)
            cookie = cookie.trim().split('=')[0]
            if(cookie.trim() === name) {
                document.cookie = name + '=; expires=' + date.toUTCString() + '; path='+ path +'; SameSite=Lax'
            }
        }        
    }
}

export const Currency = (props: any) => <span dangerouslySetInnerHTML={{ __html: props.symbol }}></span>