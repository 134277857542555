import { Modal, Overlay, Tooltip } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import useData, { Currency, Spinner } from "../utils/data"
import Util from "../utils/util"
import { useToast } from "../utils/toast"
import { useRef, useState } from "react"

type Model = {
    transferRef: string
}

export default function Transfer(props: any) {
    const data = useData()
    const util = Util()
    const toast = useToast()
    const advice = props.advice
    const accountNumber = "1014317001"


    //const [show, setShow] = useState(false);
    //const handleClose = () => setShow(false);
    //const handleShow = () => setShow(props.direct);
    const { register, handleSubmit, formState: { errors } } = useForm<Model>()

    const onSubmit: SubmitHandler<Model> = model => {
        data.post(process.env.REACT_APP_API_TRANSFERCOMPLETE + '?reference=' + model.transferRef + '&amount=' + advice.amount, {}, (resp: any) => {
            console.log('resp', resp)
            if (resp) {
                if (resp.data) props.setDirect(false)
                toast.show(resp.message, (resp.data ? 'success' : 'error'))
            }
        })

    }

    const target = useRef(null);
    const [repeat, setRepeat] = useState(false);

    return (
        <>
            <Modal show={props.direct} onHide={() => props.setDirect(false)} className="p-2" centered>
                <Modal.Header closeButton>
                    <Modal.Title className="p-4">Direct transfer</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-5 pt-4">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <p>
                            Transfer {advice?.amount > 0 && <Currency symbol={props.currency} />}{advice?.amount > 0 && (advice?.amount / 100).toLocaleString()} into Zenith, <Link to="#" onClick={() => { setRepeat(!repeat) }}><span className="badge bg-secondary">{accountNumber}</span></Link>
                            <Overlay target={target.current} show={repeat} placement="top">
                                {(props) => (
                                    <Tooltip id="overlay-example" {...props}>
                                        OK please confirm
                                    </Tooltip>
                                )}
                            </Overlay> - Capehi Limited.
                        </p>
                        <p>
                            <span className="form-text">You may also enter reference</span> <Link to="#" onClick={() => util.clipboardCopy(advice?.ref)}><span className="badge bg-secondary">{advice?.ref}</span></Link> <span className="form-text">into your bank app as the narration, to fasttrack processing.</span>
                        </p>

                        <div className="mb-3">
                            <label htmlFor="reference" className="form-label">Reference</label>
                            <input type="text" defaultValue="" {...register("transferRef", {
                                required: 'Reference above is required.'
                            })} maxLength={150} autoComplete="off" className="form-control" id="reference" placeholder="Enter reference above" />
                            {errors.transferRef && <small className="text-danger">{errors.transferRef.message}</small>} {errors.transferRef && <small className="text-danger d-md-none">Tap it to copy.</small>}
                        </div>
                        <div className="mb-4">
                            <span className="form-text">
                                I am using this "Transfer completed" button to notify of my transfer. I understand that these funds will not add to my wallet immediately.
                            </span>
                        </div>
                        <div className="d-grid gap-2">
                            <button type="submit" className="btn btn-primary" disabled={data.isBusy()}>
                                Transfer completed {data.isBusy()} <Spinner visibility={data.isBusy()} isCentered={false} />
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>
        </>
    )
}