import { useEffect, useRef, useState } from "react"
import { Overlay, Tooltip } from "react-bootstrap"
import { Link } from "react-router-dom"
import DashNav from "../home/dashnav"
import useData from "../utils/data"
import Airtime from "./airtime"
import Bundle from "./bundle"
import { useAuth } from "../context/auth"

type Biller = {
    name: string,
    shortName: string,
    code: string,
    dataCode: string,
    background: string,
    color: string,
    productId: number,
    product: string,
    productField: string
}

type BundleX = {
    productId: number,
    name: string,
    amount: number,
    billerDataCode: string,
    productField?: string,
    productValue?: string,
    bundleField?: string,
    itemName?: string,
    itemType?: string
}


export default function Topup() {
    const data = useData()
    const auth = useAuth()
    const authContext = auth.context
    const user = authContext.getUser()
    const wallet = user?.wallet
    const [biller, setBiller] = useState<Biller>()
    const [category, setCategory] = useState("airtime")
    const [purchased, setPurchased] = useState(false)
    const [allBundles, setAllBundles] = useState<BundleX[]>([])
    const [isSubscribed, setIsSubscribed] = useState(true)
    const [billers] = useState([
        { name: 'MTN', shortName: 'MTN', code: 'mtn', dataCode: 'data', productId: 60, product: 'AIRTIME', productField: 'Product', background: '#ffecb5', color: '#664d03' },
        { name: 'Airtel', shortName: 'Air', code: 'airtel', dataCode: 'airtel-data', productId: 11, product: 'AIRTEL-VTU', productField: 'Product Type', background: '#f5c2c7', color: '#842029' },
        { name: 'Glo', shortName: 'Glo', code: 'glo', dataCode: 'glo-data', productId: 61, product: 'AIRTIME', productField: 'Product', background: '#badbcc', color: '#0f5132' },
        { name: '9mobile', shortName: '9mo', code: '9MOBILEAIRTIME', dataCode: '9mobiledata1', productId: 1143, product: 'AIRTIME', productField: 'Product', background: '#b6d4fe', color: '#084298' }
    ])

    const handleBillerClick = (biller: Biller) => {
        setBiller(biller)
    }

    const handleCategoryChange = (category: string) => {
        setCategory(category)
    }

    useEffect(() => {
        if (purchased) {
            const timeout = setTimeout(() => {
                // update user wallet
                //auth.fetchWallet()
                setPurchased(false)
            }, 3000)

            return () => clearTimeout(timeout)
        }
    }, [purchased]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // fetch all data bundles once
        data.get(process.env.REACT_APP_API_VASGETBUNDLES, (resp: any) => {
            if (resp?.data) {
                if(isSubscribed){
                    setAllBundles(resp.data)
                }                
            }
        })

        return ()=>{
            setIsSubscribed(false)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const target = useRef(null);
    const [repeat, setRepeat] = useState(false);

    return (
        <>
            <DashNav title="Airtime &amp; data">
                <p className="mb-3 d-none">Your wallet balance is <Link to="/fund">
                    <span dangerouslySetInnerHTML={{ __html: wallet?.currency?.html }}></span>{(wallet?.balance / 100).toLocaleString()}</Link>.
                </p>
                <p>
                    Top up airtime or data for any mobile number.
                    {/* Top up airtime or data and score fan points for your team. */}
                </p>
                <p>
                    Choose a network<Link to="#" className="d-none" ref={target} onClick={() => { setRepeat(!repeat); setTimeout(() => setRepeat(false), 3000) }}> or repeat last</Link>
                    <Overlay target={target.current} show={repeat} placement="top">
                        {(props) => (
                            <Tooltip id="overlay-example" {...props}>
                                OK please confirm
                            </Tooltip>
                        )}
                    </Overlay>
                </p>
                <div className="row row-cols-auto">
                    {
                        billers.map((b, index) => {
                            return (
                                <div className="col mb-4" key={index}>
                                    <Link onClick={() => handleBillerClick(b)} className="nav-lin" to="#" style={{ opacity: b.code === biller?.code ? '1' : '.2' }}>
                                        <svg className="bd-placeholder-img rounded-circle"
                                            width="44" height="44" xmlns="http://www.w3.org/2000/svg"
                                            role="img" aria-label={b.name} preserveAspectRatio="xMidYMid slice"
                                            focusable="false">
                                            <rect width="100%" height="100%" fill={b.background}></rect>
                                            <text x="50%" y="50%" dominantBaseline="middle" fontWeight="bolder" textAnchor="middle" fill={b.color}>{b.shortName.substr(0, 1)}</text>
                                        </svg>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>

                <ul className={!biller ? 'nav nav-tabs d-none' : 'nav nav-tabs'} id="topup" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button onClick={() => handleCategoryChange('airtime')} className={biller?.name && category === 'airtime' ? 'nav-link active' : 'nav-link'} id="airtimetab" data-bs-toggle="tab" data-bs-target="#airtimepane" type="button" role="tab" aria-controls="airtimepane" aria-selected="true">
                            {biller?.name && category === 'airtime' ? biller?.name : ''} Airtime
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button onClick={() => handleCategoryChange('dataBundle')} className={biller?.name && category === 'dataBundle' ? 'nav-link active' : 'nav-link'} id="datatab" data-bs-toggle="tab" data-bs-target="#datapane" type="button" role="tab" aria-controls="datapane" aria-selected="false">
                            {biller?.name && category === 'dataBundle' ? biller?.name : ''} Data
                        </button>
                    </li>
                </ul>
                <div className={!biller ? 'tab-content d-none' : 'tab-content'} id="topupcontent">
                    <div className={biller?.name && category === 'airtime' ? 'tab-pane fade show active' : 'tab-pane fade show'} id="airtimepane" role="tabpanel" aria-labelledby="airtimetab">
                        <Airtime biller={biller} wallet={wallet} setPurchased={setPurchased} />
                    </div>
                    <div className={biller?.name && category === 'dataBundle' ? 'tab-pane fade show active' : 'tab-pane fade show'} id="datapane" role="tabpanel" aria-labelledby="datatab">
                        <Bundle biller={biller} wallet={wallet} setPurchased={setPurchased} allBundles={allBundles} />
                    </div>
                </div>
            </DashNav>
        </>
    )
}