import { useState } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../context/theme";
import { bantter } from "../utils";
import useData from "../utils/data";

export default function Banter(props:any){

    const showNextArrow = props.showNextArrow

    const themeContext = useTheme()
    const theme = themeContext.theme
    const data = useData()

    let banter = props.banter
    const handleBanterClick = props.handleBanterClick
    let isPhraseTruncated = (props.isPhraseTruncated !== undefined) ? false: true

    const [banters, setBanters] = useState<bantter[]>([])
    const [viewedBanterStrs, setviewedBanterStrs] = useState<string[]>([])
    const [noResults, setNoResults] = useState(false)

    if(!banter) return <>No banter provided.</>

    if(banters.length > 0){
        banter = banters[0]
    }

    let banterTitle = banter.phrase

    const fetchUserNextBanter = (banter:bantter)=>{

        if(banter.id === 0) return

        setNoResults(true)

        if(viewedBanterStrs.length === 0){
            viewedBanterStrs.push(banter.id.toString())
        }

        let viewedBanters = viewedBanterStrs.join()

        let url = process.env.REACT_APP_API_USERSNEXTBANTER +'?previousbanterids=' + viewedBanters

        data.get(url, (resp:any)=>{
            
            if(resp && resp.data){
                
                let updateBanters = [...banters]
                if(resp.data.length >= 1){

                    updateBanters.shift()

                    //setviewedBanterStrs(banters.map((b : bantter) => b.id.toString() ))

                    let nextBanter = resp.data[0]
                    
                    // Replace whatever the last banter is with the next banter
                    
                    updateBanters.push(nextBanter)
                    viewedBanterStrs.push(nextBanter.id.toString())

                    setviewedBanterStrs(viewedBanterStrs)

                    setNoResults(false)
                }else{
                    setNoResults(true)
                }

                setBanters(updateBanters)
            }
        })
    }

    return(
        <div className="pb-0">
            <div className="d-flex justify-content-between text-muted">
                <small><i className="bi bi-person-fill"></i> {banter.owner}</small>
                {/* <div>
                    {
                        banter?.isPromoted && 
                        <small className="badge bg-warning text-dark rounded-pill">
                            <i className="bi bi-gem" aria-hidden="true" />
                        </small>
                    }                                        
                </div> */}
            </div>
            <div className="d-flex w-100 justify-content-between mb-3 lh-1">

                <span className={isPhraseTruncated ? 'mb-1 mt-2' : 'mb-1 mt-2'}>
                    {
                        <Link to="#" className="fw-bold" data-testid="banterName" onClick={() => { if(handleBanterClick) handleBanterClick(banter)}}>{banterTitle}.</Link>
                    }
                    {/* {
                        !handleBanterClick && <span className="fw-bold">{banterTitle}.</span>
                    } <small className={"d-none fs-6 " + theme.color}> <i className="bi bi-person-fill"></i> {banter.owner}</small> */}
                </span> 
            </div> 
            {
                banter.fixture &&
                <div className={isPhraseTruncated ? 'mb-1 text-secondary ' + theme.color : 'mb-1 w-100'}>                    

                    <div className={ props.hidePhrase ? "d-none" : 'row g-2'}>

                        <div>
                            
                            <div className="d-flex align-items-center mb-2">

                                <div style={{width:24, height:24, backgroundSize:'cover', backgroundRepeat: 'no-repeat', backgroundPosition:'center', backgroundImage: `url(${banter.fixture.homeTeam.logo})`}} className="me-2 rounded-circle"></div>

                                <div style={{width:'100%'}} className="ellipsis">{banter.fixture.homeTeam.name}</div>

                            </div>

                            <div className="d-flex align-items-center mb-2">

                                <div style={{width:24, height:24, backgroundSize:'cover', backgroundRepeat: 'no-repeat', backgroundPosition:'center', backgroundImage: `url(${banter.fixture.awayTeam.logo})`}} className="me-2 rounded-circle"></div>

                                <div style={{width:'100%'}} className="ellipsis">{banter.fixture.awayTeam.name}</div>

                            </div>

                        </div>

                        <div className="d-flex justify-content-between align-items-center" style={{minHeight:65}}>
                            <Link to="#" className={theme.color}>
                                {
                                    banter?.endMinutes >= 1 && banter?.endMeasure.toLowerCase() !== 'tomorrow' && <span>{banter?.endMinutes}</span>
                                } {
                                    banter?.endMeasure
                                }
                            </Link>

                            {
                                noResults === false && showNextArrow &&
                                <Link to="#" onClick={() => fetchUserNextBanter(banter)}>
                                    <i className={"bi bi-arrow-right-circle-fill text-secondary " + theme.color} style={{fontSize:42}}></i>
                                </Link>
                            }
                            
                        </div>

                    </div>
                </div>
            }
            
        </div>  
    )

}