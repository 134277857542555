import { useEffect } from "react"
import { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTheme } from "../context/theme"
import useData, { Spinner } from "../utils/data"
import { useToast } from "../utils/toast"
import Util from "../utils/util"

type Model = {
    billerCode?: string,
    productId?: number,
    mobile: string,
    amount?: number,
    productField?: string,
    bundleIndex: number,
    itemType?: string,
    itemName?: string,
    product: string
}

type Biller = {
    name: string,
    shortName: string,
    code: string,
    dataCode: string,
    background: string,
    color: string,
    productId: number,
    product: string,
    productField: string
}

type BundleX = {
    productId: number,
    name: string,
    amount: number,
    billerCode: string,
    productField?: string,
    productValue?: string,
    bundleField?: string,
    itemName?: string,
    itemType: string
}


export default function Bundle(props: any) {
    const biller = props.biller
    const wallet = props.wallet
    const allBundles: BundleX[] = props.allBundles
    const themeContext = useTheme()
    const theme = themeContext.theme
    const util = Util()
    const convert = require('number-to-words')
    const toast = useToast()
    const data = useData()
    const { register, watch, handleSubmit, setValue, formState: { errors } } = useForm<Model>()
    const mobilePattern = /^[0-9]{11,11}$/i
    const watchAmount = watch("amount")
    const [bundles, setBundles] = useState<BundleX[]>([])
    const submitButton = props.submitButton
    const submitButtonText = submitButton?.text ?? 'OK, send'


    const updateBundles = (biller?: Biller) => {
        // attempt to get bundles from allBundles
        if (!biller?.dataCode) return []
        let bunds = allBundles.filter(b => b.billerCode === biller.dataCode);
        if (bunds.length === 0) {
            // fetch from api
            bunds = []
        }
        setBundles(bunds)
    }

    const onSubmit: SubmitHandler<Model> = model => {
        if (!biller) { toast.show('Choose a network.', 'error') }
        // TODO: Replace before going live
        //const bundle = bundles[model.bundleIndex]
        const bundle = bundles[0];
        // TODO: Replace before going live

        model.billerCode = bundle?.billerCode
        model.productId = bundle.productId
        model.amount = bundle.amount
        model.productField = bundle.productField
        model.product = bundle.itemType
        model.itemName = bundle.itemName // for 9mobile data plans only
        //return

        data.post(process.env.REACT_APP_API_VASDATABUNDLE, model, (resp: any) => {
            if (resp.data) {
                // set purchased in order to trigger a wallet update
                props.setPurchased(true)
                // clear amount
                setValue("amount", undefined)
                let randGreeting = Math.floor((Math.random() * 3) + 0)
                if(submitButton.callback){
                    submitButton.callback()
                }
                toast.show(['Awesome!', 'Nice!', 'Cool!'][randGreeting] + ' Your mobile will be credited shortly, thank you.', 'success')
            } else {
                if (resp.message) toast.show(resp.message, 'error')
                else
                    toast.show('Request failed to complete. Please confirm your connection and try again.', 'error')
            }
        })

    }

    useEffect(() => { updateBundles(biller) }, [biller]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="pt-4">
                <div className="mb-3">
                    <label htmlFor="mobile" className="form-label">Mobile</label>
                    <input defaultValue="" type="text" {...register("mobile", {
                        required: 'Mobile number is required',
                        pattern: { value: mobilePattern, message: 'Enter a valid mobile number' }
                    })} maxLength={11} className={"form-control form-control-lg " + theme.background + ' ' + theme.color} id="mobile" placeholder="Enter mobile number" />
                    {errors.mobile && <small className="text-danger">{errors.mobile.message}</small>}
                </div>
                <div className="">
                    <label htmlFor="bundleIndex" className="form-label">Bundles</label>
                    <select className={"form-select form-select-lg " + theme.background + ' ' + theme.color} id="bundleIndex" {...register("bundleIndex", {
                        required: 'Choose a data bundle'
                    })}>
                        <option value="">- select one -</option>
                        {
                            bundles.map((bundle, index) => <option key={index} value={index}>{bundle.name}</option>)
                        }
                    </select>
                    {errors.bundleIndex && <small className="text-danger">{errors.bundleIndex.message}</small>}
                </div>
                <div className="mb-4 mt-3">
                    {
                        watchAmount && watchAmount > 0 ?
                            <small className="form-text"><strong>{util.titleCase(convert.toWords(watchAmount))}</strong> {wallet.currency.name.toLowerCase()} data, please confirm.</small>
                            : <></>
                    }
                </div>
                <div className="d-grid gap-2">
                    <button type="submit" className="btn btn-lg btn-primary" disabled={data.isBusy()}>
                        {submitButtonText} {data.isBusy()} <Spinner visibility={data.isBusy()} isCentered={false} />
                    </button>
                </div>
            </form>
        </>
    )
}