import React, { useState, useContext } from 'react'
import { Button, CloseButton, Modal } from 'react-bootstrap'
import { useAuth } from '../context/auth'
import { useTheme } from '../context/theme'
import Airtime from './airtime'
import Bundle from './bundle'
import Coupon from './coupon'
import { billerr, bundlex } from '../utils'

const defaultTitle = 'Coins'
const defaultMessage = ''
const config = { okayButton : { text: 'Redeem' }}

const init = { 
    config : config, 
    visible: false, 
    title: defaultTitle, 
    message: defaultMessage, 
    hide: () => { }, 
    show: (message?: string, title?: undefined|string, options?: any) => { } 
}

const ModalContext = React.createContext(init)

// Custom hook for modal
export const useRedeem = () => {
    return useContext(ModalContext)
}

const useModal = () => {
    const [visible, setVisible] = useState(init.visible)
    const [message, setMessage] = useState(init.message)
    const [title, setTitle] = useState(init.title)
    const [config, setConfig] = useState(init.config)

    init.config = config
    init.visible = visible
    init.hide = () => {
        setVisible(false)
        setTitle(title)
    }
    init.title = title
    init.message = message
    init.show = (message, title, options) => {

        if(options !== undefined) {
            setConfig(options)
        }

        if(!title) title = defaultTitle
        if(!message) message = defaultMessage
        
        setMessage(message)        
        setTitle(title)        
        setVisible(true)
    }
    return init;
}

export default function RedeemProvider({ children }: any) {

    const themeContext = useTheme()
    const theme = themeContext.theme

    const auth = useAuth()
    const authContext = auth.context
    const user = authContext.getUser()
    const wallet = user?.wallet
    
    const redeem = useModal()
    const [biller, setBiller] = useState<billerr>()
    const [category, setCategory] = useState<string|undefined>('coupon')
    const [setPurchased] = useState(false)
    const [allBundles] = useState<bundlex[]>([])
    //const [isPartnerSelected, setIsPartnerSelected] = useState<undefined|boolean>()

    const [billers] = useState([
        { name: 'MTN', shortName: 'MTN', code: 'mtn', dataCode: 'data', productId: 60, product: 'AIRTIME', productField: 'Product', background: '#ffecb5', color: '#664d03' },
        { name: 'Airtel', shortName: 'Air', code: 'airtel', dataCode: 'airtel-data', productId: 11, product: 'AIRTEL-VTU', productField: 'Product Type', background: '#f5c2c7', color: '#842029' },
        { name: 'Glo', shortName: 'Glo', code: 'glo', dataCode: 'glo-data', productId: 61, product: 'AIRTIME', productField: 'Product', background: '#badbcc', color: '#0f5132' },
        { name: '9mobile', shortName: '9mo', code: '9MOBILEAIRTIME', dataCode: '9mobiledata1', productId: 1143, product: 'AIRTIME', productField: 'Product', background: '#b6d4fe', color: '#084298' }
    ])

    // const handleBillerClick = (e: HTMLSelectElement) => {

    //     if (!e || e.selectedIndex <= 0) {
    //         return
    //     }

    //     const biller = billers[e.selectedIndex - 1]

    //     setBiller(biller)
    //     setIsPartnerSelected(true)
    // }

    const handleCategoryChange = (category?: string) => {
        setCategory(category)
        if(!biller && billers[0]){
            setBiller(billers[0])
        }
    }

    const redeemCallback = ()=>{
        setBiller(undefined)
        //setIsPartnerSelected(false)
        redeem.hide()
    }

    return (
        <ModalContext.Provider value={redeem}>

            { children }

            <Modal show={redeem.visible} onHide={redeem.hide} contentClassName={"border border-secondary " + theme.background} className="p-1 pb-0" centered>
                <Modal.Header>
                    <Modal.Title className="p-3"><i className={"bi me-1 bi-coin text-warning " + theme.color} /> {redeem.title}</Modal.Title>
                    <CloseButton className={theme.name === 'dark' ? 'btn-close-white' : ''} onClick={()=>redeem.hide()} />
                </Modal.Header>
                <Modal.Body className="p-5 pt-2 pb-0">
                    <p className="mb-2 fs-5 lh-1">
                        Redeem your coins using available options.
                        {/* { redeem.message } */}
                    </p>
                    
                    <div className="pt-4">

                        <h5 className="mb-4 d-none">Choose an option</h5>

                        {/* <div className="row g-2">
                            <div className="col-auto">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" defaultValue={0} name="redeemOption"  id="airtimeOption" />
                                    <label className="form-check-label" htmlFor="femaleSex">
                                        Airtime
                                    </label>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" defaultValue={1} name="redeemOption" id="dataOption" />
                                    <label className="form-check-label" htmlFor="maleSex">
                                        Data
                                    </label>
                                </div>
                            </div>
                        </div>                     */}
                    </div>
                    
                <ul className={'nav nav-tabs'} id="topup" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button onClick={() => handleCategoryChange('coupon')} className={category === 'coupon' ? 'nav-link active text-light ' + theme.background + ' ' + theme.color : 'nav-link'} id="coupontab" data-bs-toggle="tab" data-bs-target="#couponpane" type="button" role="tab" aria-controls="couponpane" aria-selected="false">
                            Coupon
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button onClick={() => handleCategoryChange('airtime')} className={category === 'airtime' ? 'nav-link active text-light ' + theme.background + ' ' + theme.color : 'nav-link'} id="airtimetab" data-bs-toggle="tab" data-bs-target="#airtimepane" type="button" role="tab" aria-controls="airtimepane" aria-selected="true">
                            Airtime
                        </button>
                    </li>                    
                    {/* <li className="nav-item d-none" role="presentation">
                        <button onClick={() => handleCategoryChange('dataBundle')} className={biller?.name && category === 'dataBundle' ? 'nav-link active text-light ' + theme.background + ' ' + theme.color  : 'nav-link'} id="datatab" data-bs-toggle="tab" data-bs-target="#datapane" type="button" role="tab" aria-controls="datapane" aria-selected="false">
                            {biller?.name && category === 'dataBundle' ? biller?.name : ''} Data
                        </button>
                    </li> */}                   
                    
                </ul>
                <div style={{minHeight:200}}>
                    {/* {
                        billers.map((b, index) => {
                            return (
                                <div className="col mb-0 mt-5" key={index}>
                                    <Link onClick={() => handleBillerClick(b)} className="nav-lin" to="#" style={{ opacity: b.code === biller?.code ? '1' : '.2' }}>
                                        <svg className="bd-placeholder-img rounded-circle"
                                            width="44" height="44" xmlns="http://www.w3.org/2000/svg"
                                            role="img" aria-label={b.name} preserveAspectRatio="xMidYMid slice"
                                            focusable="false">
                                            <rect width="100%" height="100%" fill={b.background}></rect>
                                            <text x="50%" y="50%" dominantBaseline="middle" fontWeight="bolder" textAnchor="middle" fill={b.color}>{b.shortName.substr(0, 1)}</text>
                                        </svg>
                                    </Link>
                                </div>
                            )
                        })
                    } */}
                    
                    
                    <div className="tab-content" id="topupcontent">
                        <div className={category === 'airtime' ? 'tab-pane fade show active' : 'tab-pane fade show'} id="airtimepane" role="tabpanel" aria-labelledby="airtimetab">   
                            <Airtime billers={billers} wallet={wallet} setPurchased={setPurchased} submitButton={ {text : 'OK, Redeem', callback: ()=>redeemCallback() } } />
                        </div>
                        <div className={category === 'coupon' ? 'tab-pane fade show active' : 'tab-pane fade show'} id="couponpane" role="tabpanel" aria-labelledby="coupontab">
                           <Coupon wallet={wallet} setPurchased={setPurchased} submitButton={ {text : 'OK, Proceed', callback: ()=>redeemCallback() } } />
                        </div>
                        <div className={category === 'dataBundle' ? 'tab-pane fade show active' : 'tab-pane fade show'} id="datapane" role="tabpanel" aria-labelledby="datatab">
                            <Bundle biller={biller} wallet={wallet} setPurchased={setPurchased} allBundles={allBundles} submitButton={ {text : 'OK, Redeem', callback: ()=>redeemCallback()} } />
                        </div>
                        
                    </div>
                </div>
                </Modal.Body>
                <Modal.Footer className="pb-3">
                    { redeem.config?.okayButton && <Button className="btn-lg d-none" variant="primary" onClick={()=>redeem.hide()}>{redeem.config.okayButton.text}</Button> }
                </Modal.Footer>
            </Modal>            

        </ModalContext.Provider>
    )
}