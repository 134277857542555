import React, { useContext } from "react"
import { useHistory } from "react-router-dom"
import { response } from "../utils"
import { Cookie } from "../utils/cookie"
import useData from "../utils/data"
import { useToast } from "../utils/toast"

type inviteCodeType = {
    acceptInvite : Function
}

export const useInviteCode = ()=>{

    const cookie = Cookie()
    const data = useData()
    const history = useHistory()
    const toast = useToast()

    const acceptInvite = (inviteCode: string) : void =>{

        if(!inviteCode){
            return
        }

        // Api call to accept invitation
        if(inviteCode){
            const url = process.env.REACT_APP_API_ACCEPTCLIQUEINVITE + '?inviteCode=' + inviteCode
            data.post(url, inviteCode, ((resp:response<any>)=>{

                if(!resp.data){
                    toast.show(resp.message, 'error')
                    history.push('/clique')                    
                }else{
                    toast.show(resp.message, 'success')                    
                    history.push('/clique')
                }

                cookie.expireCookie('invitecode')

            }))
        }
    }

    const init : inviteCodeType = {
        acceptInvite: acceptInvite    
    }

    const InvideCodeContext = React.createContext(init)

    return useContext(InvideCodeContext)
}

