import useData, { Spinner } from "../utils/data";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTheme } from "../context/theme";
import { usePopup } from "../utils/popup";
import { useAuth } from "../context/auth";
import { useContext, useEffect, useState } from "react";
import { clique, country, response } from "../utils";
import { ButtonGroup, CloseButton, Dropdown, InputGroup, Modal } from "react-bootstrap";
import { Cookie } from "../utils/cookie";
import React from "react";
import Util from "../utils/util";
import { Link } from "react-router-dom";

type Model = {
    name: string,
    mobile: string,
    mobileCountryCode: string,
    country?: country,
}

type init = {
    visible?: boolean,
    hide: () => void,
    show: Function,
    callback?: Function|undefined,
}

const initInvite: init = {
    visible: false,
    show: ()=>{},
    hide: ()=>{},
}

const useInvite = () : init=>{

    const [visible, setVisible] = useState(initInvite.visible)

    initInvite.visible = visible

    initInvite.show = () => setVisible(true)
    initInvite.hide = () => setVisible(false)
    
    //setInitInvite(init)

    return initInvite
}

let ModalContext = React.createContext(initInvite)

export const useCliqueInvite = (callback?:Function)=>{

    initInvite.callback = callback
    ModalContext = React.createContext(initInvite)

    return useContext(ModalContext)
}


export default function CliqueInviteProvider(props: any){

    const popup = usePopup()
    const auth = useAuth()
    const authContext = auth.context
    const user = authContext.user
    const themeContext = useTheme()
    const theme = themeContext.theme
    const invite  = useInvite()
    const data = useData()
    const cookie = Cookie()
    const util = Util()
    const mobilePattern = util.mobileRegex()

    const { register, watch, handleSubmit, setValue, formState: { errors } } = useForm<Model>()

    const [countries, setCountries] = useState<country[]>([])
    const [isSubscribed, setIsSubscribed] = useState(true)

    const country = watch("country")

    const lastUsedWhatsApp = cookie.getCookie('whatsappnumber')
    const watchMobile = watch("mobile", lastUsedWhatsApp)
    const lastMobileCountryCode = cookie.getCookie('whatsappcountrycode')
    const submitButton = props.submitButton
    const whatsAppNumber = process.env.REACT_APP_WHATSAPP_SERVICENUMBER
    const whatsAppShareUrl = 'https://wa.me/' + whatsAppNumber + '?text='

    const onSubmit: SubmitHandler<Model> = model => {

        let url = process.env.REACT_APP_API_CLIQUES
        data.post(url, model, (resp:response<clique>)=>{

            if(!resp.data){
                if(resp.message){
                    popup.show(resp.message)
                }               
            }else{

                // Save whatsapp number in cookie
                if(model.mobileCountryCode){
                    cookie.setCookie('whatsappcountrycode', model.mobileCountryCode)
                }

                if(model.mobile){
                    cookie.setCookie('whatsappnumber', model.mobile)
                }

                let requestMessage = 'Hello, send me my clique invite for ' + model.name + '.'
                window.open(whatsAppShareUrl + encodeURIComponent(requestMessage))
                invite.hide()

                // Inform user what to expect
                let popMessage:any = <>You will receive your clique invite on WhatsApp, if you sent the request message to <Link to='#' onClick={()=>{window.open(whatsAppShareUrl + encodeURIComponent(requestMessage))}}>{whatsAppNumber}</Link></>
                popup.show(popMessage)

                // Execute callback if available
                if(initInvite.callback){
                    initInvite.callback()
                }
                
                if(submitButton && submitButton.callback){
                    submitButton.callback()
                }
            }

        }, ()=>{})

    }

    const fetchCountries = () => {
        const url = process.env.REACT_APP_API_COUNTRIES + '?orderby=iso3'
        data.get(url, (resp: response<country[]>) => {
            let countries = resp?.data
            if(countries && isSubscribed){
                setCountries(countries)

                let userCountry = undefined
                if(countries.length >= 1){

                    // Choose last used country or default to user country
                    
                    if(lastMobileCountryCode){
                        userCountry = countries.filter(c=>c.countryCode === lastMobileCountryCode)[0]
                    }else{
                        userCountry = countries.filter(c=>c.countryCode === user?.profile?.countryCode)[0]
                    }

                    setValue('country', userCountry ?? countries[0])
                }
                
            }
        }, () => { })
    }

    useEffect(() => {
        
        // fetch all countries
        if(countries.length === 0){
            fetchCountries()
        }

        return () => {            
            // unsubscribe to prevent updating state if unmounted          
            setIsSubscribed(false)
        }
        
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <ModalContext.Provider value={invite}>

            <Modal show={invite.visible} onHide={invite.hide} contentClassName={"border border-secondary " + theme.background} className="p-2 pb-0" centered>
                <Modal.Header>
                    <Modal.Title className="p-3 lh-1"></Modal.Title>
                    <CloseButton className={theme.name === 'dark' ? 'btn-close-white' : ''} onClick={()=>invite.hide()} />
                </Modal.Header>
                <Modal.Body className="p-5 pt-2 pb-0">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <p className="lh-1">
                            A clique is your private circle of friends.
                        </p>

                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Clique name</label>
                            <input className={"form-control form-control-lg " + theme.background + ' ' + theme.color} id="name" {...register("name", {
                                required: 'Clique name is required'
                            })} maxLength={40} placeholder="Name your clique" />
                            {errors.name && <small className="text-danger">{errors.name.message}</small>}
                        </div>
                        
                        <div className="mb-3 mt-3">
                            <label htmlFor="mobile" className="form-label"><i className="bi bi-whatsapp fs-5"></i> WhatsApp number</label>
                            <div>
                                <InputGroup>
                                    <Dropdown as={ButtonGroup}>
                                        <Dropdown.Toggle className={theme.color + " " + theme.background + " border border-secondary"}>
                                            +{country?.countryCode}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className={"border border-secondary " + theme.background} style={{maxHeight:300, overflow:'scroll'}}>
                                            {
                                                countries.map((country, index)=>{
                                                    return <Dropdown.Item className="pt-3 pb-2" onClick={()=>setValue('country', country)} key={index}>{country.iso3} +{country.countryCode}</Dropdown.Item>
                                                })
                                            }                                    
                                        </Dropdown.Menu>
                                        
                                    </Dropdown> 
                                    
                                    <input defaultValue={lastUsedWhatsApp} type="text" {...register("mobile", {
                                        required: 'WhatsApp number is required',
                                        pattern: { value: mobilePattern, message: 'Enter a valid WhatsApp number without leading zero' }
                                    })} autoComplete="off" maxLength={11} className={"ps-2 form-control form-control-lg " + theme.background + ' ' + theme.color} id="mobile" placeholder="Your WhatsApp" />

                                </InputGroup>

                            </div>
                            
                            {
                                (!errors.mobile && !watchMobile) &&
                                <div className="form-text fs-6 lh-1">
                                    Leave out the first zero
                                </div>
                            }
                            {
                                errors.mobile && 
                                <div className="lh-1 mt-1">
                                    <small className="text-danger lh-1">{errors.mobile.message}</small>
                                </div>}
                        </div>

                        <div className="mb-4 lh-1">
                            <div className="form-text">You will receive your clique invite on WhatsApp which you can send to friends.</div>
                        </div>

                        <div className="d-grid gap-2">
                            <button type="submit" className="btn btn-lg btn-primary" disabled={data.isBusy()}>
                                Request invite <Spinner visibility={data.isBusy()} isCentered={false} />
                            </button>
                        </div>

                        <div className="mt-3 fs-6 lh-1">
                            {/* <span>You will receive your clique invite on WhatsApp which you can send to friends.</span> */}
                        </div>

                    </form>

                </Modal.Body>
                <Modal.Footer className="pb-3">
                    {/* <Button variant="primary" onClick={()=>{}}>Request</Button> */}
                </Modal.Footer>
            </Modal>            

        </ModalContext.Provider>
    )

}