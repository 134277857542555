type util = {
    titleCase: Function,
    clipboardCopy : Function,
    money : Function,
    emailRegex: Function,
    passwordRegex : Function,
    mobileRegex : Function,
    titleSeparator: Function,
    appendSex: Function,
    composeUrl:Function,
    getFriendlyFixtureDate: Function,
}

export default function Util() : util {

    return {
        titleCase: (phrase: string) => {
            if (!phrase) return phrase
            return phrase.replace(/\w\S*/g, function (word: string) {
                return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
            })

        },
        clipboardCopy: (textToCopy: string, callback?: Function, failback?: Function) => {
            if (!textToCopy) return false
            navigator.clipboard.writeText(textToCopy)
             //.then(()=> {
                 //if (callback) callback()
             //})//, function () {
            //     if (failback) failback()
            // })
            return true
        },
        money: (amount: number) => {
            return (amount / 100).toLocaleString()
        },
        emailRegex: () => { 
            const emailPattern = /^([\w.%+-]{3,})@([\w-]{2,}\.)+([\w]{2,})$/i
            return emailPattern 
        },        
        passwordRegex : () => {
            const specialCharacters = "`!@#$%^&*()_+\\-=\\[\\]{};:'\",.<>\\/?"
            const passwordPattern = '^(?=.*\\d)(?=.*[' + specialCharacters + '])(?=.*[a-z])(?=.*[A-Z]).{8,}$'
            return new RegExp(passwordPattern)
        },
        mobileRegex : () => {
            const mobilePattern = /^[1-9]{1,1}[0-9]{7,10}$/i
            return new RegExp(mobilePattern)
        },
        titleSeparator : ()=>{
            return " "
        },
        appendSex : (sex: string)=>{
    
            if(sex){
                sex = sex.toString()
                if(sex === '1') return 'female/'
                if(sex === '2') return 'male/'
            }
            
            return ''
        },
        composeUrl : (url:string, bits:string[])=>{

            if(!url || !bits || bits.length === 0) {
                return url
            }

            let templateRegex = new RegExp(/\{[a-z]{1,}\}/gi)
            let matches = url.match(templateRegex)

            if(matches) {
                for(let i = 0; i < matches.length; i++){

                    if(!bits[i]){
                        break
                    }
    
                    url = url.replace(matches[i], bits[i])
                }
            }            

            return url
        },
        getFriendlyFixtureDate : (startDate: string) => {

            let result = ''
        
            if(startDate){
                const date = new Date(startDate)
                const dateParts = date.toDateString().split(' ')
                let friendlyDate = dateParts[0] + ' ' + dateParts[1] + ' ' + dateParts[2]
                if(date.getMonth() === 11 || date.getMonth() === 0){
                    friendlyDate = friendlyDate + ' ' + dateParts[3]
                }
        
                friendlyDate = friendlyDate + ', '
        
                const timeParts = new Date(startDate).toLocaleTimeString().split(':')
                const friendlyTime = timeParts[0] + ':' + timeParts[1]
        
                result = friendlyDate + friendlyTime
            }
        
            return result
        }
    }
}

export function Pluralize(props :any){
    let count:number = props.count
    let single:string = props.single
    let plural:string = props.plural
    let word = single

    if(!single){
        word = '*Missing single form of word*'
    }else if(!plural){
        word = '*Missing plural form of word*'
    }    
    
    if(single && plural && count !== 1) {
        word = plural
    }
    
    return <>{word}</>
}
