import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth } from "../context/auth";
import DashNav from "../home/dashnav";
import useData, { Currency, Spinner } from "../utils/data";
import { useToast } from "../utils/toast";

type fundrequest = { reference: string, firstName: string, surname: string, amount: number }


export default function AdminFund() {
    const toast = useToast()
    const data = useData()
    const auth = useAuth()
    const authContext = auth.context
    const user = authContext.getUser()
    const wallet = user?.wallet
    const [showConfirm, setShowConfirm] = useState(false)
    const [fundRequest, setFundRequest] = useState<fundrequest>()
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const [fundRequests, setFundRequests] = useState<fundrequest[]>([])    

    const handleClick = (req: fundrequest, index: number) => {
        if (req) {
            setFundRequest(req)
            setSelectedIndex(index)
            setShowConfirm(true)
        }
    }

    const handleApprove = () => {
        if (!fundRequest) {
            toast.show('No request. Select a request to approve.')
            return false
        }

        const url = process.env.REACT_APP_API_PROCESSTRANSFER + '?reference=' + fundRequest.reference + '&action=1'
        data.post(url, {}, (resp: any) => {
            if (resp && resp.data) {
                fundRequests.splice(selectedIndex, 1)
                setFundRequests(fundRequests)
                setShowConfirm(false)
                toast.show('Fund wallet request approved.')
            } else {
                if (resp) toast.show(resp.message, 'error')
            }
        }, (resp: any) => { if (resp) toast.show(resp.message, 'error') })
    }

    const handleReject = () => {
        if (!fundRequest) {
            toast.show('No request. Select a request to reject.')
            return false
        }
        const url = process.env.REACT_APP_API_PROCESSTRANSFER + '?reference=' + fundRequest.reference + '&action=0'
        data.post(url, {}, (resp: any) => {
            if (resp && resp.data) {
                fundRequests.splice(selectedIndex, 1)
                setFundRequests(fundRequests)
                setShowConfirm(false)
                toast.show('Fund wallet request rejected.')
            } else {
                if (resp) toast.show(resp.message, 'error')
            }
        }, (resp: any) => { if (resp) toast.show(resp.message, 'error') })
    }

    useEffect(() => {
        data.get(process.env.REACT_APP_API_TRANSFERS, (resp: any) => {
            if (resp) {
                setFundRequests(resp.data)
            }
        })
    }, [selectedIndex]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <DashNav title="Fund requests">
                <p>
                    {fundRequests.length === 0 ?
                        "There are no pending requests yet." :
                        fundRequests.length === 1 ?
                            "There's a new request, please treat urgently."
                            :
                            "There are " + fundRequests.length + " pending requests, please treat urgently."}
                </p>

                {fundRequests && fundRequests.length > 0 && <p className="form-text">
                    First, confirm that the amount has been received before approving, otherwise reject.
                </p>}
                <ol className="list-group list-group-flush">
                    {
                        fundRequests.map((req: any, index: number) => {
                            return (
                                <li key={index} className="list-group-item d-flex justify-content-between align-items-start ps-0 pe-0" onClick={() => handleClick(req, index)}>
                                    <div className="me-auto">
                                        <div className="fw-bold">
                                            <Link to="#">{req.reference}</Link>
                                        </div>
                                        {req.firstName + ' ' + req.surname}
                                    </div>
                                    <Link to="#"><span className="badge bg-primary rounded-pill"><Currency symbol={wallet?.currency?.html} />{(req.amount / 100).toLocaleString()}</span></Link>
                                </li>
                            )
                        })
                    }
                </ol>

            </DashNav>

            <Modal show={showConfirm} onHide={() => setShowConfirm(false)} className="p-2" centered>
                <Modal.Header closeButton>
                    <Modal.Title className="p-4">Important<i>!</i></Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-5 pt-4">
                    <p className="mb-5">
                        Have you confirmed that this {fundRequest ? <strong>
                            {<Currency symbol={wallet?.currency?.html} />}{(fundRequest?.amount / 100).toLocaleString()}
                        </strong> : 'amount'} has been received from <strong>{fundRequest?.firstName} {fundRequest?.surname}</strong>? If so then approve, otherwise <Link to="#" onClick={() => handleReject()} className="fw-bold text-danger">Reject</Link> this request.
                    </p>
                    <div className="d-grid gap-2">
                        <button type="button" className="btn btn-primary" disabled={data.isBusy()} onClick={() => handleApprove()}>
                            Yes, approve {data.isBusy()} <Spinner visibility={data.isBusy()} isCentered={false} />
                        </button>
                    </div>
                </Modal.Body>
            </Modal>            
            
        </>
    )

}