import { useState, useContext, createContext, useEffect } from "react"
import { ButtonGroup, CloseButton, Dropdown, InputGroup, Modal } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { useAuth } from "../context/auth"
import { useTheme } from "../context/theme"
import { product, response } from "../utils"
import useData, { Spinner } from "../utils/data"
import BuyCoinsImage from "../assets/scss/fantastake_coupon.png"
import { usePopup } from "../utils/popup"
import usePaystack from "../utils/paystack"

type Advice = {
    ref: string,
    email: string,
    amount: number,
    key: string
}

export const BuyContext = createContext<any>({})

export const useBuy = (callback?: any)=>{

    let context = useContext(BuyContext)
    if(callback){
        context.callback = callback
    }
    
    return context
}

export default function BuyCoinsProvider(){

    const auth = useAuth()
    const popup = usePopup()
    const authContext = auth.context
    const user = authContext.user
    const themeContext = useTheme()
    const theme = themeContext.theme
    const data = useData()
    const { register, watch, handleSubmit, setValue, formState: { errors } } = useForm<Advice>()
    const [visible, setVisible] = useState(false)
    const amount = watch("amount", 0)
    const [coupons, setCoupons] = useState<product[]>([])
    const [isSubscribed, setIsSubscribed] = useState(true)    
    const paystack = usePaystack()

    const onSubmit: SubmitHandler<Advice> = model => {

        generateAdvice(model.amount)

    }

    let context = useBuy()
    context.show = ()=> setVisible(true)
    context.hide = () => setVisible(false)

    const fetchProductCoupons = ()=>{

        const url = process.env.REACT_APP_API_PRODUCTCOUPONS
        data.get(url, (resp : response<product[]>)=>{

            if(resp && resp.data){
                const result = resp.data
                isSubscribed && setCoupons(result)
            }
        })
    }

    const generateAdvice = (amount:number) => {
        
        // Get reference and determine payment provider to use
        const url = process.env.REACT_APP_API_PAYREFERENCE + '?amount=' + amount + '&currency=' + user?.wallet?.currencyIso3
        data.get(url, (resp: any) => {
            if(resp){
                if(resp.message){
                    popup.show(resp.message)
                    return
                }

                let result = resp.data
                if(user && result){
                    let advice = {
                        ref: result.reference,
                        email: user.username,
                        amount: result.amount,
                        key: result.publicKey,
                    }

                    // Present paystack/flutterwave option only if there's a key
                    if (advice.key) {
                        paystack.pop(advice, (resp: any) => {
                            setVisible(false)
                            let message = 'Please allow a short while for verification. Your balance will be updated soon after.'                                
                            popup.show(message)  

                            // Convert amount to lowest currency unit by multiplying by 100
                            if(user && user.wallet && user.wallet.currencyIso3){

                                let model = { reference: advice.ref, amount: { value: advice.amount, currencyIso: user.wallet.currencyIso } }
                                data.post(process.env.REACT_APP_API_WALLET, model, (resp: any) => {
                                    
                                    if(resp){
                                        if(resp.errors && Object.keys(resp.errors).length > 0){
                                            popup.show('Failed to verify your transaction. Please contact support@fantastake.com with your reference: '+ model.reference)
                                        }
                                    }                                                                  
                                })
                            }
                            
                        }, () => {
                            popup.show("Having card trouble? Make a direct transfer.")
                            setVisible(false)
                        })
                    } else {
                        // Present direct transfer option
                        popup.show("Card payment providers are unavailable at this time. Please use the Direct Transfer option.")
                        setVisible(false)
                    }
                }
            }            
            
        })
    }

    useEffect(()=>{

        if(coupons.length === 0){
            fetchProductCoupons()
        }

        return ()=> setIsSubscribed(false)

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <BuyContext.Provider value={context}>

            <Modal show={visible} onHide={context.hide} contentClassName={"border border-secondary " + theme.background} className="p-2 pb-0" centered>
                <Modal.Header>
                    <Modal.Title className="p-3 lh-1"><i className={"bi me-1 bi-coin text-warning " + theme.color} /> Buy coins</Modal.Title>
                    <CloseButton className={theme.name === 'dark' ? 'btn-close-white' : ''} onClick={context.hide} />
                </Modal.Header>
                <Modal.Body className="p-5 pt-2 pb-0">

                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* <p className="lh-1">
                            Choose coupon amount
                        </p> */}
                        <div style={{minHeight:200}} className="mb-3">
                            <img src={BuyCoinsImage} width="100%" alt="Buy coins coupon" />
                        </div>                        

                        <div className="mb-3">
                            <label htmlFor="payoutAmount" className="form-label lh-1">Coupon amount</label>

                            <InputGroup size="lg">
                                <Dropdown as={ButtonGroup} {...register("amount", { 
                                required : "Amount is required",
                            })}>
                                    <Dropdown.Toggle className={theme.background + " border border-secondary text-start d-flex align-items-center"} style={{width:'100%'}}>
                                        <div className={"flex-fill text-secondary " + theme.color }>{ amount ? (user?.wallet?.currencyIso3 + ' ' + amount.toLocaleString()) : <span className="text-secondary">Select amount</span> }</div> 
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className={"border border-secondary " + theme.background} style={{maxHeight:250, overflow:'scroll'}}>
                                        {
                                            coupons.map((coupon:product, index)=>{
                                                return <Dropdown.Item className="pt-3 pb-2" onClick={()=>{setValue("amount", coupon.amount)}} key={index}>{coupon.amount.toLocaleString()}</Dropdown.Item>
                                            })
                                        }                                    
                                    </Dropdown.Menu>

                                </Dropdown> 

                            </InputGroup>

                            { (errors.amount && !amount) && <small className="text-danger">{errors.amount.message}</small>}

                        </div>

                        {/* <div className={"d-flex align-items-center mt-3 mb-3 lh-1 fs-5 form-text " + theme.color} style={{minHeight:50}}>
                            error message here
                        </div> */}

                        <div className="d-grid gap-2 mt-4 mb-4">
                            <button type="submit" className="btn btn-lg btn-primary" disabled={data.isBusy()}>
                                OK, Proceed <Spinner visibility={data.isBusy()} isCentered={false} />
                            </button>
                        </div>
                        
                    </form>

                </Modal.Body>
            </Modal>            

        </BuyContext.Provider>
    )
}