import { useEffect } from "react";
import { useTheme } from "../context/theme";
import Chain from "../utils/chain";
import Util from "../utils/util";

export default function Terms(){

    const util = Util()
    const themeContext = useTheme()
    const theme = themeContext.theme

    useEffect(()=>{
        document.title = process.env.REACT_APP_WEBSITE_TITLE + util.titleSeparator() + "Terms"
    })

    return(
        <div>
            <h1 className={"mb-5 text-light " + theme.color}>
                Terms and privacy
            </h1>
            <div className="fs-6 pe-3" style={{maxHeight:600, overflowY: 'scroll' }}>            

                <p className={"text-light " + theme.color}>
                    In these Terms of Use, any use of the words "you", "yours" or similar expressions shall mean any user of this website whatsoever. Terms such as "we", "us, "our" or similar expressions shall mean the Fantastake. 
                    By Registering to participate in the Fantastake Heroes League (the "Game") you agree to be bound by and to abide by the following terms and conditions (the "Terms"). You should read these Terms before using the Game. These Terms apply to every occasion that you use the Game. If you do not agree or accept the Terms, you are advised not to use the Game.
                    These Terms may be amended or modified, or new conditions may be imposed, at any time. Any such changes or additions will be reflected by an update of this page. You are therefore advised to check these Terms periodically for changes. Your continued use of the Game following the posting of changes to these Terms will mean you accept those changes and that such changes shall apply to your use of the Game after such changes have been posted.
                </p>

                <h5 className={"mt-5 mb-4 text-light " + theme.color}>Under Eighteen (18)</h5>

                <p className={"text-light " + theme.color}>
                    Under 18s may Register to play the Game. If you are under 18, you may need your parent/guardian to help you with your use of the Game and with reading these Terms. If anything is hard to understand, please ask your parent/guardian to explain. If you still have any questions, you or your parent/guardian can contact us at: hello@fantastake.com.
                </p>

                <Chain titleTag="h5" sectionTag="p">
                    <h5 className="text-light">Signup and Registration</h5>
                    <p>
                    All applications to participate in the Game must be made online on the Fantastake website at www.fantastake.com (the "Site") or via the Fantastake mobile application (the "App"). Where your application is accepted, you have successfully "Registered" and you will become a "Player" in the Game. Each player is then able to create a banters.
                    </p>
                    <h5 className="text-light">The Game Rules</h5>
                    <p>
                    The Game will be governed by the Game Rules (as amended from time to time) published on the Site by the Fantastake ("Game Rules"). The Fantastake reserves the right to alter, amend or supplement the Game Rules in its absolute discretion. You agree that no liability shall attach to the Fantastake as a result of any such change and are advised to check the Game Rules regularly. The Game Rules form part of these Terms and by Registering for the Game, you agree to accept the Game Rules.
                    </p>
                    <p>
                    There will be no prizes or rewards for winning Players but the Fantastake will be the sole decision-maker of any matter of interpretation of the Game Rules and any aspect of the content of, or playing of, the Game. The Fantastake will not enter into correspondence relating to such matters and its decision as to any matter arising out of or in connection with the Game Rules including but not limited to the allocation of points to any Player and/or any ranking or league table shall be final and conclusive.
                    </p>
                    <h5 className="text-light">Responsibility and Remedies</h5>
                    <p>The extent of the Fantastake's responsibility to you has been determined in the context of the following: (a) the Game is provided to you free of charge and on an “as is” basis;</p>
                    <h5 className="text-light">Breach</h5>
                    <p>Any breach by you of the Game Rules from time to time shall also be a breach of these Terms.</p>
                    <p>In the event of any breach by you of these Terms the Fantastake reserves the right in its sole discretion to: (a) take any action described in term 23 above;</p>

                </Chain>
            </div>
        </div>
        
    )

}