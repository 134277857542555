import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Login from '../auth/login'
import { useFavTeam } from './favteam'
import { Pluralize } from '../utils/util'
import { useTheme } from '../context/theme'
import { useAuth } from '../context/auth'
import { Cookie } from '../utils/cookie'
import { response } from '../utils'
import useData, { setCookie, Spinner } from '../utils/data'
import Messages from '../utils/messages'
import { useToast } from '../utils/toast'
import { useRedeem } from '../wallet/redeem'
import { useInviteCode } from '../auth/invite'
import { ButtonGroup, Dropdown } from 'react-bootstrap'
import { useBuy } from '../wallet/buy'

//import { useBuy } from '../wallet/buy'

export default function DashNav({ children, title, initCallback, pointsCallback }: any) {

    const themeContext = useTheme()
    const theme = themeContext.theme
    const favTeamPop = useFavTeam()
    const cookie = Cookie()
    const toast = useToast()
    const redeem = useRedeem()
    const messages = Messages()
    const inviteCode = useInviteCode()
    const buy = useBuy()

    const data = useData()
    const auth = useAuth()
    const authContext = auth.context
    let isAuthenticated = cookie.getCookie('token') !== ''

    const userProfile = authContext.getUser()
    const user = { ...authContext.user, ...userProfile  }    
    const isUserEmailVerified = user?.profile?.isEmailVerified === '1'
    
    const favTeam = authContext.favTeam
    const [isEmailVerified, setIsEmailVerified] = useState(isUserEmailVerified)
    
    const [isSubscribed, setIsSubscribed] = useState(true)
    const { activation, invitecode } = useParams<any>()    

    // Drop cookies
    if(invitecode){
        //Cookie expires in 24hrs
        cookie.setCookie('invitecode', invitecode, 24*60*60);
    }

    const cliqueInviteCode = cookie.getCookie('invitecode')

    const acceptClickInvite = ()=>{

        inviteCode.acceptInvite(cliqueInviteCode)
    }

    const activate = (callback:Function) => {
        if (activation) {            
            const url = process.env.REACT_APP_API_VERIFYEMAIL + '?activationCode=' + activation
            data.post(url, {}, (data: any) => {
                if (data) {
                    if (data.errors) {
                        let error: any = Object.values(data.errors)[0]
                        toast.show(error, 'error');
                    } else {
                        toast.show(data.message, 'success');
                        setIsEmailVerified(true)
                        if(callback) callback()
                    }
                }
            })
        }
    }

    const fetchFavTeam = (context : any, result? : any) => {
        data.get(process.env.REACT_APP_API_FAVTEAM, (resp: response<any>) => {
            
            if(resp && resp.data && isSubscribed){

                if(!resp.data.name){
                    // prompt user to choose a favourite team if none chosen yet
                    if(favTeamPop.show){
                        favTeamPop.show()
                    }                    
                }else{

                    // update auth context
                    if(context){

                        auth.updateContext({ ...context, favTeam : resp.data })
                    }else{
                        auth.updateContext({ ...authContext, favTeam : resp.data })
                    }                  
                }
            }
        })
    }

    // Get full user and wallet

    const fetchUser = (callback?: Function) => {
        const endpoint = process.env.REACT_APP_API_USERPROFILE
        
        data.post(endpoint, {}, (resp: any) => {

            if (resp.data) {
                
                const updatedUser = { 
                    ...user, 
                    position : resp.data.profile.position, 
                    wallet : resp.data.wallet 
                }

                const updatedContext = { ...authContext, user : updatedUser }

                // update auth context
                auth.updateContext(updatedContext)

                if (callback) callback(updatedContext, resp)

            }
        })
    }

    useEffect(() => {

        if(isAuthenticated) {
            
            // get user stats
            fetchUser((context : any, result:any)=>fetchFavTeam(context, result))

            // if user is authenticated execute the child callback
            if(initCallback) {
                initCallback()
            }

            // set user email verification status
            if(user?.profile && !user?.profile?.isEmailVerified){

                setIsEmailVerified(false)
            }            
        }

        // confirm user email
        activate(()=>{
            
            // Update tokens
            let username = user.username;
            const url = process.env.REACT_APP_API_REFRESHTOKEN + '?username=' + username
            data.post(url, {}, (resp: any) => {
                if(resp.data.token){
                    // Update stale tokens
                    setCookie('token', resp.data.token)
                    setCookie('refreshToken', resp.data.refreshToken)

                }
            })
        })

        
        return () => {
            setIsSubscribed(false)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (!isAuthenticated) {
        let showPopup = true
        if(activation) {
            showPopup = false
        }

        return <Login showPopup={showPopup} />
    }

    return (
        <>
            <div>
                {
                    user &&
                    <div className="mb-1">
                        <div className="d-flex align-items-center">

                            <Link to="/profile">
                                <div className="me-2 bg bg-primary fw-bold text-light border border-primary rounded-circle d-flex justify-content-center align-items-center" 
                                    style={{background: `url(` + user.profile?.avatar + `)`, cursor: 'pointer', width:50, height:50, backgroundSize:'cover', backgroundRepeat: 'no-repeat', backgroundPosition:'center'}}>
                                    { 
                                        !user.profile?.avatar && <i className="bi bi-person-fill fs-1"></i>
                                    }
                                </div>
                            </Link>

                            {
                                favTeam &&
                                <Link to="#" style={{marginLeft:-22}} onClick={()=>favTeamPop.show()}>
                                    <div className="me-2 rounded-circle bg-primary">
                                            <img src={favTeam.logo} width="50px" height="50px" className="border border-4 border-primary rounded-circle" alt={favTeam.name} />
                                    </div>
                                </Link>
                            }                                                            
                            
                            <div className={"mt-1 d-flex flex-column text-light " + theme.color}>
                                <Link to="/profile">
                                    <div className="fs-3 lh-1">
                                        { user && user.profile && user.profile.firstName } 
                                    </div>
                                </Link>

                            </div>
                        </div>

                    </div>
                }

                <div className="mb-4">
                    <div className="d-flex justify-content-end align-items-top">
                        {
                            user && user.wallet?.friendlyBalance &&
                            <Dropdown as={ButtonGroup} variant="transparent" className="noarrow" title="Your coins">
                                <Dropdown.Toggle variant={"btn border-0 ps-1 pe-1 pb-2 pt-1 text-light fs-5 " + theme.color}>
                                    <span>
                                        {user.wallet?.friendlyBalance} <Pluralize count={user.wallet?.balance} single='coin' plural='coins' />
                                    </span> <i className={"bi me-1 bi-coin text-warning " + theme.color} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={"text-end border border-secondary " + theme.background}>
                                    <Dropdown.Item eventKey="1" to="#" className={"p-3 ps-4 pe-4 fs-5 " + theme.background}>
                                        <span className={"text-light " + theme.color} onClick={()=>buy.show()}>Buy coins</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="2" className={"p-3 ps-4 pe-4 fs-5 " + theme.background}>
                                        <span className={"text-light " + theme.color} onClick={() => redeem.show(messages.aboutCoins, 'Redeem coins')}>
                                            Redeem coins
                                        </span>
                                    </Dropdown.Item>                                                                      
                                </Dropdown.Menu>
                                
                            </Dropdown>
                            /*<Link className="fs-5" to="#" onClick={() => redeem.show(messages.aboutCoins, 'Coins')}>
                                <span className="ellipsis me-2">
                                    {user.wallet?.friendlyBalance} <Pluralize count={user.wallet?.balance} single='coin' plural='coins' />
                                </span>
                            </Link>*/                                
                        }

                        {/* <Link className="fs-5" to="#" onClick={() => redeem.show(messages.aboutCoins, 'Coins')}>
                            <i className={"bi me-1 bi-coin text-warning " + theme.color} />
                        </Link> */}

                        {/* <span className="badge pe-0">
                            {
                                favTeam && favTeam.logo !== undefined &&
                                <div className="rounded-circle" style={{cursor: 'pointer', width:24, height:24, backgroundSize:'cover', backgroundRepeat: 'no-repeat', backgroundPosition:'center', backgroundImage: `url(${favTeam.logo})`}} onClick={()=>favTeamPop?.show()}></div>
                            }
                        </span> */}
                    </div>
                </div>
                
                <div className='d-flex justify-content-between'>
                    {
                        title && 
                        <h1 className={"mb-4 text-light " + theme.color}>
                            {title}
                        </h1>
                    }
                                        
                </div>
                {
                    isEmailVerified !== undefined && isEmailVerified === false && 
                    <div className="mb-5 lh-1">
                        <div className="alert alert-warning" role="alert">
                            <small>{ messages.emailNotVerified }</small>
                        </div> If you need to request a new confirmation email, use the <span className="fs-6 badge bg-danger rounded-pill">not verified</span> option on <Link to="/profile">your profile</Link>.
                    </div>
                    
                }

                { /* Notifications bar */ }

                {
                    isEmailVerified && cliqueInviteCode &&
                    <div className="alert alert-warning lh-1" role="alert">
                        You have a pending clique invite expiring soon <button onClick={()=>acceptClickInvite()} className="btn btn-primary rounded-pill ps-3 pe-3">Accept <Spinner visibility={data.isBusy()} isCentered={false} /></button>
                    </div>
                }
                
            </div>

            {children}

            
        </>
    )
}