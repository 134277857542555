import useData, { Spinner } from "../utils/data";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTheme } from "../context/theme";
import { usePopup } from "../utils/popup";
import { useAuth } from "../context/auth";
import { useEffect, useState } from "react";
import { bank, country, response, trophy } from "../utils";
import { Link } from "react-router-dom";
import { ButtonGroup, Dropdown, InputGroup } from "react-bootstrap";
import Messages from "../utils/messages";
import { Cookie } from "../utils/cookie";
import Util from "../utils/util";

type Model = {
    mobile: string,
    mobileCountryCode: string,
    amount: number,
    bankId: number,
    country?: country,
}

export default function Coupon(props: any) {

    const messages = Messages()
    const auth = useAuth()
    const authContext = auth.context
    const user = authContext.user
    const cookie = Cookie()
    const util = Util()

    const themeContext = useTheme()
    const theme = themeContext.theme
    const popup = usePopup()
    const data = useData()
    const { register, watch, handleSubmit, setValue, formState: { errors } } = useForm<Model>()    

    const [banks, setBanks] = useState<bank[]>([])
    const [countries, setCountries] = useState<country[]>([])
    const [trophies, setTrophies] = useState<trophy[]>([])
    const [isSubscribed, setIsSubscribed] = useState(true)    
    const [bank, setBank] = useState<bank>()

    const country = watch("country")
    const whatsAppNumber = process.env.REACT_APP_WHATSAPP_SERVICENUMBER//"15550938598"
    const whatsAppShareUrl = 'https://wa.me/' + whatsAppNumber + '?text='
    const requestMessage = "Hello, I would like to receive my fantastake coupon on WhatsApp."
    const lastUsedWhatsApp = cookie.getCookie('whatsappnumber')
    const lastBankId = cookie.getCookie('lastbankid')
    const whatsappCountryCode = cookie.getCookie('whatsappcountrycode')
    //const [isShowingSaveInfo, setIsShowingSaveInfo] =  useState(true)
    
    const amount = watch("amount", 0)
    if(lastBankId){
        watch("bankId", parseInt(lastBankId))
    }    
    
    const watchMobile = watch("mobile", lastUsedWhatsApp)  
    const submitButton = props.submitButton
    const mobilePattern = util.mobileRegex()

    const onSubmit: SubmitHandler<Model> = model => {

        if(model.country){
            model.mobileCountryCode = model.country.countryCode
        }

        data.post(process.env.REACT_APP_API_REQUESTCOUPON, model, (resp: any) => {
            
            if (resp.data) {

                if(resp.data.wallet){

                    const updatedUser = { ...user, wallet: resp.data.wallet }
                    const updatedContext = { ...authContext, user : updatedUser }
                    auth.updateContext(updatedContext)
                }

                // Cache user's choices for subseqent use
                if(model.mobile !== 'undefined'){
                    cookie.setCookie('whatsappnumber', model.mobile)
                }
                
                if(model.bankId > 0){
                    cookie.setCookie('lastbankid', model.bankId)
                }
                
                if(model.mobileCountryCode !== 'undefined'){
                    cookie.setCookie('whatsappcountrycode', model.mobileCountryCode)
                }
                
                
                let message = 'Your coupon code was sent to your email. You can also request to receive it by WhatsApp.'
                popup.show(message, null, { okayButton: { text: 'Request', callback : ()=>handleRequestClick() } })

                if(submitButton.callback){
                    submitButton.callback()
                }

            }else{

                if (resp.message) {
                    popup.show(resp.message)
                } else{
                    popup.show('Request failed. Please confirm your connection and try again.', 'Failed')
                }
            }            

        })

    }

    const handleRequestClick = () =>{
        window.open(whatsAppShareUrl + encodeURIComponent(requestMessage))
    }

    // const handleOpenServiceChat = ()=>{
    //     //setIsShowingSaveInfo(false)
    //     window.open("https://wa.me/" + whatsAppNumber)
    // }

    const fetchBanks = () => {
        const url = process.env.REACT_APP_API_BANKS
        data.get(url, (resp: response<bank[]>) => {
            let banks = resp?.data
            if(banks && isSubscribed){
                setBanks(banks)

                // Choose last used bank if any
                if(lastBankId){
                    const lastBank = banks.filter(b=>b.id.toString() === lastBankId)[0]
                    if(lastBank){
                        setBank(lastBank)
                        setValue("bankId", lastBank.id)
                    }
                }
            }
        }, () => { })
    }

    const setWhatsappCountryCode = (countries:country[])=>{

        if(!countries) {
            return
        }

        let userCountry = undefined
        if(countries.length > 0){

            // Choose last used country or default to user country
            
            if(whatsappCountryCode){
                userCountry = countries.filter(c => c.countryCode === whatsappCountryCode)[0]
            }else{
                userCountry = countries.filter(c=>c.countryCode === user?.profile?.countryCode)[0]                
            }            

            setValue('country', userCountry ?? countries[0])
        }

    }

    const fetchCountries = () => {
        const url = process.env.REACT_APP_API_COUNTRIES + '?orderby=iso3'
        data.get(url, (resp: response<country[]>) => {
            let countries = resp?.data
            if(countries && isSubscribed){
                setCountries(countries)
                setWhatsappCountryCode(countries)                
            }
        }, () => { })
    }

    const fetchTrophies = (countryCode: string) => {

        if(!countryCode){
            return;
        }

        const url = process.env.REACT_APP_API_TROPHIES + '?countrycode=' + countryCode
        data.get(url, (resp: response<trophy[]>) => {
            let trophies = resp?.data
            if(trophies && isSubscribed){
                setTrophies(trophies)
                
            }
        }, () => { })
    }

    useEffect(() => {
        
        // fetch all banks
        if(banks.length === 0){
            fetchBanks()
        }
        
        // fetch all countries
        if(countries.length === 0){
            fetchCountries()
        }else{
            setWhatsappCountryCode(countries)
        }

        // fetch trophies of country
        if(trophies.length === 0){            
            let countryCode = user?.profile?.countryCode
            if(countryCode){
                fetchTrophies(countryCode)
            }
        }

        return () => {            
            // unsubscribe to prevent updating state if unmounted          
            setIsSubscribed(false)
        }
        
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>

            <div className="mt-4 fs-5 lh-1">

                <Link className="text-warning" to="#" onClick={()=>popup.show(messages.howDoesCouponWork, 'How does cash redemption work?')}>
                    How does it work? <i className="bi bi-info-circle-fill fs-6"></i>
                </Link>

                {
                    <div className="mt-4">

                        {/* <p>
                            Before you begin, save the Fantastake WhatsApp self-service line <Link to="#" onClick={()=>handleOpenServiceChat()}>+{whatsAppNumber}</Link> to your contacts.
                        </p> */}

                        {/* <p>Why? So that coupon links that you receive from this line will be active and easy to use for your convenience.</p>
                        
                        <p className="mt-5">
                            <Link to="#" className="" onClick={()=>handleOpenServiceChat()}>
                                Okay, continue
                            </Link>
                        </p> */}
                        
                    </div>
                }
                
            </div>

            {
                //(lastUsedWhatsApp !== '' || isShowingSaveInfo === false) &&
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-3">
                        <label htmlFor="banker" className="form-label lh-1">Bank <Link to="#" onClick={()=>popup.show(messages.bankRequiredInfo)}><i className="bi bi-info-circle-fill fs-6"></i></Link></label>                    
                        
                        <InputGroup size="lg">
                            <Dropdown as={ButtonGroup} {...register("bankId", { 
                            required : "Bank is required",
                        })}>
                                <Dropdown.Toggle className={theme.background + " border border-secondary text-start d-flex align-items-center"} style={{width:'100%'}}>
                                    <div className={"flex-fill text-secondary " + theme.color }>{ bank ? bank.name : <span className="text-secondary">Select your bank</span> }</div> 
                                </Dropdown.Toggle>
                                <Dropdown.Menu  className={"border border-secondary " + theme.background} style={{maxHeight:250, overflow:'scroll'}}>
                                    {
                                        banks.map((bank:bank, index)=>{
                                            return <Dropdown.Item className="pt-3 pb-2" onClick={()=>{setValue("bankId", bank.id); setBank(bank)}} key={index}>{bank.name}</Dropdown.Item>
                                        })
                                    }                                    
                                </Dropdown.Menu>

                            </Dropdown> 

                        </InputGroup>
                                                
                        <div className="border border-0 mt-1">
                            {errors.bankId && !bank && <span className="text-danger">{errors.bankId.message}</span>}                            
                        </div>

                    </div>

                    <div className="mb-3 mt-3">
                        <label htmlFor="mobile" className="form-label lh-1"><i className="bi bi-whatsapp fs-5"></i> WhatsApp number</label>
                        <div>
                            <InputGroup>
                                <Dropdown as={ButtonGroup}>
                                    <Dropdown.Toggle className={theme.color + " " + theme.background + " border border-secondary"}>
                                        +{country?.countryCode}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className={"border border-secondary " + theme.background} style={{maxHeight:300, overflow:'scroll'}}>
                                        {
                                            countries.map((country, index)=>{
                                                return <Dropdown.Item className="pt-3 pb-2" onClick={()=>setValue('country', country)} key={index}>{country.iso3} +{country.countryCode}</Dropdown.Item>
                                            })
                                        }                                    
                                    </Dropdown.Menu>
                                    
                                </Dropdown> 
                                
                                <input defaultValue={lastUsedWhatsApp} type="text" {...register("mobile", {
                                    required: 'Your WhatsApp number is required',
                                    pattern: { value: mobilePattern, message: 'Enter a valid WhatsApp number without leading zero' }
                                })} autoComplete="off" maxLength={11} className={"ps-2 form-control form-control-lg " + theme.background + ' ' + theme.color} id="mobile" placeholder="Your WhatsApp" />

                            </InputGroup>

                        </div>
                        
                        {
                            (!errors.mobile && !watchMobile) &&
                            <div className="form-text fs-6 lh-1">
                                Leave out the first zero
                            </div>
                        }
                        {
                            errors.mobile && 
                            <div className="lh-1 mt-1">
                                <small className="text-danger lh-1">{errors.mobile.message}</small>
                            </div>}
                    </div>
                    <div>
                        <label htmlFor="payoutAmount" className="form-label lh-1">Amount</label>

                        <InputGroup size="lg">
                            <Dropdown as={ButtonGroup} {...register("amount", { 
                            required : "Amount is required",
                        })}>
                                <Dropdown.Toggle className={theme.background + " border border-secondary text-start d-flex align-items-center"} style={{width:'100%'}}>
                                    <div className={"flex-fill text-secondary " + theme.color }>{ amount ? (user?.wallet?.currencyIso3 + ' ' + amount.toLocaleString()) : <span className="text-secondary">Select amount</span> }</div> 
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={"border border-secondary " + theme.background} style={{maxHeight:250, overflow:'scroll'}}>
                                    {
                                        trophies.map((trophy:trophy, index)=>{
                                            return <Dropdown.Item className="pt-3 pb-2" onClick={()=>{setValue("amount", trophy.amount)}} key={index}>{trophy.amount.toLocaleString()}</Dropdown.Item>
                                        })
                                    }                                    
                                </Dropdown.Menu>

                            </Dropdown> 

                        </InputGroup>

                        { (errors.amount && !amount) && <small className="text-danger">{errors.amount.message}</small>}

                    </div>
                    <div className="mb-2 mt-2 lh-1" style={{minHeight:50}}>
                        {                        
                            bank && watchMobile && !errors.mobile && amount > 0 &&
                            <div>                            
                                <small className={"form-text " + theme.color}>Please confirm at least <strong className={"text-light " + theme.color}>{(amount * 1.2).toLocaleString()}</strong> coins in your balance ?</small>
                            </div>                            
                        }
                    </div>
                    <div className="d-grid gap-2">
                        <button type="submit" className="btn btn-lg btn-primary" disabled={data.isBusy()}>
                            OK, Proceed <Spinner visibility={data.isBusy()} isCentered={false} />
                        </button>
                    </div>
                    <div className="mt-4 fs-6 lh-1">
                        <div className="d-flex d-grid gap-2">
                            <i className="fs-6 bi bi-shield-exclamation text-warning"></i>
                            <span>Never give sensitive details like PINs or passwords to anyone.</span>
                        </div>
                    </div>
                </form>
            }            
        </div>
    )

}