import {createContext, useContext, useEffect, useState } from "react";
import { ButtonGroup, CloseButton, Dropdown, InputGroup, Modal } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useStartBanter } from "../banter/startbanter2";
import { useAuth } from "../context/auth";
import { useTheme } from "../context/theme";
import { bantter, contest, contestant, response } from "../utils";
import useData, { Spinner } from "../utils/data";
import { usePopup } from "../utils/popup";
import { useBuy } from "../wallet/buy";

export const JoinContext = createContext({ visible: false, hide: ()=>{}, show:(contest:contest)=>{}})

export const useContestJoin = ()=>{

    return useContext(JoinContext)
}

export default function JoinContestProvider(){

    const themeContext = useTheme()
    const theme = themeContext.theme
    const [isSubscribed, setIsSubscribed] = useState(true)
    const [recentBanters, setRecentBanters] = useState<bantter[]>([])
    const [visible, setVisible] = useState(false)
    const data = useData()
    const [banter, setBanter]= useState<bantter|null>()
    const [selectedContest, setSelectedContest] = useState<contest>()
    const contestTrophy = selectedContest?.trophy
    const join = useContestJoin()
    const banterPopup = useStartBanter()
    const popup = usePopup()
    const auth = useAuth()
    const authContext = auth.context
    const user = authContext.getUser()
    const buy = useBuy()
    //const redeemPopup = useRedeem()

    join.hide = ()=>setVisible(false)
    join.show = (contest: contest)=>{
        
        if(contest){
            setSelectedContest(contest)
        }
        
        setVisible(true)
    }

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<contestant>()

    const onSubmit: SubmitHandler<contestant> = model => {
        
        if(!model){
            return
        }    

        if(selectedContest && user){

            if(user.id === selectedContest.userId){
                popup.show("You're the host for this contest. You're already in this contest")
                return
            }

            if(selectedContest.fixtureId !== model.fixtureId){
                popup.show(`Choose a banter in the ${selectedContest.banter?.name} match.`)
                return
            }

            const url = process.env.REACT_APP_API_CONTESTS

            const contestantModel = { ...selectedContest, id: 0, banter: banter, banterId : model.banterId, contestId : selectedContest.id }

            data.post(url, contestantModel, (resp:response<any>) => {

                if(resp){
                    if(resp.data > 0){
                        popup.show("You have joined this contest. You'll get a notification after the match.")
                    }else if(resp.message){
                        popup.show(resp.message)
                    }
                }

            })
        }
    }

    const fetchRecentBanters = (skip: number, pageSize: number) => {
        const url = process.env.REACT_APP_API_RECENTBANTERS + '?skip=' + skip + '&pageSize=' + pageSize

        data.get(url, (resp: response<any>) => {
            if (resp && resp.data && resp.data.items) {
                let banters = resp.data.items
                if(isSubscribed){
                    // Filter only banters for contest fixture
                    let fixtureBanters = banters
                    if(selectedContest?.fixtureId){
                        fixtureBanters = banters.filter((b:bantter) =>b.fixtureId === selectedContest?.fixtureId)
                    }
                    setRecentBanters(fixtureBanters)
                }
            }
        })
    }

    const changeBanter = (banter:bantter)=>{
        if(banter){

            if(selectedContest){
                if(selectedContest.fixtureId !== banter.fixtureId){
                    popup.show(`Choose a banter in the ${selectedContest.banter?.name} match.`)
                    return
                }
            }            

            setBanter(banter)
            setValue("banterId", banter.id)
            setValue("fixtureId", banter.fixtureId)
        }
    }

    // const openCoins = ()=>{
    //     redeemPopup.show()
    // }

    const buyCoins = ()=>{
        join.hide()
        buy.show()
    }

    const startBanter = ()=>{
        join.hide()
        banterPopup.startNewBanter()
    }

    useEffect(()=>{

        setIsSubscribed(true)

        if(visible){
            fetchRecentBanters(0, 3)
        }        
        
        return ()=>{
            setIsSubscribed(false)
        }

        // visible is the flag to monitor not selectedContest
        
    }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

    

    return (
        <JoinContext.Provider value={join}>
            
            <Modal show={visible} onHide={join.hide} contentClassName={"border border-secondary " + theme.background} className="p-2 pb-0" centered>
                <Modal.Header>
                    <Modal.Title className="p-3 lh-1"><i className={"bi me-1 bi-award " + theme.color} /> Join contest</Modal.Title>
                    <CloseButton className={theme.name === 'dark' ? 'btn-close-white' : ''} onClick={join.hide} />
                </Modal.Header>
                <Modal.Body className="p-5 pt-0 pb-5">
                    {
                        recentBanters.length === 0 &&
                        <div className="lh-1 mt-4 mb-5">
                            <p className="fw-bold">{selectedContest?.banter?.name}</p>
                            <p>You don't have a banter for this match to join the contest, <Link to="#" onClick={()=>startBanter()}>start a banter</Link> for this match.</p>
                        </div>
                    }
                    <form onSubmit={handleSubmit(onSubmit)} className={recentBanters.length === 0 ? 'd-none' : ''}>
                        <p className="lh-1">
                            Bring your banter to the contest.
                        </p>                        
                        
                        <div className="mb-4 lh-1">
                            <label htmlFor="banterId" className="form-label">Banter</label>
                            <InputGroup size="lg">
                                <Dropdown as={ButtonGroup} {...register("banterId", { required : "Your banter is required" })}>
                                    <Dropdown.Toggle className={theme.background + " border border-secondary text-start d-flex align-items-center"} style={{width:'100%'}}>
                                        
                                        <div className={"flex-fill text-secondary " + theme.color }>
                                            {
                                                banter ?
                                                <div>
                                                    <div>
                                                        <img loading="lazy" className="rounded-circle" style={{ width: 20, height:20 }} src={banter.fixture?.homeTeam.logo} alt="" /> {banter?.fixture?.homeTeam.name}
                                                    </div>   
                                                    <div>
                                                        <img loading="lazy" className="rounded-circle" style={{ width: 20, height:20 }} src={banter.fixture?.awayTeam.logo} alt="" /> {banter?.fixture?.awayTeam.name}
                                                    </div>
                                                    <div className={"lh-1 fs-5 pt-2 " + theme.color} style={{whiteSpace:'break-spaces'}}>{banter?.phrase}.</div>                                             
                                                </div> : 
                                                <div>
                                                    <div className="text-secondary">Choose your banter</div>
                                                </div>                                           
                                            }
                                            
                                        </div>
                                        
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className={"border border-secondary " + theme.background} style={{maxHeight:250, overflow:'scroll'}}>
                                        {
                                            recentBanters.map((banter:bantter, index)=>{
                                                return <Dropdown.Item onClick={()=>changeBanter(banter)} className="pt-3 pb-2" key={index}>
                                                        <div>{banter.name}</div>
                                                        <div style={{whiteSpace:'break-spaces'}}>{banter.phrase}</div>                                                    
                                                    </Dropdown.Item>
                                            })
                                        }                                    
                                    </Dropdown.Menu>
                                </Dropdown>
                            </InputGroup>
                                                    
                            <div className="border border-0 mt-1">
                                {errors.banterId && !banter && <span className="text-danger">{errors.banterId.message}</span>}                            
                            </div>
                        </div>

                        {
                            contestTrophy &&
                            <div className="mb-4 lh-1">
                                <label htmlFor="trophyId" className="form-label">This contest requires {contestTrophy.amount?.toLocaleString()} coins to join, <Link to="#" onClick={()=>buyCoins()}>buy coins</Link></label> 
                                
                            </div>
                        }

                        <div className="d-grid gap-2 mt-3">
                            <button type="submit" className="btn btn-lg btn-primary" disabled={data.isBusy()}>
                                OK, Proceed<Spinner visibility={data.isBusy()} isCentered={false} />
                            </button>
                        </div>
                        {/* <div className="mt-4 fs-6 lh-1 text-center">
                            <div>
                                <span>All rewards are redeemable using <Link to="#" onClick={()=>{openCoins()}}>available options</Link>.</span>
                            </div>
                        </div> */}
                    </form>
                </Modal.Body>


            </Modal>

        </JoinContext.Provider>
    )
}