import { useTheme } from "../context/theme"

export default function Chain(props: any){

    const themeContext = useTheme()
    const theme = themeContext.theme

    let sectionCount = 1
    
    return(
        <div>
            {
                props.children &&
                props.children.map((item:any, index:number)=>{
                    return <div key={index}>
                        {
                            item.type === props.titleTag && <h5 className={"mt-5 mb-4 text-light " + theme.color + ' ' + item.props.className}>{item.props.children}</h5>
                        }
                        
                        {
                            item.type === props.sectionTag &&                             
                            <p className={"text-light " + theme.color }>
                                {sectionCount++}. {item.props.children}
                            </p>
                        }

                    </div>                    
                })
            }
        </div>
    )
}