import { useEffect } from "react"
import { useToast } from "./toast"

export default function usePaystack() {
    const src = 'https://js.paystack.co/v1/inline.js'
    const toast = useToast()
    useEffect(() => {
        const script = document.createElement('script')
        script.src = src
        script.async = true
        script.addEventListener('load', onScriptLoad)
        script.addEventListener('complete', onScriptError)
        script.addEventListener('error', () => () => toast.show('Failed to load paystack.', 'error'))
        document.body.appendChild(script)

        // Return cleanup
        return () => {
            script.removeEventListener('load', onScriptLoad)
            script.removeEventListener('error', onScriptError)
        }

    }, [src]) // eslint-disable-line react-hooks/exhaustive-deps

    const onScriptLoad = () => {

    }

    const onScriptError = () => {
    }

    return {
        init: () => { console.log('paystack loaded.') },
        pop: (advice: any, callback: Function, failback: Function) => {

            let paystackArgs = advice
            advice.amount = advice.amount * 100
            paystackArgs.callback = callback
            paystackArgs.onClose = failback

            //@ts-ignore
            const handler = window.PaystackPop && window.PaystackPop.setup(paystackArgs);
            handler && handler.openIframe();
        }
    }

}