import React, { useState, useContext } from 'react'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import { useTheme } from '../context/theme'

const init = { visible: false, message: '', hide: () => { }, show: (message: string, type: string = '') => { }, type: '' }
const ToastContext = React.createContext(init)

// Custom hook for Toaster
export const useToast = () => {
    return useContext(ToastContext)
}

const useToaster = () => {
    const [visible, setVisible] = useState(init.visible)
    const [message, setMessage] = useState('')
    const [type, setType] = useState('')

    init.visible = visible
    init.hide = () => setVisible(false)
    init.message = message
    init.type = type
    init.show = (message, type = '') => {
        switch (type) {
            case 'error':
                type = 'text-danger'
                break;
            case 'success':
                type = 'text-success'
                break;
            default:
        }
        setType(type)
        setMessage(message)
        setVisible(true)
    }
    return init;
}

export default function ToastProvider({ children }: any) {

    const themeContext = useTheme()
    const theme = themeContext.theme

    const toast = useToaster()

    return (
        <ToastContext.Provider value={toast}>
            {/* <div aria-live="polite" aria-atomic="true" className={"mt-4 pe-4 " + theme.background} style={{ zIndex: 100 }}> */}
                <ToastContainer position="top-center" className="mt-5">
                    <Toast show={toast.visible} onClose={toast.hide} autohide delay={7000}>
                        <Toast.Header className={toast.type + " border border-0 " + theme.background}>
                            <strong className="me-auto">Heads up</strong>
                        </Toast.Header>                        
                        <Toast.Body className={theme.background}>{toast.message}</Toast.Body>
                    </Toast>
                </ToastContainer>
            {/* </div> */}

            {children}

        </ToastContext.Provider>
    )
}