import { useEffect, useState } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import { Link, useHistory } from "react-router-dom"
import { useTheme } from "../context/theme"
import useData, { Spinner } from "../utils/data"
import { useToast } from "../utils/toast"
import Util from "../utils/util"

type changePassword = {
    currentPassword: string,
    newPassword:string
}

export default function ChangePassword(){

    const themeContext = useTheme()
    const theme = themeContext.theme

    const data = useData()
    const toast = useToast()
    const history = useHistory()
    const util = Util()
    const { register, handleSubmit, formState: { errors } } = useForm<changePassword>()
    const passwordRegexp = util.passwordRegex()
    const [passwordVisible, setPasswordVisible] = useState(false)

    const onSubmit: SubmitHandler<changePassword> = model => {

        data.post(process.env.REACT_APP_API_CHANGEPASSWORD, model, (resp: any) => {
            if(resp){
                if (resp.data) {
                    // Password change completed, redirect to login                    
                    history.push('/login')
                    toast.show(resp.message, 'success')                    
                }else if(resp.errors){
                    const key = Object.keys(resp.errors).filter((key, index) => index === 0)[0]
                    toast.show(resp.errors[key][0], 'error')                    
                }else if(resp.data === false && resp.message){
                    toast.show(resp.message, 'error')
                }
            }
        })
    }

    useEffect(()=>{
        document.title = process.env.REACT_APP_WEBSITE_TITLE + util.titleSeparator() + "Change Password"
    })

    return(
        <div className="mb-5">
            
            <h1 className={"mb-5 text-light " + theme.color}>Change password</h1>            
            <p className="mb-4">
                After this change, you will login using your new password.
            </p>
            
            <form className="pb-5" onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                    <label htmlFor="currentPassword" className="form-label">Current password</label>
                    <div className="input-group">
                        <input defaultValue="" type={passwordVisible ? 'text' : 'password'} {...register("currentPassword", {
                            required: 'Your current password is required',
                            pattern: { value: passwordRegexp, message: 'Password must contain at least one uppercase, lowercase, digit and special character' }
                        })} maxLength={150} className={"form-control form-control-lg border border-end-0 " + theme.background + ' ' + theme.color} id="currentPassword" placeholder="Your current password" />
                        <label className={"input-group-text border border-start-0 " + theme.background} htmlFor="password">
                            <Link to="#" onClick={()=>setPasswordVisible(!passwordVisible)}>{passwordVisible ? <i className={"bi bi-eye " + theme.color} aria-hidden="true" /> : <i className={"bi bi-eye-slash " + theme.color} aria-hidden="true" />}</Link>
                        </label>
                    </div>
                    
                    {errors.currentPassword && <small className="text-danger">{errors.currentPassword.message}</small>}
                </div>
                <div className="mb-3">
                    <label htmlFor="newPassword" className="form-label">New password</label>
                    <div className="input-group">
                        <input defaultValue="" type={passwordVisible ? 'text' : 'password'} {...register("newPassword", {
                            required: 'Choose a new password',
                            pattern: { value: passwordRegexp, message: 'Password must contain at least one uppercase, lowercase, digit and special character' }
                        })} maxLength={150} className={"form-control form-control-lg border border-end-0 " + theme.background + ' ' + theme.color} id="newPassword" placeholder="New password" />
                        <label className={"input-group-text border border-start-0 " + theme.background} htmlFor="newPassword">
                            <Link to="#" onClick={()=>setPasswordVisible(!passwordVisible)}>{passwordVisible ? <i className={"bi bi-eye " + theme.color} aria-hidden="true" /> : <i className={"bi bi-eye-slash " + theme.color} aria-hidden="true" />}</Link>
                        </label>
                    </div>
                    
                    {errors.newPassword && <small className="text-danger">{errors.newPassword.message}</small>}
                </div>
                
                <div className="d-grid gap-2 mt-5">
                    <button type="submit" className="btn btn-lg btn-primary" disabled={data.isBusy()}>
                        Change <Spinner visibility={data.isBusy()} isCentered={false} />
                    </button>
                </div>
            </form>


        </div>
    )
}