import DashNav from "../home/dashnav";
import jersery from "../assets/products/arsenalhjs.webp"
import swatch from "../assets/products/swatch2.webp"
import { Link } from "react-router-dom";
import useData, { Currency, Spinner } from "../utils/data";
import { useEffect, useState } from "react";
import AddAddress from "./addaddress";
import OrderSummary from "./ordersummary";
import Util from "../utils/util";
import { SubmitHandler, useForm } from "react-hook-form";

type product = {
    sku: string,
    name: string,
    amount: number,
    description: string,
    quantity: number
}

type category = {
    id: number,
    name: string
}

type Model = {
    query: string,
    categoryId: number
}

export default function Store() {
    const data = useData()
    const utils = Util();
    const [wallet] = useState<any>({ balance: 0 })
    const [addresses, setAddresses] = useState([])
    const [showAddress, setShowAddress] = useState(false)
    const [showOrderSummary, setShowOrderSummary] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState<product>()
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [products, setProducts] = useState<product[]>([])
    const [categories, setCategories] = useState<category[]>([])
    let [count, setCount] = useState(0)
    let [next, setNext] = useState(1)
    const pageSize = 1
    const [searching, setSearching] = useState(false)
    const { register, handleSubmit, watch, formState: { errors } } = useForm<Model>()
    const categoryId = watch("categoryId")
    const query = watch("query")

    const onSubmit: SubmitHandler<Model> = model => {
        // fetch products
        fetchProducts(0, pageSize, model.query, model.categoryId)
    }

    const handleOrderSummary = (index: number) => {
        setSelectedIndex(index)
        if (addresses.length === 0) {
            // prompt to add an address
            setShowAddress(true)
        } else {
            handleBuyNow(index)
        }
    }

    const handleBuyNow = (index: number) => {
        // set selected item
        setSelectedProduct(products[index])

        // prompt for purchase confirmation
        setShowOrderSummary(true)
    }

    const handleIncrement = (index: number) => {
        if (index > -1) {
            const product = products[index]
            product.quantity++
            if (product.quantity > 99) product.quantity = 99
            products[index] = product
            setProducts([...products])
        }
    }
    const handleDecrement = (index: number) => {
        if (index > -1) {
            const product = products[index]
            product.quantity--
            if (product.quantity < 1) product.quantity = 1
            products[index] = product
            setProducts([...products])
        }
    }

    const goNext = (step: number) => {
        let skip = next * pageSize
        setNext(next + step)
        if (skip >= count || skip < 0) {
            skip = 0
            setNext(1)
        }

        fetchProducts(skip, pageSize, query, categoryId)
        return skip
    }

    const fetchProducts = (skip: number, pageSize: number, query: string = '', categoryId: number = 0) => {
        setProducts([])
        setSearching(true)
        const urlBase = process.env.REACT_APP_API_PRODUCTS
        const url = urlBase + '?skip=' + skip + '&pageSize=' + pageSize + '&query=' + query + '&categoryid=' + categoryId
        data.get(url, (resp: any) => {
            console.log('resp', resp)
            if (resp && resp.data && resp.data.items) {
                setCount(resp.data.count)
                setProducts(resp.data.items)
            }

            setSearching(false)
        })
    }

    const fetchCategories = () => {
        data.get(process.env.REACT_APP_API_STORECATEGORIES, (resp: any) => {
            setCategories(resp.data)
        })
    }
    useEffect(() => {
        // fetch categories
        fetchCategories()

        // fetch products
        fetchProducts(0, pageSize, query, categoryId)

        // fetch user's wallet
        //auth.fetchWallet((data: any) => setWallet(data))
        // fetch user's addresses
        data.get(process.env.REACT_APP_API_USERADDRESSES, (resp: any) => {
            setAddresses(resp.data)
        })
        return () => {
            setCategories([])
            setProducts([])
            setAddresses([])
        }
    }, [categoryId]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <DashNav title="Fan store">
                <p>
                    Browse a catalouge of lovely products befitting true fans like you.
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                        <label htmlFor="query" className="form-label">What are you getting?</label>
                        <div className="input-group">
                            <input type="text" className="form-control" {...register("query", {
                                required: 'Search query is required'
                            })} id="query" maxLength={150} aria-describedby="searchHelp" placeholder="Search..." />
                            <button className="btn btn-outline-secondary" type="submit" id="button-addon2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                            </button>
                        </div>
                        {errors.query && <small className="text-danger">{errors.query.message}</small>}
                        <div id="searchHelp" className="form-text">Search by product name or tags. E.g <i>arsenal jersey</i> or <i>black samsung smartwatch</i>.</div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="categoryId" className="form-label">Category</label>
                        <select className="form-control" id="categoryId" {...register("categoryId")}>
                            <option value="0">- All categories -</option>
                            {
                                categories.map((category, index) => {
                                    return <option value={category.id} key={index} selected={category.id.toString() === categoryId?.toString()}>{category.name}</option>
                                })
                            }
                        </select>
                    </div>
                </form>
                {
                    searching && <div className="pt-5 text-center"><Spinner visibility={data.isBusy()} /></div>
                }

                {
                    products.length > 0 ?
                        <div className="row">
                            <div className="col-6">
                                <nav className="nav" aria-label="Total number of products">
                                    <Link className="nav-link active text-dark" to="#">
                                        {count > 1 ? count + ' Items' : count + ' Item'}
                                    </Link>
                                </nav>
                            </div>
                            <div className={count === pageSize ? 'col-6 d-none' : 'col-6'}>
                                <nav aria-label="Products navigation">
                                    <ul className="pagination justify-content-end">
                                        <li className="page-item"><Link to="#" className="page-link" onClick={() => goNext(-1)}>Back</Link></li>
                                        <li className="page-item"><Link to="#" className="page-link" onClick={() => goNext(1)}>Next</Link></li>
                                    </ul>
                                </nav>
                            </div>
                        </div> :
                        !searching && <div className="row mb-3">
                            <div className="col-12 p-5 text-center form-text">
                                <p>No results found.</p>
                                <p>Maybe change your search query or look in a different category.</p>
                            </div>
                        </div>
                }


                {
                    products.map((item, index) => {
                        return (
                            <div className="row mb-3" key={index}>
                                <div className="col-12">
                                    <div className="card text-end">
                                        <img src={item.sku === 'F002' ? swatch : jersery} className="card-img-top" alt={item.name} loading="lazy" />
                                        <div className="card-body">
                                            <h5 className="card-title">{item.name}</h5>
                                            <h6 className="card-subtitle mb-2 text-muted">{item.description}</h6>
                                            <p className="card-text">
                                                <Currency symbol={wallet?.currency?.html} />{utils.money(item.amount)}
                                            </p>
                                            <nav aria-label="Products navigation">
                                                <ul className="pagination justify-content-end">
                                                    <li className="page-item"><Link to="#" className="page-link" onClick={() => handleDecrement(index)}>-</Link></li>
                                                    <li className="page-item"><Link to="#" className="page-link text-dark">{item.quantity}</Link></li>
                                                    <li className="page-item"><Link to="#" className="page-link" onClick={() => handleIncrement(index)}>+</Link></li>
                                                </ul>
                                            </nav>
                                            <Link to="#" className="btn btn-dark" onClick={() => handleOrderSummary(index)}>Buy Now</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                {
                    products.length > 0 ?
                        <nav className={count <= pageSize ? 'd-none' : ''} aria-label="Products navigation">
                            <ul className="pagination justify-content-center">
                                <li className="page-item"><Link to="#" className="page-link" onClick={() => goNext(-1)}>Back</Link></li>
                                <li className="page-item"><Link to="#" className="page-link" onClick={() => goNext(1)}>Next</Link></li>
                            </ul>
                        </nav> : <></>
                }

            </DashNav>

            <AddAddress handleBuyNow={handleBuyNow} selectedIndex={selectedIndex} setAddresses={setAddresses} showAddress={showAddress} setShowAddress={setShowAddress} setShowOrderSummary={setShowOrderSummary} />

            <OrderSummary wallet={wallet} selectedProduct={selectedProduct} addresses={addresses} showOrderSummary={showOrderSummary} setShowAddress={setShowAddress} setShowOrderSummary={setShowOrderSummary} />
        </>
    )
}