import { useTheme } from "../context/theme";
import DashNav from "./dashnav";
import { useAuth } from "../context/auth";
import { useState } from "react";
import { team } from "../utils";
import { Link, useHistory } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import Util from "../utils/util";
import useData, { Spinner } from "../utils/data";
import { useToast } from "../utils/toast";
import { CloseButton, Form, Modal } from "react-bootstrap";

type Model = {
    firstname: string,
    surname: string,
    mobile: string,
    email: string,
    instagram: string,
    twitter: string
}

export default function Profile(){

    const themeContext = useTheme()
    const theme = themeContext.theme
    const data = useData()
    const toast = useToast()
    const history = useHistory()
    const util = Util()
    let title = ""
    const auth = useAuth()
    const authContext = auth.context
    let user = authContext.getUser()
    //console.log('authContext', authContext)

    const [favTeam] = useState<team | undefined>(authContext.favTeam)
    const { register, handleSubmit, formState: { errors } } = useForm<Model>()
    const emailPattern = util.emailRegex()
    const dashboard = process.env.REACT_APP_DASHBOARD_PATH ? process.env.REACT_APP_DASHBOARD_PATH : "#"
    const [showResendButton, setShowResendButton] = useState(false)
    const [isEmailCorrect, setIsEmailCorrect] = useState(false)
    const mobilePattern = /^[0-9]{11,11}$/i

    const onSubmit: SubmitHandler<Model> = model => {

        data.post(process.env.REACT_APP_API_UPDATEPROFILE, model, (resp: any) => {

            if (resp.data?.id > 0) {
                // Redirect to dashboard
                if(resp.message){
                    toast.show(resp.message, 'success')
                    // Log out
                    authContext.logout()
                }

                let isEmailSame = true
                if(model.email) isEmailSame = user.username === model.email
                history.push(dashboard, [{ isEmailVerified: isEmailSame}])
            } else if (resp.errors) {
                const key = Object.keys(resp.errors).filter((key, index) => index === 0)[0]
                toast.show(resp.errors[key], 'error')
            }

        }, (result: any) => {
            if (result) {
                toast.show(result.message)
            }
        })
        
    }

    const showResendModal = (isEmailVerified: boolean)=>{

        if(isEmailVerified) {
            return false
        }
        
        setShowResendButton(true)
    }

    const closeModal = () => {
        setShowResendButton(false)
        setIsEmailCorrect(false)
    }

    const showProfileForm = ()=>{
        closeModal()
        window.scrollTo(0, 300)
    }

    const resendVerification = ()=>{

        data.post(process.env.REACT_APP_API_RESENDVERIFICATION, {}, (resp: any) => {

            if(resp.data){

                // hide the resend button
                setShowResendButton(false)
                closeModal()
                // Log out
                authContext.logout()
                history.push(dashboard, [{ isEmailVerified: false}])
            }
        })
    }

    return (
        <div>
            <DashNav title={title}>
                <h1 className={"mb-5 text-light " + theme.color}>
                    My profile
                </h1>
                {
                    user && 
                    <div className="card p-3">
                        <div className="card-body">
                            <div className={"h5 card-title mb-3 text-light " + theme.color}>
                                <span>{user.profile?.firstName}</span> <span>{user.profile?.surname}</span>
                            </div>
                            <div className="h5 card-subtitle mb-3 text-muted">Name</div>
                            <div className={"h5 card-title mb-3 text-light " + theme.color}>
                                <span>{user.username}</span>
                            </div>
                            <div className="h5 card-subtitle mb-3 text-muted">
                                Email <Link onClick={() => showResendModal(user.profile.isEmailVerified)} className={"text-light badge " + (user.profile.isEmailVerified ? 'bg-success' : 'bg-danger') + " rounded-pill"} to="#">{user.profile.isEmailVerified ? 'verified' : 'not verified'}</Link>
                            </div>

                            {
                                user.profile?.mobile &&
                                <div>
                                    <div className={"h5 mb-3 card-title text-light " + theme.color}>
                                        { user.profile?.mobile ? user.profile?.mobile : <>&nbsp;</> }
                                    </div>
                                    <div className="h5 card-subtitle mb-3 text-muted">
                                        <i className="bi bi-phone"></i> Mobile
                                    </div> 
                                </div>
                            }

                            {
                                user?.profile?.isEmailVerified &&
                                <>
                                    <div className={"h5 card-title mb-3 text-light " + theme.color}>
                                    <span className="fs-6">
                                        {
                                            "https://"+ process.env.REACT_APP_URL + '/signup/'  + user?.profile?.referralCode
                                        } 
                                        </span>
                                    </div>
                                    <div className="h5 card-subtitle mb-3 text-muted">Referral link</div>
                                </>
                            }                       
                            
                            {
                                favTeam && favTeam.logo !== undefined && 
                                <div className="d-none">
                                    <div className={"h5 card-title mb-3 text-light " + theme.color}>
                                        <div className="d-flex">
                                            <div className="me-2" style={{cursor: 'pointer', width:24, height:24, backgroundSize:'cover', backgroundRepeat: 'no-repeat', backgroundPosition:'center', backgroundImage: `url(${favTeam.logo})`}}></div> <i className="d-none bi bi-heart-fill ms-2 me-2"></i> {favTeam.name}
                                        </div>
                                    </div>
                                    <div className="h6 card-subtitle mb-3 text-muted">You can change your favourite team at anytime.</div>
                                </div>                                
                            }

                            {
                                (user.profile?.instagram || user.profile?.twitter) &&
                                <div className="row">
                                    <div className="col-12">
                                        <h6 className={"h5 card-title mb-3 mt-4 text-light " + theme.color}>Social Media</h6>
                                        <div className="h5 card-subtitle mt-3 mb-3 text-muted">We can reach you quickly on social media.</div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className={"h5 mb-3 card-title text-light " + theme.color}>
                                            { user.profile?.instagram ? user.profile?.instagram : <>&nbsp;</> }
                                        </div>
                                        <div className="h5 card-subtitle mb-3 text-muted">
                                            <i className="bi bi-instagram"></i> Instagram
                                        </div>                                    
                                    </div>
                                    <div className="col-sm-6">
                                        <div className={"h5 mb-3 card-title text-light " + theme.color}>
                                            { user.profile?.twitter ? user.profile?.twitter : <>&nbsp;</> }
                                        </div>
                                        <div className="h5 card-subtitle mb-3 text-muted">
                                            <i className="bi bi-twitter"></i> Twitter
                                        </div> 
                                    </div>
                                    
                                </div>
                            }
                            
                        </div>
                    </div>
                }
                
                <form className="pt-4 pb-5" onSubmit={handleSubmit(onSubmit)}>

                    <div className="mb-4">
                        Update your profile and social media details.
                    </div>

                    <div className="mb-3">
                        <label htmlFor="instagram" className="form-label"><i className="bi bi-instagram"></i> Instagram</label>
                        <input autoFocus={user?.profile?.isEmailVerified} defaultValue={user?.profile?.instagram} type="text" {...register("instagram", {
                            pattern: { value: /^(@)(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,30}$/igm, message : 'Your @username'}
                        })} maxLength={50} className={"form-control form-control-lg " + theme.background + ' ' + theme.color} id="instagram" placeholder="instagram @username" />
                        {errors.instagram && <small className="text-danger">{errors.instagram.message}</small>}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label"><i className="bi bi-twitter"></i> Twitter</label>
                        <input defaultValue={user?.profile?.twitter} type="text" {...register("twitter", {
                            pattern: { value: /^(@)[A-Za-z0-9_]{1,16}$/igm, message : 'Your @username'}
                        })} maxLength={50} className={"form-control form-control-lg " + theme.background + ' ' + theme.color} id="twitter" placeholder="twitter @username" />
                        {errors.twitter && <small className="text-danger">{errors.twitter.message}</small>}
                    </div>
                    {
                        user?.profile && !user?.profile?.isEmailVerified &&
                        <div className="mb-3 lh-1">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input defaultValue={user?.username} type="email" {...register("email", {
                                required: 'Email is required',
                                pattern: { value: emailPattern, message: 'Enter a valid email address' }
                            })} maxLength={150} className={"form-control form-control-lg " + theme.background + ' ' + theme.color} id="email" placeholder="Enter your email" />
                            {errors.email && <small className="text-danger">{errors.email.message}</small>}
                            <div id="email" className="form-text">
                                <small>
                                    If you change your email and save changes, open your email and click the new verification link you receive.
                                </small>                            
                            </div>
                        </div>
                    }
                    <div className="mb-3">
                        <label htmlFor="firstname" className="form-label">Firstname</label>
                        <input defaultValue={user?.profile?.firstName} type="text" {...register("firstname", {
                            required: 'Firstname is required',
                        })} maxLength={50} className={"form-control form-control-lg " + theme.background + ' ' + theme.color} id="firstname" placeholder="Enter firstname" />
                        {errors.firstname && <small className="text-danger">{errors.firstname.message}</small>}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="surname" className="form-label">Surname</label>
                        <input defaultValue={user?.profile?.surname} type="text" {...register("surname", {
                            required: 'Surname is required'
                        })} maxLength={50} className={"form-control form-control-lg " + theme.background + ' ' + theme.color} id="surname" placeholder="Enter surname" />
                        {errors.surname && <small className="text-danger">{errors.surname.message}</small>}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="mobile" className="form-label"><i className="bi bi-whatsapp"></i> Mobile</label>
                        <input defaultValue={user?.profile?.mobile} type="text" {...register("mobile", {
                            required: 'WhatsApp number is required',
                            pattern: { value: mobilePattern, message: 'Enter a valid mobile number' }
                        })} maxLength={11} className={"form-control form-control-lg " + theme.background + ' ' + theme.color} id="mobile" placeholder="Your WhatsApp number" />
                        {errors.mobile && <small className="text-danger">{errors.mobile.message}</small>}
                    </div>
                    
                    <div className="d-grid gap-2 mb-5">
                        <p><small>Login required after saving changes.</small></p>
                        <button type="submit" className="btn btn-lg btn-primary" disabled={data.isBusy()}>
                            Save changes <Spinner visibility={data.isBusy()} isCentered={false} />
                        </button>
                    </div>
                    
                </form>

                <Modal show={showResendButton} onHide={() => showProfileForm()} contentClassName={theme.background} className="p-2" centered>
                    <Modal.Header>
                        <CloseButton className={theme.name === 'dark' ? 'btn-close-white' : ''} onClick={()=>showProfileForm()} />
                    </Modal.Header>
                    <Modal.Body className="p-5 pt-0">
                        A verification link was previously sent to <span className="text-danger">{user?.username}.</span> If this email is not reachable, <Link to="#" onClick={()=>showProfileForm()}>update your profile</Link> with a valid email and save changes.

                        <div className="d-grid gap-2 mb-2 mt-3 lh-1">
                            <p>Check this box if you confirm that your email is correctly spelled.</p>
                            <Form.Check type="checkbox">
                                <Form.Check.Input type="checkbox" onChange={()=>setIsEmailCorrect(!isEmailCorrect)} checked={isEmailCorrect}/>
                                <Form.Check.Label>
                                    I confirm that my email is correctly spelled.
                                </Form.Check.Label>
                            </Form.Check>

                            {
                                isEmailCorrect && 
                                <button type="button" onClick={()=>resendVerification()} className="btn btn-lg btn-primary mt-3" disabled={data.isBusy()}>
                                    Resend verification <Spinner visibility={data.isBusy()} isCentered={false} />
                                </button>
                            }                            
                            
                        </div>
                    </Modal.Body>
                </Modal>
                
            </DashNav>
        </div>
    )
}