import { useState, createContext, useContext, useEffect } from "react"
import { ButtonGroup, CloseButton, Dropdown, InputGroup, Modal } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import { useStartBanter } from "../banter/startbanter2"
import { useAuth } from "../context/auth"
import { useTheme } from "../context/theme"
import { bantter, clique, response, trophy } from "../utils"
import useData, { Spinner } from "../utils/data"
import { usePopup } from "../utils/popup"
import Util from "../utils/util"
import { useRedeem } from "../wallet/redeem"

type Model = {
    cliqueId: number,
    banterId: number|null,
    fixtureId: number,
    trophyId: number|null,
    trophy?:trophy|null,
}

type init = {
    visible?: boolean,
    hide: () => void,
    show: Function,
    registeredCallback?: Function|null,
}

const initContest: init = {
    visible: false,
    show: ()=> {},
    hide: ()=> {},
}

let StartContestContext = createContext(initContest)

export const useStartContest = ()=>{
    return useContext(StartContestContext)
}

export default function StartContest() {

    const contestPopup = useStartContest()
    const auth = useAuth()
    const authContext = auth.context
    const user = authContext.user
    const themeContext = useTheme()
    const theme = themeContext.theme
    const data = useData()    
    const [visible, setVisible] = useState(false)
    const [isFetchingCliques, setIsFetchingCliques] = useState(false)
    const [isSubscribed, setIsSubscribed] = useState(true)
    const util = Util()
    const popup = usePopup()
    const banterPopup = useStartBanter()
    const redeemPopup = useRedeem()

    contestPopup.show = (callback?:Function)=>{

        // register a callback if any
        if(callback){            
            contestPopup.registeredCallback = callback
        }
        
        setVisible(true)
    }

    contestPopup.hide = ()=>{
        setVisible(false)
    }

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<Model>()
    const [cliques, setCliques] = useState<clique[]>([])
    const [clique, setClique] = useState<clique>()
    const [recentBanters, setRecentBanters] = useState([])
    const [banter, setBanter]= useState<bantter|null>()
    const [trophies, setTrophies] = useState<trophy[]>([])
    const [selectedTrophy, setSelectedTrophy] = useState<trophy|null>()
    const [someTrophies, setSomeTrophies] = useState<trophy[]>([])

    const onSubmit: SubmitHandler<Model> = model => {
        
        if(!model){
            return
        }

        model.trophy = selectedTrophy
        
        let url = util.composeUrl(process.env.REACT_APP_API_CLIQUECONTESTS, [model.cliqueId])
        data.post(url, model, (resp:response<number>)=>{
            
            if(resp){
                if(resp.message){
                    popup.show(resp.message)
                }
                
                if(resp.data && resp.data > 0){
                    popup.show('Your contest is now active. Encourage friends in your clique to join.')
                    contestPopup.hide()
                    setBanter(null)
                    setSelectedTrophy(null)
                    setValue("banterId", null)
                    setValue("trophyId", null)
                    
                    if(model.trophy){
                        // update user wallet                    
                        auth.updateWallet(model.trophy.amount, user)

                        // execute callback if any
                        if(contestPopup.registeredCallback){
                            contestPopup.registeredCallback()
                        }
                    }
                }
            }

        }, ()=>{})
        
    }

    const changeBanter = (banter:bantter)=>{
        if(banter){
            setBanter(banter)
            setValue("banterId", banter.id)
            setValue("fixtureId", banter.fixtureId)
        }
    }

    const changeClique = (clique:clique)=>{
        if(clique){
            setClique(clique)
            setValue("cliqueId", clique.id);
        }        
    }

    const changeTrophy = (trophy:trophy)=>{
        if(trophy){
            setSelectedTrophy(trophy)
            setValue("trophyId", trophy.id)
        }
    }

    const fetchRecentBanters = (skip: number, pageSize: number) => {
        const url = process.env.REACT_APP_API_RECENTBANTERS + '?skip=' + skip + '&pageSize=' + pageSize
        data.get(url, (resp: response<any>) => {
            if (resp && resp.data && resp.data.items) {
                let banters = resp.data.items
                setRecentBanters(banters)
            }
        })
    }

    const fetchTrophies = (countryCode: string) => {

        if(!countryCode){
            return;
        }

        const url = process.env.REACT_APP_API_TROPHIES + '?countrycode=' + countryCode
        data.get(url, (resp: response<trophy[]>) => {
            let trophies = resp?.data
            if(trophies && isSubscribed){
                setTrophies(trophies)
                for(let i = 0; i < 2; i++){
                    if(trophies[i]){
                        someTrophies[i] = trophies[i]
                    }                    
                }

                setSomeTrophies(someTrophies)                
            }
        })
    }

    const fetchCliques = () => {
        setIsFetchingCliques(true)
        data.get(process.env.REACT_APP_API_CLIQUES, (resp: response<clique[]>) => {
            if(isSubscribed){
                setIsFetchingCliques(false)
                if (resp && resp.data) {                    
                    const results = resp.data
                    setCliques(results)
                    if(results.length === 1){
                        const firstClique = results[0]                        
                        changeClique(firstClique)
                    }
                }
            }
            
        }, () => setIsFetchingCliques(false))
    }

    const startBanter = ()=>{
        contestPopup.hide()
        banterPopup.startNewBanter()
    }

    const openCoins = ()=>{
        redeemPopup.show()
    }

    useEffect(()=>{

        //console.log('isSubscribed before fetchRecentBanters', isSubscribed)

        fetchRecentBanters(0, 3)
        
    }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(()=>{

    //     if(cliques.length === 0) {
    //         fetchCliques()
    //     }

    //     return () => setIsSubscribed(false)
    // }, [cliques]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{

        // fetch trophies of country
        if(trophies.length === 0){
            
            let countryCode = user?.profile?.countryCode
            if(countryCode){
                fetchTrophies(countryCode)
            }
        }

        if(cliques.length === 0) {
            fetchCliques()
        }

        return () => setIsSubscribed(false)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <StartContestContext.Provider value={contestPopup}>
            
            <Modal show={visible} onHide={contestPopup.hide} contentClassName={"border border-secondary " + theme.background} className="p-2 pb-0" centered>
                <Modal.Header>
                    {/* <Modal.Title className="p-3 lh-1 fs-3">Start a contest</Modal.Title> */}
                    <CloseButton className={theme.name === 'dark' ? 'btn-close-white' : ''} onClick={contestPopup.hide} />
                </Modal.Header>
                <Modal.Body className="ps-5 pe-5 pt-1 pb-5">
                    <div className="lh-1 mb-4">
                        Start a contest with friends, it's fun.
                    </div>
                    {
                        isFetchingCliques &&
                        <div className="text-center">
                            <Spinner visibility={data.isBusy()} />
                        </div>
                    }
                    {
                        recentBanters.length === 0 &&
                        <div className="lh-1 mt-5 mb-5">
                            <p>No recent banters to start a contest, <Link to="#" onClick={()=>startBanter()}>start a banter</Link></p>
                        </div>
                    }
                    <form onSubmit={handleSubmit(onSubmit)} className={recentBanters.length === 0 ? 'd-none' : ''}>
                    <div className="mb-4 lh-1">
                            <label htmlFor="banterId" className="form-label">Clique</label>
                            <InputGroup size="lg">
                                <Dropdown as={ButtonGroup} {...register("cliqueId", { required : "Clique is required" })}>
                                    <Dropdown.Toggle className={theme.background + " border border-secondary text-start d-flex align-items-center"} style={{width:'100%'}}>
                                        <div className={"flex-fill text-secondary " + theme.color }>
                                            <div className="text-secondary">{ clique?.name ?? 'Choose a clique' }</div>
                                        </div>                                        
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className={"border border-secondary " + theme.background} style={{maxHeight:250, overflow:'scroll'}}>
                                        {
                                            cliques.map((clique:clique, index)=>{
                                                return <Dropdown.Item onClick={()=>changeClique(clique)} className="pt-3 pb-2" key={index}>
                                                        <div>{clique.name}</div>                                                  
                                                    </Dropdown.Item>
                                            })
                                        }                                    
                                    </Dropdown.Menu>
                                </Dropdown>
                            </InputGroup>
                                                    
                            <div className="border border-0 mt-1">
                                {errors.cliqueId && !clique && <span className="text-danger">{errors.cliqueId.message}</span>}                            
                            </div>
                        </div>
                        <div className="mb-4 lh-1">
                            <label htmlFor="banterId" className="form-label">Banter</label>
                            <InputGroup size="lg">
                                <Dropdown as={ButtonGroup} {...register("banterId", { required : "Banter is required" })}>
                                    <Dropdown.Toggle className={theme.background + " border border-secondary text-start d-flex align-items-center"} style={{width:'100%'}}>
                                        
                                        <div className={"flex-fill text-secondary " + theme.color }>
                                            {
                                                banter ?
                                                <div>
                                                    <div>
                                                        <img loading="lazy" className="rounded-circle" style={{ width: 20, height:20 }} src={banter.fixture?.homeTeam.logo} alt="" /> {banter?.fixture?.homeTeam.name}
                                                    </div>   
                                                    <div>
                                                        <img loading="lazy" className="rounded-circle" style={{ width: 20, height:20 }} src={banter.fixture?.awayTeam.logo} alt="" /> {banter?.fixture?.awayTeam.name}
                                                    </div>
                                                    <div className={"lh-1 fs-5 pt-2 " + theme.color} style={{whiteSpace:'break-spaces'}}>{banter?.phrase}.</div>                                             
                                                </div> : 
                                                <div>
                                                    <div className="text-secondary">Choose a banter</div>
                                                </div>                                           
                                            }
                                            
                                        </div>
                                        
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className={"border border-secondary " + theme.background} style={{maxHeight:250, overflow:'scroll'}}>
                                        {
                                            recentBanters.map((banter:bantter, index)=>{
                                                return <Dropdown.Item onClick={()=>changeBanter(banter)} className="pt-3 pb-2" key={index}>
                                                        <div>{banter.name}</div>
                                                        <div style={{whiteSpace:'break-spaces'}}>{banter.phrase}</div>                                                    
                                                    </Dropdown.Item>
                                            })
                                        }                                    
                                    </Dropdown.Menu>
                                </Dropdown>
                            </InputGroup>
                                                    
                            <div className="border border-0 mt-1">
                                {errors.banterId && !banter && <span className="text-danger">{errors.banterId.message}</span>}                            
                            </div>
                            

                        </div>

                        <div className="mb-4 lh-1">
                            <label htmlFor="trophyId" className="form-label">Prize reward</label>
                            <InputGroup size="lg">
                                <Dropdown as={ButtonGroup} {...register("trophyId", { required : "Reward is required" })}>
                                    <Dropdown.Toggle className={theme.background + " border border-secondary text-start d-flex align-items-center"} style={{width:'100%'}}>
                                        <div className={"flex-fill text-secondary " + theme.color }>
                                            {
                                                selectedTrophy ?
                                                <div className="fs-3 lh-1 pt-2 pb-2">
                                                    <div className="d-flex">
                                                        <i className={"fs-1 bi me-2 " + selectedTrophy.icon}></i>
                                                        <div className="fs-5" style={{maxWidth:180}}>
                                                            {selectedTrophy.name} <div><span className="fs-6"> {selectedTrophy.amount.toLocaleString()} coins <i className="bi bi-coin fs-6"></i> </span></div>
                                                        </div>
                                                    </div>                                                    
                                                </div> : 
                                                <div>
                                                    <div className="text-secondary">Set a reward</div>
                                                </div>
                                            }
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className={"border border-secondary " + theme.background} style={{maxHeight:250, overflow:'scroll'}}>
                                    {
                                        trophies.map((trophy, index)=>{
                                            return <Dropdown.Item key={index} onClick={()=>changeTrophy(trophy)}>
                                                    <div className={"fs-3 lh-1 pt-3 pb-2 " + (trophy.id === selectedTrophy?.id ? "fw-bold" : '')}>
                                                        <div className="d-flex">
                                                            <i className={"fs-1 bi me-2 " + trophy.icon}></i>
                                                            <div className="fs-5" style={{maxWidth:180}}>
                                                                {trophy.name} <div><span className="fs-6"> {trophy.amount.toLocaleString()} <i className="bi bi-coin fs-6"></i> </span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </Dropdown.Item>
                                        })
                                    }                                    
                                    </Dropdown.Menu>
                                </Dropdown>
                            </InputGroup>

                            <div className="border border-0 mt-1">
                                {errors.trophyId && !selectedTrophy && <span className="text-danger">{errors.trophyId.message}</span>}
                            </div>
                        </div>                       
                        
                        {/* {
                            selectedTrophy && banter &&
                            <div className="lh-1 fs-5" style={{minHeight:50}}>
                                <div className={"form-text " + theme.color}>                            
                                    Starting a contest in your clique, {clique?.name}. { cliques.length > 1 && <Dropdown as={ButtonGroup} drop="up-centered" className="noarrow" title={clique?.name + " clique" } variant="transparent">
                                            <Dropdown.Toggle variant={"btn border-0 ps-1 pe-1 pb-2 pt-1 text-light " + theme.color}>Change clique</Dropdown.Toggle>
                                            <Dropdown.Menu className={"border border-secondary " + theme.background}>
                                                {cliques.map((clique, index)=>{
                                                    return <Dropdown.Item key={index} className="pt-2 pb-2" onClick={()=>changeClique(clique)}>
                                                        {clique.name}
                                                    </Dropdown.Item>
                                                })}                                            
                                            </Dropdown.Menu>
                                        </Dropdown>}
                                </div>
                            </div>
                        } */}
                       
                        <div className="d-grid gap-2 mt-3">
                            <button type="submit" className="btn btn-lg btn-primary" disabled={data.isBusy()}>
                                OK, Proceed <Spinner visibility={data.isBusy()} isCentered={false} />
                            </button>
                        </div>
                        <div className="mt-4 fs-6 lh-1 text-center">
                            <div>
                                <span>All rewards are redeemable using <Link to="#" onClick={()=>{openCoins()}}>available options</Link>.</span>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

        </StartContestContext.Provider>
    )
    
}