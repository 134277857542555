import CrownLogo from '../assets/crown.png'
import DotImage from '../assets/1pxdot.png'
import { useAuth } from '../context/auth'
import { Spinner } from '../utils/data'
import { LeaderBoardCode } from '../utils/enum'

export default function HeroesBoard(params: any){

    const auth = useAuth()
    const authContext = auth.context

    const showCrown = params.showCrown
    const isBusy = params.isBusy
    const leaderBoardCode = params.leaderBoardCode

    const toppers = params.toppers ?? []
    const firstTopper = { position: 1, ...toppers[0]}
    const secondTopper = { position: 2, ...toppers[1]}
    const thirdTopper = { position: 3, ...toppers[2]}
    const fourthTopper = { position: 4, ...toppers[3]}
    const fifthTopper = { position: 5, ...toppers[4]}

    let avatarBase = process.env.REACT_APP_API_AVATARBASEURL
    const backgroundColor = '7D36D2'

    let firstTopperAvatar = firstTopper.avatarFile ? avatarBase + authContext.appendSex(firstTopper.sex) + firstTopper.avatarFile + '.svg?background=%23CF268A&mouth=variant05' : ''
    let secondTopperAvatar = secondTopper.avatarFile ? avatarBase + authContext.appendSex(secondTopper.sex) + secondTopper.avatarFile + '.svg?background=%23' + backgroundColor + '&mouth=variant05' : ''
    let thirdTopperAvatar = thirdTopper.avatarFile ? avatarBase + authContext.appendSex(thirdTopper.sex) + thirdTopper.avatarFile + '.svg?background=%23' + backgroundColor + '&mouth=variant05' : ''
    let fourthTopperAvatar = fourthTopper.avatarFile ? avatarBase + authContext.appendSex(fourthTopper.sex) + fourthTopper.avatarFile  + '.svg?background=%23' + backgroundColor + '&mouth=variant05' : ''
    let fifthTopperAvatar = fifthTopper.avatarFile ? avatarBase + authContext.appendSex(fifthTopper.sex) + fifthTopper.avatarFile  + '.svg?background=%23' + backgroundColor + '&mouth=variant05' : ''

    return(
        <div>            

            {
                <div className="card d-flex align-items-center justify-content-center" style={{minHeight:234}}>

                    {/* <Spinner visibility={isBusy}></Spinner> */}

                    {
                        toppers.length === 0 && 
                        <div className="p-5 text-center lh-1">
                            
                            <Spinner visibility={isBusy}></Spinner>

                            {
                                leaderBoardCode === LeaderBoardCode.Today && isBusy === false &&
                                <>Rankings for this week will show up here as they become available.</>
                            }
                            {
                                leaderBoardCode === LeaderBoardCode.LastWeek && isBusy === false &&
                                <>No rankings for last week.</>
                            }                            
                        </div>
                    }

                    {
                        toppers.length > 0 &&
                        <div className="card-body text-center pt-4 pb-4 mt-3 d-non">
                        
                            <div className="d-flex justify-content-center">
                            {
                                    showCrown && firstTopperAvatar &&
                                    <div><img  alt="heroes crown" style={{marginBottom:-3}} src={CrownLogo}  /></div>
                                }
                                    
                            </div>
                            <div className="d-flex justify-content-between">

                                { // Fourth position 
                                }

                                <div className="mt-5 p-2" style={{width:'20%'}}>
                                    
                                    <div className="row">
                                        <div className="col-12">
                                            { 
                                                fourthTopperAvatar ? 
                                                <img src={fourthTopperAvatar} loading="lazy" className="border border-primary rounded-circle" alt="hero 4" style={{width:'100%'}} /> :
                                                <img src={DotImage} alt="" loading="lazy" style={{width:'100%'}} />
                                            }
                                        </div>
                                        <div className="col-12" style={{marginTop: -10}}>
                                            <span className="badge border border-2 border-dark leagueposition rounded-circle fs-6 p-1" style={{width:'28px', height:'28px'}}>4</span>
                                        </div>
                                        <div className="col-12 fs-6 ellipsis d-none">
                                            {fourthTopper.firstName}
                                        </div>
                                    </div>

                                </div>

                                { // Second position 
                                }

                                <div className="mt-4 p-1" style={{width:'20%'}}>

                                    <div className="row">                            
                                        <div className="col-12">
                                            {
                                                secondTopperAvatar ? 
                                                <img src={secondTopperAvatar} loading="lazy" className="border border-primary rounded-circle" alt="hero 2" style={{width:'100%'}} /> :
                                                <img src={DotImage} alt="" loading="lazy" style={{width:'100%'}} />
                                            }
                                        </div>
                                        <div className="col-12" style={{marginTop: -10}}>
                                            <span className="badge border border-2 border-dark leagueposition rounded-circle fs-6 p-1" style={{width:'28px', height:'28px'}}>2</span>
                                        </div>
                                        <div className="col-12 fs-6 ellipsis d-none">
                                            {secondTopper.firstName}
                                        </div>
                                    </div>

                                </div>

                                { // Crowned player
                                }

                                {

                                    <div style={{width:'20%'}}>

                                        <div className="row">                            
                                            <div className="col-12">
                                                {
                                                    firstTopperAvatar ?
                                                    <img src={firstTopperAvatar} loading="lazy" className={"rounded-circle " + (firstTopperAvatar? 'border border-3 border-warning' : '') } alt="&nbsp;" style={{width:'100%'}} /> :
                                                    <img src={DotImage} alt="" loading="lazy" style={{width:'100%'}} />
                                                }
                                            </div>
                                            <div className="col-12" style={{marginTop: -10}}>
                                                <span className="badge border border-dark leagueposition1 rounded-circle fs-6 p-1" style={{width:'28px', height:'28px'}}>1</span>
                                            </div>
                                            <div className="col-12 fs-6 ellipsis d-none">
                                                {firstTopper.firstName}
                                            </div>
                                        </div>
                                        
                                    </div>
                                }

                                { // Third position 
                                }

                                <div className="mt-4 p-1" style={{width:'20%'}}>

                                    <div className="row">                            
                                        <div className="col-12">
                                            {
                                                thirdTopperAvatar ?
                                                <img src={thirdTopperAvatar} loading="lazy" className="border border-primary rounded-circle" alt="hero 3" style={{width:'100%'}} /> :
                                                <img src={DotImage} alt="" loading="lazy" style={{width:'100%'}} />
                                            }                            
                                        </div>
                                        <div className="col-12" style={{marginTop: -10}}>
                                            <span className="badge border border-2 border-dark leagueposition rounded-circle fs-6 p-1" style={{width:'28px', height:'28px'}}>3</span>
                                        </div>
                                        <div className="col-12 fs-6 ellipsis d-none">
                                            {thirdTopper.firstName}
                                        </div>
                                    </div>

                                </div>

                                { // Fifth position 
                                }

                                <div className="mt-5 p-2" style={{width:'20%'}}>

                                    <div className="row">                            
                                        <div className="col-12">
                                            {
                                                fifthTopperAvatar ?
                                                <img src={fifthTopperAvatar} loading="lazy" className="border border-primary rounded-circle" alt="hero 5" style={{width:'100%'}} /> : 
                                                <img src={DotImage} alt="" loading="lazy" style={{width:'100%'}} />
                                            }                            
                                        </div>
                                        <div className="col-12" style={{marginTop: -10}}>
                                            <span className="badge border border-2 border-dark leagueposition rounded-circle fs-6 p-1" style={{width:'28px', height:'28px'}}>5</span>
                                        </div>
                                        <div className="col-12 fs-6 ellipsis d-none">
                                            {fifthTopper.firstName}
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {
                                leaderBoardCode === LeaderBoardCode.Today && isBusy === false &&
                                <div className="text-center fs-6"><i className="bi bi-trophy-fill text-warning"></i> Trophy from 30 up to 1,050 coins</div>
                            }

                        </div> 
                        
                    }
                            
                </div>
            }
        </div>        
    )
}