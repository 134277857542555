import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import DashNav from '../home/dashnav'
import { NavActions } from '../utils/enum'
import Util, { Pluralize } from '../utils/util'
import { useAuth } from '../context/auth'

function Dashboard() {

    const util = Util()
    const auth = useAuth()
    const authContext = auth.context
    const favTeam = authContext.favTeam

    // const activate = (callback:Function) => {
    //     if (activation) {            
    //         const url = process.env.REACT_APP_API_VERIFYEMAIL + '?activationCode=' + activation
    //         data.post(url, {}, (data: any) => {
    //             if (data) {
    //                 if (data.errors) {
    //                     let error: any = Object.values(data.errors)[0]
    //                     toast.show(error, 'error');
    //                 } else {
    //                     toast.show(data.message, 'success');
    //                     if(callback) callback()
    //                 }
    //             }
    //         })
    //     }
    // }

    const initDashboard = ()=>{
        // auth.fetchWallet((data: any) => {
        //     if(isSubscribed){
        //         //setFetchingWallet(false); 
        //         setWallet(data)
        //     }            
        //  })
    }

    useEffect(() => {

        //setFetchingWallet(true)

        // update wallet
        //fetchWallet()

        // // confirm user email
        // activate(()=>{
            
        //     // Update tokens
        //     let username = user.username;
        //     const url = process.env.REACT_APP_API_REFRESHTOKEN + '?username=' + username
        //     data.post(url, {}, (resp: any) => {
        //         if(resp.data.token){
        //             // Update stale tokens
        //             setCookie('token', resp.data.token)
        //             setCookie('refreshToken', resp.data.refreshToken)

        //         }
        //     })
        // }) 
        
        return ()=>{
            //setIsSubscribed(false)
        }
        
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        document.title = process.env.REACT_APP_WEBSITE_TITLE + util.titleSeparator() + "Dashboard"
    })

    return (
        <div className="pb-5">
            <DashNav title="Welcome back!" initCallback={initDashboard}>
                
                <p>
                    {
                        favTeam &&
                        <span>You have {!favTeam.points ? 'not earned' : <span>earned {favTeam.points}</span>} {} <Pluralize count={favTeam?.points} single='point' plural='points' /> this week.</span>
                    }                        
                    <span className="mb-3"> Share banters with friends and keep up your chances to  <Link to="/league">finish tops</Link> this week.</span>
                </p>

                {
                    <div className="col-sm-12 mt-5 mb-5 text-center">
                        <Link to={'/banters/action/' + NavActions.StartBanter} className="btn btn-lg btn-primary rounded-pill ps-4 pe-4 pt-3 pb-3 mb-3 mt-3 fw-bold">
                            Start a banter
                        </Link>
                    </div>
                }

                {/* <ul className="list-group list-group-flush mb-5">
                    <li className="list-group-item text-nowrap"><Link to="/banters"><i className="bi bi-hand-thumbs-down" aria-hidden="true" /> Challenge banters</Link></li>
                    <li className="list-group-item">
                        <Link to="#" >
                            <Share channel={ShareChannel.Whatsapp}> Share the excitement</Share> 
                        </Link>
                    </li>                    
                    <li className="list-group-item d-none"><Link to="/store">Visit the fan store</Link></li>
                    <li className="list-group-item d-none"><Link to="/topup">Top up airtime &amp; data</Link></li>
                    
                </ul> */}

            </DashNav>
        </div>
    )
}

export default Dashboard