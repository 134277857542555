import { Modal } from "react-bootstrap"
import useData, { Spinner } from "../utils/data";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useToast } from "../utils/toast";

type Model = {
    tag: string,
    line1: string,
    stateIndex: number,
    stateId: number,
    mobile: string,
}

export default function AddAddress(props: any) {
    const toast = useToast()
    const data = useData()
    const { register, handleSubmit, reset, formState: { errors } } = useForm<Model>()
    const [states, setStates] = useState<any>([])
    const mobilePattern = /^[0-9]{11,11}$/i
    const [errorMessage, setErrorMessage] = useState('')

    const onSubmit: SubmitHandler<Model> = model => {
        setErrorMessage('')
        const state = states[model.stateIndex]
        model.stateId = state.id

        console.log(model)

        data.post(process.env.REACT_APP_API_USERADDRESSES, model, (resp: any) => {
            if (resp && resp.errors) {
                Object.keys(resp.errors).map(key => resp.errors[key].map((error: string) => setErrorMessage(error)))
            } else if (resp.data) {
                toast.show('Your address has been saved.', 'success')

                // close new address modal
                props.setShowAddress(false)

                // reset form
                reset({})

                // fetch user's addresses
                data.get(process.env.REACT_APP_API_USERADDRESSES, (resp: any) => {
                    if (props.setAddresses) {

                        // set addresses
                        props.setAddresses(resp.data)

                        // attempt to complete buy now again
                        props.handleBuyNow(props.selectedIndex)
                    }
                })
            }
        })
    }

    useEffect(() => {
        // fetch states
        data.get(process.env.REACT_APP_API_STATES, (resp: any) => {
            setStates(resp.data)
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Modal show={props.showAddress} onHide={() => props.setShowAddress(false)} className="p-2" centered>
            <Modal.Header closeButton>
                <Modal.Title className="p-4">New address</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-5 pt-4">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <p className="mb-3">
                        Add a new shipping address.
                    </p>
                    <div className="mb-3">
                        <label htmlFor="address" className="form-label">Address</label>
                        <textarea className="form-control" id="address" {...register("line1", {
                            required: 'Address is required'
                        })} rows={2} placeholder="Enter an address in Nigeria"></textarea>
                        {errors.line1 && <small className="text-danger">{errors.line1.message}</small>}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="state" className="form-label">State</label>
                        <select id="state" className="form-control" {...register("stateIndex", {
                            required: 'Choose a state'
                        })}>
                            <option value="">- select one -</option>
                            {
                                states.map((item: any, index: number) => {
                                    return <option key={index} value={index}>{item.name}</option>
                                })
                            }
                        </select>
                        {errors.stateIndex && <small className="text-danger">{errors.stateIndex.message}</small>}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="mobile" className="form-label">Mobile</label>
                        <input defaultValue="" type="text" {...register("mobile", {
                            required: 'Mobile number is required',
                            pattern: { value: mobilePattern, message: 'Enter a valid mobile number' }
                        })} maxLength={11} className="form-control" id="mobile" placeholder="Enter mobile number" />
                        {errors.mobile && <small className="text-danger">{errors.mobile.message}</small>}

                    </div>
                    <div className="mb-1">
                        <label htmlFor="addresstag" className="form-label">Tag</label>
                        <input type="text" id="addresstag" className="form-control" {...register("tag", {
                            required: 'Tag is required'
                        })} />
                        {errors.tag && <small className="text-danger">{errors.tag.message}</small>}
                        {!errors.tag && <div className="form-text">Use something easy to remember. E.g Home, Office, c/o Emma.</div>}

                    </div>
                    <div className="mb-4">
                        <small className="text-danger">{errorMessage}</small>
                    </div>
                    <div className="d-grid gap-2">
                        <button type="submit" className="btn btn-primary" disabled={data.isBusy()}>
                            Save address <Spinner visibility={data.isBusy()} isCentered={false} />
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )

}