import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useForm, SubmitHandler } from "react-hook-form"
import { useToast } from "../utils/toast"
import useData, { Spinner } from "../utils/data"
import Util from '../utils/util'
import { useTheme } from '../context/theme'
import FootballFriends from '../assets/scss/homeimages/fantastake_friends.jpg'

type Model = {
    resetCode: string,
    password: string,
    confirmPassword: string
}

export default function Reset() {

    const themeContext = useTheme()
    const theme = themeContext.theme
    
    const data = useData()
    const toast = useToast()
    const util = Util()
    const { code } = useParams<{ code: string }>();
    const { register, handleSubmit, formState: { errors } } = useForm<Model>()
    const passwordRegexp = util.passwordRegex()
    //const dashboard = process.env.REACT_APP_DASHBOARD_PATH ? process.env.REACT_APP_DASHBOARD_PATH : "#"
    const [success, setSent] = useState(false)

    const onSubmit: SubmitHandler<Model> = model => {
        model.resetCode = code
        data.post(process.env.REACT_APP_API_RESETPASSWORD, model, (resp: any) => {
            if (resp.data) {
                // Hide password reset form
                setSent(true)
                if (resp.message) toast.show(resp.message, 'success')
            } else {
                if (resp.message) toast.show(resp.message, 'error')
            }
        }, (result: any) => {
            if (result) {
                toast.show(result.message)
            }
        })
    }

    return (
        <>
            <div style={{height:250}} className="d-flex align-items-end rounded-bottom rounded-5 mb-5 beginchallenge d-md-none">
                <div className="border border-0 w-100" style={{height:'45%', backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(14,12,14,0.8), rgba(14,12,14,1))'}}></div>
            </div>

            <div className="container-fluid">

                <div className="row mb-5">

                    <div className="col-md-6 d-none d-md-flex">

                        <div className="border border-0 rounded-4 flex-fill m-3" style={{backgroundImage:`url(${FootballFriends})`, backgroundSize:'cover', backgroundPosition:'center'}}>
                        </div>

                    </div>

                    <div className="col-md-6 p-md-5 pb-md-0 border border-0">

                        <div className="ps-lg-5 pe-lg-5">

                            <div className="mb-5">
                                <h1 className={"mb-5 text-light " + theme.color}>{success ? 'Reset successful' : 'Reset your password'}</h1>

                                {
                                    !success ?

                                        <>
                                            <p className="mb-4">
                                                Choose a new password
                                            </p>
                                            <form className="pb-5" onSubmit={handleSubmit(onSubmit)}>
                                                <div className="mb-4">
                                                    <label htmlFor="password" className="form-label">Password</label>
                                                    <input defaultValue="" type="password" {...register("password", {
                                                        required: 'Password is required', minLength: { value: 8, message: 'Password must be at least 8 characters' },
                                                        pattern: { value: passwordRegexp, message: 'Password must contain at least one uppercase, lowercase, digit and special character' }
                                                    })} maxLength={150} className={"form-control form-control-lg " + theme.background + ' ' + theme.color} id="password" placeholder="Choose a password" />
                                                    {errors.password && <small className="text-danger">{errors.password.message}</small>}
                                                </div>
                                                <div className="mb-4">
                                                    <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                                    <input defaultValue="" type="password" {...register("confirmPassword", {
                                                        required: 'Confirm Password is required.', minLength: { value: 8, message: 'Confirm Password must match Password' },
                                                        pattern: { value: passwordRegexp, message: 'Confirm Password must match Password' }
                                                    })} maxLength={150} className={"form-control form-control-lg " + theme.background + ' ' + theme.color} id="password" placeholder="Confirm chosen password" />
                                                    {errors.confirmPassword && <small className="text-danger">{errors.confirmPassword.message}</small>}
                                                </div>
                                                <div className="mb-4 lh-1">
                                                    <span>If you remember your password now, you can <Link to="/login">go back to login</Link></span>
                                                </div>
                                                <div className="d-grid gap-2">
                                                    <button type="submit" className="btn btn-lg btn-primary" disabled={data.isBusy()}>
                                                        Reset password <Spinner visibility={data.isBusy()} isCentered={false} />
                                                    </button>
                                                </div>
                                            </form>
                                        </>

                                        :

                                        <>

                                            <p className="mb-5">
                                                Okay, your password reset was successful. You can proceed to <Link to="/login">login now</Link>.
                                            </p>
                                        </>
                                }

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
        
    )
}