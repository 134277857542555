import { useEffect } from "react"
import { FormEvent, useState } from "react"
import { Link } from "react-router-dom"
import DashNav from "../home/dashnav"
import useData, { Spinner, Currency } from "../utils/data"
import { useToast } from "../utils/toast"
import payOptions from "../assets/mastercardvisa.png"
import usePaystack from "../utils/paystack"
import Transfer from "./transfer"
import fundCard1 from "../assets/manucard.jpg"
import Util from "../utils/util"
import { wallett } from "../utils"
import { useAuth } from "../context/auth"

type Advice = {
    ref: string,
    email: string,
    amount: number,
    key: string
}

export default function Fund() {
    const utils = Util()
    const data = useData()
    const toast = useToast()
    const auth = useAuth()
    const authContext = auth.context
    const user = authContext.getUser()
    const [wallet, setWallet] = useState<wallett>()
    const [lastTrans, setLastTrans] = useState<any>()
    const [isVerifing, setIsVerifing] = useState(false)
    const [direct, setDirect] = useState(false)
    const [openDirect, setOpenDirect] = useState(false)
    const [noCard, setNoCard] = useState(false)
    const dashboard = process.env.REACT_APP_DASHBOARD_PATH ? process.env.REACT_APP_DASHBOARD_PATH : "#"
    const paystack = usePaystack()
    const [advice, setAdvice] = useState<Advice>()
    const [flexibleAmount, setFlexibleAmount] = useState(50000)
    const [isSubscribed, setIsSubscribed] = useState(true)

    useEffect(() => {

        // fetch user's wallet

        console.log('lastTrans', lastTrans)
        if (lastTrans === undefined) {
            //auth.fetchWallet((data: any) => setWallet(data))
            generateAdvice()
        } else {
            const timeout = setTimeout(() => {

                data.get(process.env.REACT_APP_API_WALLET, (resp: any) => {
                    if(isSubscribed === false) return
                    if (isVerifing) {
                        if (wallet && wallet.balance !== resp.data.balance) {
                            setWallet(resp.data)
                            toast.show('Your wallet has been updated.', 'success');
                        } else {
                            // No change in wallet balance
                            setLastTrans(null)
                        }
                    }

                    setIsVerifing(false)
                }, (resp: any) => { console.log('failed to get wallet', resp) })

                generateAdvice()

            }, 6000)

            return () => clearTimeout(timeout)
        }

        return ()=>{
            setIsSubscribed(false)
        }

    }, [lastTrans, user]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleClick = (e: FormEvent, amount: number) => {
        e.preventDefault()
        if (!advice) {
            toast.show('No payment advice found. Please confirm network connection.', 'error')
            return
        }

        setDirect(false)
        setNoCard(false)

        advice.amount = amount
        setAdvice(advice)

        console.log('advice', advice)

        // Present paystack/flutterwave option only if there's a key
        if (advice.key) {
            paystack.pop(advice, (resp: any) => {
                console.log('provider success', resp)
                // Convert amount to lowest currency unit by multiplying by 100
                let model = { reference: advice.ref, amount: { value: advice.amount, currency: wallet?.curr } }
                data.post(process.env.REACT_APP_API_WALLET, model, (resp: any) => {
                    console.log(resp)
                    setIsVerifing(true)
                    setLastTrans(resp)
                    let message = 'We are verifying your transaction.'
                    toast.show(message, 'success')
                    window.scrollTo(0, 0)
                })
            }, () => {
                toast.show("Having card trouble? Make a direct transfer.")
                setDirect(true)
                window.scrollTo(0, 0)
            })
        } else {
            // Present direct transfer option
            toast.show("Card payment providers are unavailable at this time. Please use the Direct Transfer option.")
            setNoCard(true)
            window.scrollTo(0, 0)
        }

    }

    const generateAdvice = () => {
        // Get reference and determine payment provider to use
        data.get(process.env.REACT_APP_API_PAYREFERENCE + '?amount=' + 0 + '&currency=' + wallet?.curr, (resp: any) => {
            if (!resp.data) {
                toast.show('Please confirm your network connection and refresh.', 'error')
                return
            }
            let result = resp.data
            if(user){
                let advice = {
                    ref: result.reference,
                    email: user.username,
                    amount: result.amount,
                    key: result.publicKey,
                }
                console.log('generated advice', advice)
                setAdvice(advice)
            }
            
        })
    }

    return (
        <>
            <DashNav title="My wallet">
                {
                    wallet && 
                    <p className="mb-1">
                        Your wallet balance is <Link to={dashboard}>
                            <span dangerouslySetInnerHTML={{ __html: wallet?.currency?.html }}></span>{(wallet.balance / 100).toLocaleString()}
                        </Link>.
                    </p>
                }

                <div className="mb-3">

                    {
                        isVerifing ?
                            <div className="form-text">Please allow a few moments to verify your last <span dangerouslySetInnerHTML={{ __html: wallet?.currency?.html }}></span>{(lastTrans?.amount?.value / 100).toLocaleString()} transaction <small><Spinner visibility={true} className="form-text" /></small></div>
                            :
                            <div className="form-text">
                                {
                                    lastTrans === null ? 'There was a problem verifying your last transaction. It\'s possibly a temporary network delay so we will try again soon.' : ''
                                }
                            </div>
                    }
                </div>

                <div className="mb-3">
                    <div className="mb-1">
                        Choose an amount to fund your wallet.
                    </div>
                    {
                        direct ?
                            <div className="form-text">
                                You can make a direct transfer but it will not reflect in your wallet instantly. Okay, <Link to="#" onClick={() => setOpenDirect(true)}>Direct Transfer.</Link>
                            </div>
                            : ''
                    }
                    {
                        noCard ?
                            <div className="form-text">
                                Card payment option is unavailable at this time. Please use the <Link to="#" onClick={() => setOpenDirect(true)}>Direct Transfer</Link> option.
                            </div>
                            : ''
                    }
                </div>

                <ul className="list-group list-group-flush mb-3">
                    <li className="list-group-item p-5 ml-0 mb-2 mt-2" style={{ background: `url(${fundCard1})`, backgroundSize: 'cover' }}>
                        <Link to="#" className="btn btn-light" onClick={(e) => handleClick(e, 100 * 1000)}>
                            <Currency symbol={wallet?.currency?.html} />1,000</Link>
                    </li>
                    <li className="list-group-item p-5 ml-0 mb-2 mt-2" style={{ background: `url(${fundCard1})`, backgroundSize: 'cover' }}>
                        <Link to="#" className="btn btn-light" onClick={(e) => handleClick(e, 100 * 5000)}>
                            <Currency symbol={wallet?.currency?.html} />5,000</Link>
                    </li>
                    <li className="list-group-item p-5 ml-0 mb-2 mt-2" style={{ background: `url(${fundCard1})`, backgroundSize: 'cover' }}>
                        <Link to="#" className="btn btn-light" onClick={(e) => handleClick(e, 100 * 25000)}>
                            <Currency symbol={wallet?.currency?.html} />25,000</Link>
                    </li>
                    <li className="list-group-item p-0 ml-0 mb-2 mt-2">
                        <label className="form-label">Other amount</label>
                        <div className="input-group">
                            <input className="form-control" value={flexibleAmount} onChange={(e: any) => setFlexibleAmount(e.target.value)} type="number" min="50000" max="100000" step="1000" placeholder="Enter amount" />
                            <button className="btn btn-outline-dark" onClick={(e) => handleClick(e, 100 * flexibleAmount)}>
                                <Currency symbol={wallet?.currency?.html} />{utils.money(100 * flexibleAmount)}
                            </button>
                        </div>
                    </li>
                </ul>

                <p className="mb-5">
                    You can shop at a fan store, top up <Link to="/topup">airtime &amp; data</Link> or even take out all your wallet funds at any time.
                </p>

                <p>
                    <img style={{ height: '36px' }} src={payOptions} alt="Payment methods, Mastercard, Visa." loading="lazy" />
                </p>

            </DashNav>

            <Transfer direct={openDirect} setDirect={setOpenDirect} advice={advice} currency={wallet?.currency?.html} />

        </>
    )
}
