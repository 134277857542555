type cookie = {
    setCookie: Function,
    getCookie: Function,    
    parseJwt: Function,
    expireCookie: Function,
}

export function Cookie() : cookie {

    return {
        setCookie: setCookie,
        getCookie : getCookie,
        parseJwt : parseJwt,
        expireCookie : expireCookie,        
    }
    
}

export const getCookie = (name: string) => {

    let cookies = document.cookie.split(';')
    let keyPairs = cookies.filter(c => c.indexOf(name + '=') > -1)
    if (keyPairs.length === 0) return '';
    let cookie = keyPairs[0].split('=')[1]
    return cookie;
}

export const setCookie = (name: string, value:string, expireDuration?: number) => {

    if(!expireDuration){
        document.cookie = name + '=' + value + '; path=/; SameSite=Lax'
    }else{
        document.cookie = name + '=' + value + '; path=/; SameSite=Lax; max-age=' + (60 * expireDuration)
    }
}

export const expireCookie = (name: string, path?:string) => {

    if(!path) path = '/'
    var date = new Date('1970-01-01')
    
    if(document.cookie){
        let cookiePairs = document.cookie.split(';')
        for(let i = 0; i < cookiePairs.length; i++){            
            let cookie = cookiePairs[i]
            cookie = cookie.trim().split('=')[0]
            if(cookie?.trim() === name) {
                document.cookie = name + '=; expires=' + date.toUTCString() + '; path='+ path +'; SameSite=Lax'
            }
        }        
    }
}

export const parseJwt = (token: string) => {

    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}