import { FaGem } from "react-icons/fa"
import { useTheme } from "../context/theme"
import { ShareChannel } from "../utils/enum"
import Banter from "./banter"
import Share from "../utils/share"

export default function SocialBanter(props:any){

    const themeContext = useTheme()
    const theme = themeContext.theme

    const banter = props?.banter
    const handleBanterClick = props?.handleBanterClick
    const handleShare = props?.handleShare
    //const banterPosted = props?.banterPosted
    const setBanterPosted = props?.setBanterPosted
    const showNextArrow = props.showNextArrow
    
    //const removeBanter = props?.removeBanter

    // const nextBanter = ()=>{

    //     if(handleBanterClick) {
    //         handleBanterClick(banter)

    //         if(removeBanter){
    //             removeBanter(banter)
    //         }
    //     }
    // }

    if(!banter) return <>No banter</>

    return(
        <div className={"p-5 pt-4 list-group-item border-top-0 list-group-item-action fade-in " + theme.background} aria-current="true">

            <Banter banter={banter} handleBanterClick={handleBanterClick} showNextArrow={showNextArrow} />
            
            {
                banter?.isPromoted && 
                <span className="badge bg-warning text-dark rounded-pill me-1" data-testid="bounty" >
                    <FaGem />
                </span>
            }
            {
                <div className="mt-2 mb-2">

                    {/* <div className="d-flex justify-content-between align-items-center">
                        <Link to="#" className={theme.color}>
                            {
                                banter?.endMinutes >= 1 && banter?.endMeasure.toLowerCase() !== 'tomorrow' && <span>{banter?.endMinutes}</span>
                            } {
                                banter?.endMeasure
                            }
                        </Link>
                        
                    </div> */}
                
                
                {/* {
                    (banter.supports + banter.challenges) > 0 && 
                    <Link to="#" data-testid="fansViewCount" onClick={handleBanterClick} className={theme.color}>
                        {(banter.supports + banter.challenges) * boostFactor} {(banter.supports + banter.challenges) * boostFactor === 1 ? 'act' : 'acts'}
                    </Link>
                } */}
                <div className="mt-4">

                    <span className="me-4 text-muted">
                        <Share channel={ShareChannel.Whatsapp} banter={banter} handleShare={handleShare} setBanterPosted={setBanterPosted} />
                    </span>

                    <span className="ms-4 text-muted">
                        <Share channel={ShareChannel.Twitter} banter={banter} handleShare={handleShare} setBanterPosted={setBanterPosted} />
                    </span>

                </div>
            </div>                                
            }
            {
                //props.index === 0 && banterPosted && 
                //<div className="form-text mt-2 text-end"><span>share now</span></div>
            }
            
        </div>
    )
}