import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import useData from '../utils/data'
import DashNav from './dashnav'
import { LeaderBoardCode } from '../utils/enum'
import { leaderBoard, response, userr } from '../utils'
import Util, { Pluralize } from '../utils/util'
import { useTheme } from '../context/theme'
import { useAuth } from '../context/auth'
import HeroesBoard from './heroesboard'
import { usePopup } from '../utils/popup'
import BanterButton from './banterbutton'
import { useAccolades } from '../member/accolades'
import CrownLogo from '../assets/crown.png'

export default function HeroesLeague() {

    const util = Util()
    const themeContext = useTheme()
    const theme = themeContext.theme
    const popup = usePopup()

    const data = useData()
    const auth = useAuth()
    const authContext = auth.context
    const [user, setUser] = useState<userr>(authContext.getUser())
    const isBusy = data.isBusy();
    const accolades = useAccolades()

    
    //const [userBoard, setUserBoard] = useState<leaderBoard>()
    //const [isSubscribed, setIsSubscribed] = useState(true)
    const [toppers, setToppers] = useState<any[]>([])

    const boards = [
        { leaderBoardCode: LeaderBoardCode.Today, name : 'Today', skip : 0, start : 0, count : 15, daysoffset : 0},
        { leaderBoardCode: LeaderBoardCode.LastWeek, name : 'Last Week', skip : 0, start : 0, count : 15, daysoffset : -7}
    ]

    const [selectedLeaderBoard, setSelectedLeaderBoard] = useState(boards[0])

    const avatarBase = process.env.REACT_APP_API_AVATARBASEURL    

    // const fetchUserBoardPosition = (skip:number, count:number)=>{

    //     const url = process.env.REACT_APP_API_USERBOARDPOSITION + '?skip=' + skip + '&count=' + count
    //     data.get(url, (resp:response<any>)=>{
    //         if(isSubscribed){
    //             let userPosition : number = resp.data
    //             let userBoard = boards.filter(b=> userPosition > 0 && userPosition <= (b.start + count))[0]
    //             if(userBoard){
    //                 let newUserBoard : leaderBoard = {...userBoard, position : userPosition}
    //                 setUserBoard(newUserBoard)
    //             } 
    //         }
                       
    //     })
    // }

    const handleLeaderBoardClick = (leaderBoard:leaderBoard)=>{
        if(!leaderBoard) return

        setToppers([])

        setSelectedLeaderBoard(leaderBoard)

        let skip = leaderBoard.skip
        let count = leaderBoard.count
        let daysFromStart = leaderBoard.daysoffset

        fetchToppers(skip, count, daysFromStart)

        //fetchUserBoardPosition(skip, count)       
    }

    const fetchToppers = (skip:number, count:number, startDayOffset: number = 0)=>{
        const url = process.env.REACT_APP_API_BOARDS + '?skip=' + skip + '&count=' + count + '&startdayoffset=' + startDayOffset
        data.get(url, (resp:response<any>)=>{

            const toppers = resp.data.toppers

            let userPosition: number | undefined = undefined
            const userTopper = toppers.filter((t:any)=>t.userId === user.id)[0]
            if(userTopper){
                userPosition = userTopper.position
            }
            
            setToppers(toppers)      
            setUser({ ...user, position : userPosition })            
        })     
    }

    const init = ()=>{ 
        let skip = selectedLeaderBoard.skip
        let count = selectedLeaderBoard.count
        let daysFromStart = selectedLeaderBoard.daysoffset

        fetchToppers(skip, count, daysFromStart)

        //fetchUserBoardPosition(skip, count)

    }

    useEffect(() => {

        return () => {            
            // unsubscribe to prevent updating state if unmounted
            //setIsSubscribed(false)
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        document.title = process.env.REACT_APP_WEBSITE_TITLE + util.titleSeparator() + "Heroes League"
    })

    return (
        <div className="pb-5">
            <DashNav title="" initCallback={()=>init()}>

            <nav className="nav nav-justified mt-0">
                <Link onClick={()=>{handleLeaderBoardClick(boards[0])}} className={"nav-link ms-3 me-2 ps-2 pe-2 " + (selectedLeaderBoard.leaderBoardCode === LeaderBoardCode.Today ? "border border-primary border-2 border-top-0 border-start-0 border-end-0" : theme.color)} aria-current="page" to="#">Today</Link>
                <Link onClick={()=>{handleLeaderBoardClick(boards[1])}} className={"nav-link ellipsis ms-3 me-2 ps-2 pe-2 " + (selectedLeaderBoard.leaderBoardCode === LeaderBoardCode.LastWeek ? "border border-primary border-2 border-top-0 border-start-0 border-end-0" : theme.color)} to="#">Last Week</Link>
            </nav>            

            <div className="mt-4">
                <HeroesBoard isBusy={isBusy} toppers={toppers} showCrown={selectedLeaderBoard.leaderBoardCode === LeaderBoardCode.LastWeek} leaderBoardCode={selectedLeaderBoard.leaderBoardCode} />
            </div>            

            {
                user && toppers.length > 0 && 
                <div className="mt-5 mb-3 border border-0">
                    <div className="d-flex justify-content-center">                        
                        <div>
                            <span className="fs-5">Your spot {(selectedLeaderBoard.leaderBoardCode === LeaderBoardCode.LastWeek ? 'last week' : '')}</span>
                            <span className="text-dark badge rounded-pill leagueposition bg-info fs-6 p-1 ms-1 ps-2 pe-2" style={{backgroundColor:'#B685F1', minWidth:28, lineHeight:'1.2rem'}}>
                                { user.position ?? toppers.length + '+' }
                            </span>
                        </div>
                    </div>                    
                </div>
                
            }
            
            <div className="mt-2 mb-5">
                <div className={"ard " + theme.background}>
                    {/* <div className="bg bg-light" style={{minHeight:130, backgroundPosition: 'top', backgroundSize:'cover', backgroundRepeat: 'no-repeat', backgroundImage:`url(${worldcup})`}}>
                    </div>
                    
                    <div className="card-body bg-warning d-flex justify-content-between">
                        <h5 className="card-title fw-bold text-dark">{selectedLeaderBoard?.name ? selectedLeaderBoard.name : 'League'}</h5>
                    </div> */}                    
                    <ul className="list-group list-group-flush">
                        <li className={"p-0 list-group-item border-0 " + theme.color}>
                            <div className="p-2 pe-3 mb-1 card" style={{backgroundColor: '#533508'}}>
                                <div className="d-flex justify-content-between align-items-center">

                                    <div className="p-2"><img  alt="heroes crown" src={CrownLogo}  /></div>
                                    
                                    <div className="flex-fill p-2 fw-bold fs-5 text-light">Heroes League</div>

                                    <i style={{cursor:'pointer'}} className={"bi bi-info-circle-fill text-secondary"} onClick={()=>popup.show('Contests for friends is coming to you soon. Watch out for updates.')}></i>

                                </div>
                                
                            </div>
                        </li>
                        {
                            toppers.map((profile, index)=>{
                                let backgroundUrl = ''
                                if(profile.sex){
                                    backgroundUrl = avatarBase + authContext.appendSex(profile.sex) + profile.avatarFile + '.svg?background=%231C1A1E&mouth=variant05'
                                }

                                return <li style={{cursor:'pointer'}} className="p-0 list-group-item border-0" key={index} onClick={()=>accolades.show(profile)}>
                                        <div className={"p-2 mb-1 card"} style={{background : (index <= 4 ? '#281B08' : '#1C1A1E'), borderColor:(index <= 4 ? '#281B08' : '#1C1A1E')}}>
                                            <div className="d-flex align-items-center ps-1 pe-2 pt-1 pb-1">
                                                <div className="border border-0">
                                                    <div className="text-warning ps-2 fw-bold border border-0 rounded-circle d-flex justify-content-center align-items-center">

                                                        {
                                                            index + 1 === 1 &&
                                                            <span className="fs-1 ms-0 bi bi-1-circle-fill fs-1 text-warning"></span> 
                                                        }

                                                        {
                                                            index + 1 === 2 &&
                                                            <span className="fs-1 bi bi-2-circle-fill fs-1 text-secondary"></span> 
                                                        }

                                                        {
                                                            index + 1 === 3 &&
                                                            <span className="fs-1 bi bi-3-circle-fill fs-1 text-danger"></span> 
                                                        }

                                                        { 
                                                            index + 1 > 3 &&
                                                            <span className="ps-1">{ index + 1 }</span>
                                                        }

                                                    </div>
                                                </div>
                                                <div className="flex-fill">
                                                    <div className={"border border-0 d-flex justify-content align-items-center " + theme.color}>
                                                        
                                                        {
                                                            // Avatar url available
                                                            backgroundUrl && 
                                                            <div className="ms-2 me-3 p-3 fw-bold border border-0 rounded-circle d-flex justify-content-center align-items-center" 
                                                                style={{background: `url(` + backgroundUrl + `)`, cursor: 'pointer', backgroundSize:'cover', backgroundRepeat: 'no-repeat', backgroundPosition:'center'}}>
                                                                
                                                            </div>
                                                        }

                                                        {
                                                            // No avatar url
                                                            !backgroundUrl &&
                                                            <div className="ms-2 me-3 p-1 fw-bold border border-0 rounded-circle d-flex justify-content-center align-items-center">
                                                                <i className="bi bi-person-circle text-secondary fs-4"></i>
                                                            </div>
                                                        }                                                        
                                                        
                                                        <div className='flex-fill'>
                                                            <div className="d-flex border border-0 align-items-end">
                                                                <div className={"flex-fill fs-5 fw-bold text-secondary border border-0 "}>
                                                                    <Link to="#">
                                                                        {profile.firstName} 
                                                                        { 
                                                                            profile.awards?.length > 0 && selectedLeaderBoard.leaderBoardCode === LeaderBoardCode.Today &&
                                                                            <i className="ms-2 bi bi-star fs-6"></i> 
                                                                        }
                                                                    </Link>                                                                    
                                                                </div>
                                                                <div className='border border-0'>
                                                                    {profile?.favouriteTeam?.logo && <img loading="lazy" className="rounded-circle" style={{ width: 25, height:25 }} src={profile?.favouriteTeam?.logo} alt="" />}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="d-flex border border-0 fs-6">
                                                                <div className="flex-fill text-nowrap text-secondary">
                                                                    {profile.weeklyPoints > 0 && profile.weeklyPoints} {profile.weeklyPoints > 0 &&<Pluralize count={profile.weeklyPoints} single="point" plural="points"/>}
                                                                </div>
                                                                <div className="truncate text-secondary" style={{maxWidth:85}}>
                                                                    {profile?.favouriteTeam?.name}
                                                                </div>
                                                            </div>
                                                        </div>                                                
                                                         
                                                    </div>                                                    
                                                </div>
                                                

                                            </div>

                                            

                                            {/* <div className="d-flex justify-content-between text-secondary">
                                                <span className="fs-5">{profile.weeklyPoints > 0 && profile.weeklyPoints} {profile.weeklyPoints > 0 &&<Pluralize count={profile.weeklyPoints} single="point" plural="points"/>}</span>
                                                <div className="fs-6 ">{profile?.favouriteTeam?.name}</div>                                            
                                            </div> */}

                                                {
                                                    index === 0 && selectedLeaderBoard.leaderBoardCode === LeaderBoardCode.LastWeek && profile.awards?.length > 0 &&
                                                    <div className={"ps-3 fs-6 " + theme.color}>
                                                        <Link to="#" className="text-secondary">
                                                            <i className="bi bi-trophy me-1 text-warning" /> Won <span>{(profile.awards[0].walletReward / 100)} coins</span> last week.
                                                        </Link>                                                    
                                                    </div>
                                                }
                                        </div>
                                    </li>
                            })
                        }
                    </ul>
                    
                </div>

            </div>
            {
                toppers.length >= 15 &&
                <p className="text-center fs-5">Showing only top 15</p>

            }

            </DashNav>

            <BanterButton />
            
        </div>
    )

}