import { CloseButton, Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import useData, { Spinner } from "../utils/data";
import { FanActivity, ShareChannel } from "../utils/enum";
import { bantter, response } from "../utils";
import Banter from "./banter";
import { useTheme } from "../context/theme";
import { useState } from "react";
import Share from "../utils/share";
import useAnalytics from "../utils/analytics";
import { usePopup } from "../utils/popup";

export default function ConfirmBanter(props: any) {

    const analytics = useAnalytics()
    const themeContext = useTheme()
    const theme = themeContext.theme
    const history = useHistory()
    
    const banter = props.composedBanter

    const setComposedBanter = props.setComposedBanter
    const updateRecentBanters = props.updateRecentBanters
    const setBanterPosted = props.setBanterPosted
    const data = useData()
    const popup = usePopup()
    const [isActed, setIsActed] = useState(false)

    const postBanter = (banter: bantter) => {        
        if (!banter || !updateRecentBanters) return

        data.post(process.env.REACT_APP_API_BANTERS, banter, (resp: response<any>) => {
            if (resp && resp.data) {                
                setComposedBanter(resp.data)
                updateRecentBanters(resp.data)                
                setBanterPosted(true)

                setIsActed(true)

                // award points for starting a banter
                analytics.awardPoints(FanActivity.StartBanter)              

                // set daily banter count cookie
                let dailyBanterCount = data.getCookie('dailyBanterCount')
                let utcHours = new Date().getUTCHours()
                let expireMinutes = (24 - utcHours) * 60

                if(dailyBanterCount){
                    let dailyBanterCountNum = parseInt(dailyBanterCount)
                    data.setCookie('dailyBanterCount', (dailyBanterCountNum + 1).toString(), expireMinutes)
                }else{
                    data.setCookie('dailyBanterCount', '1', expireMinutes)
                }

                history.push('/banters/tabs/1/' + Math.random())

                // switch tabs to users banters
                
            } else {
                closeModal()
                if (resp?.errors) {
                    const key = Object.keys(resp.errors).filter((key, index) => index === 0)[0]
                    //toast.show(resp.errors[key][0], 'error')
                    popup.show(resp.errors[key][0])
                }
            }
        }, (resp: response<any>) => {
            closeModal()
            if (resp && resp.errors) {
                const key = Object.keys(resp.errors).filter((key, index) => index === 0)[0]
                popup.show(resp.errors[key][0])
            }

        })
    }

    const closeModal = () => {
        setIsActed(false)
        if (props.setShowConfirmBanter)
            props.setShowConfirmBanter(false)
    }

    return (
        <Modal show={props.showConfirmBanter} onHide={() => closeModal()} contentClassName={"border border-secondary " + theme.background} className="p-2" centered>
            <Modal.Header>
                <CloseButton className={theme.name === 'dark' ? 'btn-close-white' : ''} onClick={()=>closeModal()} />
            </Modal.Header>
            <Modal.Body className="p-5 pt-0">
                {/* <p className="mb-3 lh-1">
                    Confirm your banter
                </p> */}
                <div className="list-group mb-3">
                    
                    {
                        banter && <div className={"list-group-item " + theme.background + ' ' + theme.color} aria-current="true">
                            {
                                <Banter banter={banter} isPhraseTruncated={false} />
                            }
                        </div>
                    }
                </div>
                {
                    isActed &&
                    <div className="mt-3 mb-3 text-center fade-in" onClick={() => closeModal()}>
                        <p className="lh-1">Now share your banter</p>
                        <div className="d-flex justify-content-center">
                            <div>
                                <Link to="#" onClick={(e) => setIsActed(false)} className={'ps-4 pe-4 fs-2 ' + theme.background}>
                                    <Share banter={banter} channel={ShareChannel.Whatsapp} />
                                </Link>
                            </div>
                            <div>
                                
                                <Link to="#" onClick={(e) => setIsActed(false)} className={'ps-4 pe-4 fs-2 '  + theme.background}>
                                    <Share banter={banter} channel={ShareChannel.Twitter} />
                                </Link>
                            </div>                                
                        </div>
                    </div>
                }
                {
                    isActed === false &&
                    <div className="d-grid gap-2">
                        <button type="button" className="btn btn-lg btn-primary" onClick={() => postBanter(banter)} disabled={data.isBusy()}>
                            Yes, Continue <Spinner visibility={data.isBusy()} isCentered={false} />
                        </button>
                    </div>
                }                

            </Modal.Body>
        </Modal>
    )

}
