import { bantter } from "."
import useAnalytics from "./analytics"
import { FanActivity, ShareChannel } from "./enum"
import { useTheme } from "../context/theme"
import { Cookie } from "./cookie"

export default function Share(props:any){

    const themeContext = useTheme()
    const theme = themeContext.theme
    const cookie = Cookie()
    let expireMinutes = (24 - new Date().getUTCHours()) * 60

    const analytics = useAnalytics()
    const shareMessage = props?.message   

    const banter = props?.banter
    const placeChildrenRight = props?.placeChildrenRight ?? true;

    const setBanterPosted = props?.setBanterPosted
    let channel = ShareChannel.Whatsapp
    if(props?.channel) channel = props?.channel

    let showShareIcon = true
    if(props?.showShareIcon !== undefined) showShareIcon = props?.showShareIcon

    let shareIcon = channel === ShareChannel.Whatsapp ? <i className="bi bi-whatsapp"></i> : <i className="bi bi-twitter"></i>
    const whatsAppShareUrl = 'https://wa.me/?text='
    const twitterShareUrl = 'https://twitter.com/intent/tweet?text=';

    const handleShare = (platform:ShareChannel, banter:bantter, callback:Function) =>{

        if(banter){

            // Sharing a banter
        
            let banterPhrase = banter.phrase;
            let banterLink = 'https://' + process.env.REACT_APP_URL + "/banters/" + banter.id
            let isBanterEnded = banter.endMinutes && banter.endMinutes <= 0
            let banterUrl = 'https://' + process.env.REACT_APP_URL + '/banters'
            if(platform === ShareChannel.Whatsapp){
                let messageGreeting = ""            
                let messageBody = "Upcoming game on fantastake.com, " + banter.name + ", be expecting " + banterPhrase + ". Are you up for the challenge? It's game on!! " + banterLink + ""
                
                if(isBanterEnded){
                    //let activityCount = Math.ceil((banter.challenges ?? 0) + (banter.supports ?? 0) + (banter.views ?? 0))
                    messageGreeting = ""
                    messageBody = "" + banter.name + " @fantastakeo, this game received quite a buzz. See more banters on " + banterUrl
                }

                let message = messageGreeting + messageBody
                //window.open('whatsapp://send?text=' + encodeURIComponent(message))
                window.open(whatsAppShareUrl + encodeURIComponent(message))
                if(callback) callback()
            }else if(platform === ShareChannel.Twitter){
                let messageGreeting = ""
                let messageBody = "Upcoming game on @fantastakeo, " + banter.name + ", be expecting " + banterPhrase + ". Are you up for the challenge? It's game on!! " + banterLink + ""

                if(isBanterEnded){
                    //let activityCount = Math.ceil((banter.challenges ?? 0) + (banter.supports ?? 0) + (banter.views ?? 0))
                    messageBody = "" + banter.name + " @fantastakeo, this game received quite a buzz. See more banters on " + banterUrl
                }

                let message = messageGreeting + messageBody
                window.open(twitterShareUrl + encodeURIComponent(message))
                if(callback) callback()
            }
        }else{

            // Telling a friend
            let message = "Join the Fantastake Heroes League Challenge. Sign up now on https://" + process.env.REACT_APP_URL
            if(shareMessage) message = shareMessage

            if(platform === ShareChannel.Whatsapp){
                window.open(whatsAppShareUrl + encodeURIComponent(message))
            }

            if(platform === ShareChannel.Twitter){
                window.open(twitterShareUrl + encodeURIComponent(message))
            }
        }        

        // award points for sharing
        if(banter && banter.id && banter.id > 0){
            cookie.setCookie('dailyShareCount', '1', expireMinutes)
            analytics.awardPoints(FanActivity.Share)
        }
    }

    return(
        <span style={{cursor: 'pointer'}} className={"text-nowrap " + theme.link} data-testid="whatsappShare" onClick={()=>{handleShare(channel, banter, setBanterPosted)}}>
            { !placeChildrenRight && props.children } {showShareIcon && shareIcon} { placeChildrenRight && props.children }
        </span>
    )
}