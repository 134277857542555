import React, { useState, useContext } from 'react'
import { Button, CloseButton, Modal } from 'react-bootstrap'
import { useTheme } from '../context/theme'

const defaultTitle = 'Info'
const defaultMessage = ''
const defaultConfig = { okayButton : { text: 'Got it', callback : ()=>{} }}

const init = { 
    config : defaultConfig, 
    visible: false, 
    title: defaultTitle, 
    message: defaultMessage, 
    hide: () => { }, 
    show: (message: string, title?: undefined|null|string, options?: any) => { } 
}

const ModalContext = React.createContext(init)

// Custom hook for modal
export const usePopup = () => {
    return useContext(ModalContext)
}

const useModal = () => {
    const [visible, setVisible] = useState(init.visible)
    const [message, setMessage] = useState(init.message)
    const [title, setTitle] = useState(init.title)
    const [config, setConfig] = useState(defaultConfig)

    init.config = config
    init.visible = visible
    init.hide = () => {
        setVisible(false)
        setTitle(title)
    }
    init.title = title
    init.message = message
    init.show = (message, title, options) => {

        setConfig(defaultConfig)
        if(options !== undefined) {
            setConfig(options)
        }

        if(title === undefined || title === null) { 
            title = ''
        }else if(title === ''){
            title = defaultTitle
        }
        if(!message) message = defaultMessage
        
        setMessage(message)        
        setTitle(title)        
        setVisible(true)
    }
    
    return init;
}

export default function PopProvider({ children }: any) {

    const themeContext = useTheme()
    const theme = themeContext.theme

    const popup = useModal()

    const handleButtonClick = ()=>{

        popup.hide()

        if(popup?.config?.okayButton?.callback){

            popup.config.okayButton.callback()

        }

    }

    return (
        <ModalContext.Provider value={popup}>

            { children }

            <Modal show={popup.visible} onHide={popup.hide} contentClassName={"border border-secondary " + theme.background} className="p-2 pb-0" centered>
                <Modal.Header>
                    <Modal.Title className="p-3 lh-1">{popup.title}</Modal.Title>
                    <CloseButton className={theme.name === 'dark' ? 'btn-close-white' : ''} onClick={()=>popup.hide()} />
                </Modal.Header>
                <Modal.Body className="p-5 pt-2 pb-0">
                    <div className="mb-2 fs-5 lh-1">
                        { popup.message }
                    </div>
                </Modal.Body>
                <Modal.Footer className="pb-3">
                    {/* <Button variant="secondary">Close</Button> */}
                    { popup.config?.okayButton && <Button variant="primary" onClick={()=>handleButtonClick()}>{popup.config.okayButton.text}</Button> }                    
                </Modal.Footer>
            </Modal>            

        </ModalContext.Provider>
    )
}