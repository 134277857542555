import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import useData from '../utils/data'
import Util from '../utils/util'
import HeroesLeaguePhone from '../assets/scss/homeimages/heroesleagueacrobat.png'
import UltimateBoardLight from '../assets/scss/homeimages/ultimateboard_light.png'
import UltimateBoardDark from '../assets/scss/homeimages/ultimateboard_dark.png'
import { useTheme } from '../context/theme'
import FriendsScream from '../assets/scss/homeimages/friendsscream.png'
import ChalleneSupport from '../assets/scss/homeimages/challengesupport.png'
import ShareSocialMedia from '../assets/scss/homeimages/shareonsocialmedia.png'
import FootballHeader from '../assets/scss/homeimages/footballheader2.png'

export default function Home() {
    
    const themeContext = useTheme()
    const theme = themeContext.theme

    const data = useData()
    const util = Util()

    const dashboard = process.env.REACT_APP_DASHBOARD_PATH ? process.env.REACT_APP_DASHBOARD_PATH : "#"
    const token = data.getCookie('token')    

    useEffect(()=>{
        document.title = process.env.REACT_APP_WEBSITE_TITLE + util.titleSeparator() + "Home"

        // Match pattern of invite code to determine next action

    })

    return (
        <div className="pt-lg-5">

            <div className="getready pb-5 mt-5">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-lg-5 ps-5 pe-5 ps-lg-0 mb-5">
                            <h1 className={"pt-4 fw-bold text-center text-lg-start text-light " + theme.color}>
                                <div>Get ready</div>
                                <div>for the</div>
                                <div className={theme.name === 'light' ? "text-danger": "textcolor-lime"}>challenge</div>
                            </h1>
                            <div className={"fs-4 pt-2 ps-lg-0 pe-lg-5 fw-bold text-center text-lg-start text-" + (theme.name === 'light'? 'secondary' : 'light')}>
                                
                            Start the contests that make your friendships great.

                            </div>
                            <div className="mt-5 text-center text-lg-start">

                                {
                                    !token &&
                                    <Link to="/signup" className="btn btn-lg btn-primary ps-5 pe-5 pt-3 pb-3">
                                        <span className="fs-4 fw-bold ps-lg-4 pe-lg-4 text-nowrap">Sign up</span>
                                    </Link>
                                }
                                {
                                    token &&
                                    <Link to={dashboard} className="btn btn-lg btn-primary ps-5 pe-5 pt-3 pb-3">
                                        <span className="fs-4 fw-bold ps-lg-4 pe-lg-4 text-nowrap">Start a banter</span>
                                    </Link>
                                }

                            </div>
                        </div>
                        <div className="col-lg-7 p-lg-0">
                            <img src={FootballHeader} style={{width:'100%'}} alt="footballer scoring a header" loading="lazy" />
                        </div>
                    </div>
                </div>

            </div>

            <div className="settlescores pb-5">

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 p-3 p-lg-5">
                            <h1 className={"text-center pe-lg-5 text-light " + theme.color}>
                                <div className="ps-2 pe-2">
                                    Settle scores
                                </div>
                                <div className="ps-2 pe-2">
                                    with fans online
                                </div>
                            </h1>                            
                        </div>
                    </div>
                </div>

                <div className={theme.name === 'light' ? 'teamlogostrip-light' : 'teamlogostrip-dark'}>
                    <div className="teamlogostrip"></div>
                </div>

            </div>

            <div className="howitworks p-3 mt-5 p-lg-5">
                <div className="container">

                    <div className="row">

                        <div className="col-12 fs-4 mb-5 ">
                            <h3 className={"text-center mb-4 fw-bold text-" + (theme.name === 'light' ? 'secondary' : 'light')}>HOW IT WORKS</h3>
                            <h1 className={"text-center pe-lg-5 text-light " + theme.color}>
                                <div className="ps-2 pe-2">
                                    Start a banter
                                </div>
                                {/* <div className="ps-2 pe-2">
                                    banters as you can
                                </div> */}
                            </h1>
                        </div>

                    </div>

                    <div className="row">

                        <div className="col-12 rounded-4 mb-5 pt-5" style={{backgroundColor: '#E94C4C'}}>
                        
                            <div className="row">

                                <div className="col-lg-6 pt-lg-5 ps-4 pe-4 ps-lg-5 pe-lg-5">

                                    <div className="ps-lg-5 mb-5">

                                        <p className="banterwithfriends text-light pe-lg-5">
                                            Banter with your friends
                                            
                                        </p>
                                        <p className="fs-3 text-light">
                                            Brag about your most daring predictions.
                                        </p>
                                        <div className="mt-5">
                                            <Link to="/signup" className="btn btn-lg btn-light ps-5 pe-5 pt-3 pb-3" style={{color: '#E94C4C'}}>
                                                <span className="fs-4 fw-bold ps-lg-4 pe-lg-4 text-nowrap">Try it now &rarr;</span>
                                            </Link>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className="col-lg-6 p-0 align-self-end">
                                    <img src={FriendsScream} alt="Banter with your friends" className="w-100" loading="lazy" />
                                </div>

                            </div>
                            
                        </div>
                        <div className="col-12 pt-lg-3 p-0 border-0">
                            <div className="row">

                                <div className="col-lg-6 border-0">
                                    <div className="rounded-4 mb-5 me-lg-2" style={{backgroundColor: '#EBA133'}}>
                                        <div className="row">
                                            <div className="col-12 pt-5 ps-5 pe-5">

                                                <div className="ps-lg-5 pt-lg-5 pe-lg-5 mb-5">

                                                    <p className="banterwithfriends text-light pe-lg-5">
                                                        Join a contest in your clique
                                                    </p>
                                                    <p className="fs-3 text-light">
                                                        Challenge your friends when they make a prediction.
                                                    </p>
                                                    <div className="mt-5">
                                                        <Link to="/signup" className="btn btn-lg btn-dark ps-5 pe-5 pt-3 pb-3" style={{borderColor: '#CF8618', backgroundColor: '#CF8618'}}>
                                                            <span className="fs-4 fw-bold ps-lg-4 pe-lg-4 text-nowrap">Try it now &rarr;</span>
                                                        </Link>
                                                    </div>                                        
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <img src={ChalleneSupport} alt="Challenge and support banters" className="w-100" loading="lazy" />
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>

                                <div className="col-lg-6 border-0">
                                    <div className="rounded-4 mb-5 ms-lg-2" style={{backgroundColor: '#4C81E9'}}>
                                        <div className="row">
                                            <div className="col-12 pt-5 ps-5 pe-5">

                                                <div className="ps-lg-5 pt-lg-5 pe-lg-5 mb-5">

                                                    <p className="banterwithfriends text-light pe-lg-5">
                                                        Share banters on social media
                                                    </p>
                                                    <p className="fs-3 text-light">
                                                        Earn bonus points daily when you share your banters.
                                                    </p>
                                                    <div className="mt-5">
                                                        <Link to="/signup" className="btn btn-lg btn-dark ps-5 pe-5 pt-3 pb-3" style={{borderColor: '#396CCF', backgroundColor: '#396CCF'}}>
                                                            <span className="fs-4 fw-bold ps-lg-4 pe-lg-4 text-nowrap">Try it now &rarr;</span>
                                                        </Link>
                                                    </div>                                        
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <img src={ShareSocialMedia} alt="Grow your social media for free" className="w-100" loading="lazy" />
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className="heroesleague mt-5 pt-5 border border-0">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-lg-last fs-4 mb-5 ">
                            <h3 className={"text-center text-lg-start mb-4 fw-bold text-" + (theme.name === 'light' ? 'secondary' : 'light')}>HEROES LEAGUE</h3>
                            <h1 className={"text-center text-lg-start pe-lg-5 text-light " + theme.color}>
                                <div className="ps-2 pe-2 ps-lg-0">
                                    The ultimate board for ranking fans
                                </div>
                            </h1>
                            <div className="row">
                                <div className="col-lg-7 fw-bold">
                                    <p className={"fs-3 text-center text-lg-start pt-4 pb-3 mb-0 ps-2 pe-2 ps-lg-0 pe-lg-0 text-" + (theme.name === 'light' ? 'secondary' : 'light')}>
                                        Receive a hero's badge and bragging rights.
                                    </p>
                                </div>
                            </div>
                            <div className="mt-4 text-center text-lg-start">
                                <Link to="/signup" className="btn btn-lg btn-primary ps-5 pe-5 pt-3 pb-3">
                                    <span className="fs-4 fw-bold ps-4 pe-4 text-nowrap">Try it now</span>
                                </Link>
                            </div>

                        </div>

                        <div className="col-lg-6 pe-5 order-lg-first">

                            <img src={theme.name === 'light' ? UltimateBoardLight : UltimateBoardDark} className="w-100" alt="The ultimate scoreboard for ranking true fans." loading="lazy" />
                            
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className="prefooter mt-5">

                <div className="container-fluid">

                    <div className="row border border-0 align-items-end">

                        <div className="col mb-lg-5">
                        
                            <div className="mb-4 mb-lg-5 ps-lg-5 text-center text-lg-start">

                                <div className="ps-5 pe-5 pb-2 text-light shareandwintitle">
                                    <h1>Share and win <span className="textcolor-lime">points</span></h1>
                                </div>
                                <div className="mt-4 ms-lg-5 pb-lg-5">
                                    <Link to="/signup" className="btn btn-lg btn-dark ps-5 pe-5 pt-3 pb-3">
                                        <span className="fs-4 fw-bold ps-4 pe-4 text-nowrap">SIGN UP NOW</span>
                                    </Link>
                                </div>
                                
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-5">

                            <div className="text-center pt-lg-5 ps-5 pe-5 ps-lg-0 pe-lg-0">
                                <img src={HeroesLeaguePhone} className="w-100 pt-5" alt="heroes league" loading="lazy" />
                            </div>

                        </div>
                        
                    </div>
                </div>
          
            </div>

        </div>
    )

}