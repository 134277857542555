import { useForm, SubmitHandler } from "react-hook-form"
import { Link, useHistory, useParams } from "react-router-dom"
import { useToast } from "../utils/toast"
import useData, { Spinner } from "../utils/data"
import { useEffect, useState } from "react"
import useRecaptcha from "../utils/recaptcha"
import Util from "../utils/util"
import { useTheme } from "../context/theme"
import { Sex } from "../utils/enum"
import { country, response } from "../utils"
import Messages from "../utils/messages"
import { usePopup } from "../utils/popup"
import FootballFriends from '../assets/scss/homeimages/fantastake_friends.jpg'

type Model = {
    name: string,
    email: string,
    password: string,
    currency: string,
    sex: number,
    countryId: number,
    referralCode:string
}

function Signup() {

    const themeContext = useTheme()
    const theme = themeContext.theme

    const recaptcha = useRecaptcha()
    const data = useData()
    const toast = useToast()
    const history = useHistory()
    const util = Util()
    const messages = Messages()
    const { register, handleSubmit, formState: { errors } } = useForm<Model>()
    const emailPattern = util.emailRegex()
    const dashboard = process.env.REACT_APP_DASHBOARD_PATH ? process.env.REACT_APP_DASHBOARD_PATH : "#"
    const token = data.getCookie('token')
    const passwordRegexp = util.passwordRegex()
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [countries, setCountries] = useState<country[]>([])
    const [isSubscribed, setIsSubscribed] = useState(true)
    const { refer } = useParams<any>()
    const popup = usePopup()

    const onSubmit: SubmitHandler<Model> = model => {

        model.sex = parseInt(model.sex.toString())
        model.countryId = parseInt(model.countryId.toString())
        if(refer){
            model.referralCode = refer
        }
        

        if(recaptcha.isRecaptchaLoaded() === false) return false;

        let grecaptcha = window.grecaptcha;

        grecaptcha.ready(()=>{
            grecaptcha.execute('6LfgNDAgAAAAAJPJTlPj01d7qFBGOMrAA1otgrAu', { action: 'signup' }).then((recaptchaToken:any)=>{

                // Verify recaptcha

                const recaptchaVerifyUrl = process.env.REACT_APP_API_VERIFYRECAPTCHA

                data.post(recaptchaVerifyUrl, { response : recaptchaToken }, (resp:any)=>{
                    if(resp.errors){
                        const key = Object.keys(resp.errors).filter((key, index) => index === 0)[0]
                        let error = resp.errors[key];
                        console.log('Something went wrong with captcha verification - ', error)
                    }else if(resp.data?.success){

                        // Permit signup

                        data.post(process.env.REACT_APP_API_SIGNUP, model, (resp: any) => {
                            if (resp.data > 0) {
                                // Redirect to dashboard
                                toast.show(messages.activationRequired, 'success')
                                history.push('/login', [{ isEmailVerified: false}])
                            } else if (resp.errors) {
                                const key = Object.keys(resp.errors).filter((key, index) => index === 0)[0]
                                //toast.show(resp.errors[key], 'error')
                                let message = resp.errors[key]
                                popup.show(message, 'Sign up failed', { okayButton : { text : 'Okay' }})                                
                            }
                
                        }, (result: any) => {
                            if (result) {
                                toast.show(result.message)
                            }
                        })

                    }
                })

            })
        })        
    }

    const fetchCountries = () => {
        const url = process.env.REACT_APP_API_COUNTRIES
        data.get(url, (resp: response<any>) => {
            let countries = resp?.data
            if(countries && isSubscribed){
                setCountries(countries)
            }
        }, () => { })
    }

    useEffect(()=>{

        // fetch countries
        if(countries.length === 0){
            fetchCountries()
        }        

        return () => {            
            // unsubscribe to prevent updating state if unmounted          
            setIsSubscribed(false)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        document.title = process.env.REACT_APP_WEBSITE_TITLE + util.titleSeparator() + "Signup"
    })

    return (
        <>

            <div style={{height:250}} className="d-flex align-items-end rounded-bottom rounded-5 mb-5 beginchallenge d-md-none">
                <div className="border border-0 w-100" style={{height:'45%', backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(14,12,14,0.8), rgba(14,12,14,1))'}}></div>
            </div>

            <div className="container-fluid">

                <div className="row mb-5">

                    <div className="col-md-6 d-none d-md-flex">

                        <div className="border border-0 rounded-4 flex-fill m-3" style={{backgroundImage:`url(${FootballFriends})`, backgroundSize:'cover', backgroundPosition:'center'}}>
                        </div>

                    </div>

                    <div className="col-md-6 p-md-5 pb-md-0 border border-0">
                        
                        <div className="p-md-5 pb-md-0">

                            <h1 className={"mb-5 text-light " + theme.color}>Sign up</h1>
                            <p className="mb-4">
                                Let's get you started
                            </p>{/*, beginning with some basic info.*/}
                            <form className="pb-5" onSubmit={handleSubmit(onSubmit)}>
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input autoFocus={true} defaultValue="" type="text" {...register("name", {
                                        required: 'Name is required'
                                    })} maxLength={150} className={"form-control form-control-lg " + theme.background + ' ' + theme.color} id="name" placeholder="Enter firstname and surname" />
                                    {errors.name && <small className="text-danger">{errors.name.message}</small>}
                                </div>
                                <div className="mb-3">

                                    <div className="row g-2">
                                        <div className="col-auto">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" defaultValue={Sex.Female} {...register("sex", { required: 'Sex is required' })} id="femaleSex" />
                                                <label className="form-check-label" htmlFor="femaleSex">
                                                    Female
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" defaultValue={Sex.Male} {...register("sex", { required: 'Sex is required' })}  id="maleSex" />
                                                <label className="form-check-label" htmlFor="maleSex">
                                                    Male
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div>{errors.sex && <small className="text-danger">{errors.sex.message}</small>}</div>                    
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input defaultValue="" type="email" {...register("email", {
                                        required: 'Email is required',
                                        pattern: { value: emailPattern, message: 'Enter a valid email address' }
                                    })} maxLength={100} className={"form-control form-control-lg " + theme.background + ' ' + theme.color} id="email" placeholder="Enter your email" />
                                    {errors.email && <small className="text-danger">{errors.email.message}</small>}
                                    <div id="passwordHelpInline" className="form-text fs-6 lh-1">
                                        You’ll receive an email to verify it before you can login.
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <div className="input-group">
                                        <input defaultValue=""  type={passwordVisible ? 'text' : 'password'} {...register("password", {
                                            required: 'Password is required', minLength: { value: 8, message: 'Password must be at least 8 characters' },
                                            pattern: { value: passwordRegexp, message: 'Password must contain at least one uppercase, lowercase, number and special character.' }
                                        })} maxLength={100} className={"form-control form-control-lg border border-end-0 " + theme.background + ' ' + theme.color} id="password" placeholder="Choose a password" />
                                        <label className={"input-group-text border border-start-0 " + theme.background} htmlFor="password">
                                            <Link to="#" onClick={()=>setPasswordVisible(!passwordVisible)}>{passwordVisible ? <i className={"bi bi-eye " + theme.color} aria-hidden="true" /> : <i className={"bi bi-eye-slash " + theme.color} aria-hidden="true" />}</Link>
                                        </label>                            
                                    </div>                    
                                    {errors.password && <small className="text-danger">{errors.password.message}</small>}
                                </div>

                                <div className="mb-5">
                                    <label htmlFor="countryId" className="form-label">Country</label>
                                    <select defaultValue="" id="countryId" {...register("countryId", { 
                                            required : "Country is required",
                                        })} className={"form-select form-select-lg " + theme.background + ' ' + theme.color} aria-label="Select your country">
                                        <option value="">- Country of residence -</option>
                                        {
                                            countries.map((country, index) => {
                                                return <option value={country.id} key={index}>{country.name}</option>
                                            })
                                        }
                                    </select>
                                    {errors.countryId && <small className="text-danger">{errors.countryId.message}</small>}
                                </div>

                                <p className="mb-5 lh-1">
                                    By signing up, I confirm that I have read and agree to the terms and conditions.
                                </p>
                                
                                <div className="d-grid gap-2 mb-5">
                                    <button type="submit" className="btn btn-lg btn-primary" disabled={data.isBusy()}>
                                        Sign up <Spinner visibility={data.isBusy()} isCentered={false} />
                                    </button>
                                </div>

                                <p className="mb-2 text-center">
                                    Already a member? {
                                        token && token !== '' ? 
                                            <Link to={dashboard}>Start a banter.</Link> 
                                            : <Link to="/login">Login</Link>
                                    }
                                </p>

                                <div className="mb-5 text-center">
                                    I <Link to="/forgot">forgot</Link> my password
                                </div>

                            </form>
                            
                        </div>

                    </div>

                </div>                            
                
            </div>
        </>
    )
}

export default Signup