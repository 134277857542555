import useData from "./data"
import { FanActivity } from "./enum"
import { activityModel, analyticsService, response } from "."
import { useToast } from "./toast"
import { useEffect, useState } from "react"

export default function useAnalytics() : analyticsService {
    
    const data = useData()
    const toast = useToast()
    const [isSubscribed, setIsSubscribed] = useState(true)    

    const banterView = (banterId: number, callback?: Function) => {

        if (banterId > 0) {
            const url = process.env.REACT_APP_API_ANALYTICSBANTERVIEW + '?banterid=' + banterId  
            /* istanbul ignore next */ 
            const postCallback = (resp: response<any>) => { 
                if (resp && callback) callback() 
            }                        
            data.post(url, {}, postCallback)
            return true
        }
    }

    const banterAction = (banterId: number, fanActivity: FanActivity, callback?:Function) => {
        if (banterId > 0 && fanActivity) {
            const url = process.env.REACT_APP_API_BANTERACTION
            let model:activityModel = { banterId : banterId, activity : fanActivity }
            
            data.post(url, model, (resp: response<any>) => {
                /* istanbul ignore next */
                if (resp && resp.errors) {
                    if (resp.errors.message) toast.show(resp.errors.message[0], 'error')
                } else if (resp.message) {
                    //toast.show(resp.message, 'success')
                    if(callback) callback()
                }
            })
            return true
        }
    }

    const awardPoints = (fanActivity: FanActivity, callback?: Function)=>{
        if(fanActivity){
            const url = process.env.REACT_APP_API_USERPOINTS
            let model: activityModel = {activity : fanActivity }
            isSubscribed && data.post(url, model, (resp: response<any>) => {
                /* istanbul ignore next */
                if(callback) callback()
                /* istanbul ignore next */
                if (resp && resp.errors) {
                    if (resp.errors.message) toast.show(resp.errors.message[0], 'error')
                } else if (resp.message) {
                    toast.show(resp.message, 'success')
                }
            })
            return true
        }
    }

    let analytics =  {
        banterView: banterView,
        banterAction: banterAction,
        awardPoints: awardPoints,
    }

    useEffect(() => {

        return () => {            
            // unsubscribe to prevent updating state if unmounted          
            setIsSubscribed(false)
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return analytics
}