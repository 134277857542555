import { bantter } from "../utils";
import SocialBanter from "./socialbanter";

export default function BanterGroup(props:any){

    const banters: bantter[] = (props?.banters) ? props.banters : []
    const handleBanterClick = props?.handleBanterClick
    const removeBanter = props?.removeBanter

    //console.log('remove banter banter group', removeBanter)
    //console.log('handleBanterClick', handleBanterClick)
    const handleShare = props?.handleShare
    const banterPosted = props?.banterPosted
    const setBanterPosted = props?.setBanterPosted
    const showNextArrow = props.showNextArrow
    const showCount = props?.showCount ?? banters.length

    let showBanters = []
    for(var i = 0; i < showCount; i++){
        showBanters.push(banters[i])
    }

    return (
        <>            
            {   banters.length > 0 &&
                <div className="list-group">
                    {
                        showBanters.map((banter, index) => 
                            <SocialBanter banter={banter}
                                handleBanterClick={handleBanterClick} 
                                handleShare={handleShare}
                                banterPosted={banterPosted}
                                setBanterPosted={setBanterPosted}
                                showNextArrow={showNextArrow}
                                removeBanter={removeBanter}
                                index={index}
                                key={index} />)
                    }
                </div>
            }
        </>
    )
}