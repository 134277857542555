import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import useData, { Spinner } from "../utils/data";
import { favTeam, league, response, team } from "../utils";
import { useTheme } from "../context/theme";
import { useAuth } from "../context/auth";
import { FaInstagram, FaTwitter } from "react-icons/fa";
import { useToast } from "../utils/toast";
import React from "react";

type favTeamInit = {
    visible : boolean,
    hide : Function,
    show : Function,
}

const init : favTeamInit = {
    visible : false,
    show : ()=>{},
    hide : ()=>{},
}

let ModalContext = React.createContext<favTeamInit>(init)

// Custom hook for favourite team provider

export const useFavTeam = () => {
    return useContext(ModalContext)
}

const useConfig = () : favTeamInit =>{

    const [visible, setVisible] = useState(init.visible)

    init.visible = visible
    init.hide = () => {
        setVisible(false)
    }
    init.show = () => {
        setVisible(true)
    }

    return init
}

export default function FavTeamProvider({children} : any){

    const toast = useToast()
    const themeContext = useTheme()
    const theme = themeContext.theme
    const data = useData()
    const auth = useAuth()
    const authContext = auth.context
    const [isSubscribed, setIsSubscribed] = useState(true)

    const [leagues, setLeagues] = useState<league[]>([]);
    const [teams, setTeams] = useState<team[]>([]);
    const [selectedLeague, setSelectedLeague] = useState<league>()
    const [selectedTeam, setSelectedTeam] = useState<team>()

    const favTeamInit = useConfig()

    const handleTeamClick = (team: team) => {
        if (!team) return
        let favTeam: favTeam = { id: team.id, name: team.name, logo: team.logo }
        data.post(process.env.REACT_APP_API_FAVTEAM, favTeam, (resp: response<any>) => {
            if (resp) {
                
                if (resp.errors) {
                } else if (resp.data) {
                    
                    if (authContext != null) {
                         // update auth context                       
                         auth.updateContext({ ...authContext, favTeam : resp.data })
                         favTeamInit.hide()
                         toast.show('Your team badge has been set.', 'success')
                    }
                }
            }

        })

        setSelectedTeam(team)
    }

    const fetchTeams = (league:league) => {
        if(!league) return
        let leagueId = league.id
        let url = process.env.REACT_APP_API_TEAMS + '?leagueid=' + leagueId
        data.get(url, (resp: response<any>) => {
            if (resp && resp.data) {
                setTeams(resp.data)
            }
        })
    }    

    const fetchLeagues = (callback:Function) => {
        let url = process.env.REACT_APP_API_LEAGUES
        data.get(url, (resp: any) => {
            if (resp && resp.data && isSubscribed) {         
                setLeagues(resp.data)
                if (resp.data.length > 0) {

                    // select the first leaue by default
                    let league = resp.data[0]
                    handleLeagueChange(league)

                    if(callback) {
                        callback(league)
                    }
                }
            }
        })
    }

    const handleLeagueChange = (e: HTMLSelectElement)=>{

        if (!e || e.selectedIndex <= 0) return

        const league = leagues[e.selectedIndex - 1]

        setSelectedLeague(league)
        fetchTeams(league)

    }

    useEffect(() => {

        // fetch leagues and take a callback to fetch teams 
        if(leagues.length === 0) { //&& popup.visible === true
            fetchLeagues((league:league)=>{

                // fetch teams of the selected league

                if (league) { // && popup.visible === true
                    let url = process.env.REACT_APP_API_TEAMS + '?leagueid=' + league.id
                    
                    data.get(url, (resp: response<any>) => {

                        if (resp && resp.data && isSubscribed) {
                           setTeams(resp.data)
                        }
                    })
                }    
            })
        }else{
            if(isSubscribed) {
                setTeams(teams)
            }
        }

        return () => { 
            setIsSubscribed(false)
         }
    }, [favTeamInit.visible]) // eslint-disable-line react-hooks/exhaustive-deps    

    return(
        <ModalContext.Provider value={favTeamInit}>

            { children }

            <Modal show={favTeamInit.visible} contentClassName={theme.background} className="p-2" centered>
                <Modal.Header>
                    <Modal.Title className="p-4">Team</Modal.Title>
                    <CloseButton className={theme.name === 'dark' ? 'btn-close-white' : ''} onClick={()=>favTeamInit?.hide()} />
                </Modal.Header>
                <Modal.Body className="p-5 pt-0">

                    {
                        !selectedTeam && 
                        <div className="mb-4">
                            Set your team badge                            
                        </div>
                    }

                    <div className="row">
                        <div className="col-sm-12 mb-3 mt-0">
                            {
                                selectedTeam && <>
                                    <img src={selectedTeam?.logo} style={{ width: 50, height:50 }} className="mb-3 rounded-circle" alt="team logo" loading="lazy" />
                                    <h4 className="mb-4">
                                        I <i className="bi bi-heart-fill fs-5" aria-hidden="true" /> {selectedTeam?.name}
                                    </h4>
                                </>
                            }
                            <select id="leagueId" defaultValue={selectedLeague?.id} className={"form-select form-select-lg " + theme.background + ' ' + theme.color} onChange={(e) => handleLeagueChange(e.target as HTMLSelectElement)} aria-label="Select a league">
                                <option value={0}>- select teams in -</option>
                                {
                                    leagues.map((league, index) => {
                                        return <option value={league?.id} key={index}>{league?.name}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="text-center" style={{minHeight:'30px'}}>
                            <Spinner visibility={data.isBusy()} />
                        </div>
                        <div className="col-12">
                            {
                                selectedLeague && 
                                <p>
                                    <small>
                                        Don't see your team? Tell us 
                                        <Link to="#" onClick={()=>window.open('https://twitter.com/fantastakeo')} className="ms-2 me-3"> <FaTwitter/></Link>  
                                        <Link to="#" onClick={()=>window.open('https://www.instagram.com/fantastakeo')}> <FaInstagram/></Link>
                                    </small>
                                </p>
                            }
                        </div>
                        <div className="col-12 mb-2">
                            
                            {
                                teams.map((team, index) => {
                                    return (selectedTeam?.id === team.id) ? <Link onClick={() => handleTeamClick(team)} to="#" key={index}>
                                        <span className="badge bg-primary text-light me-1"><img src={team?.logo} style={{ width: 13 }} className="m-0" alt="team logo" loading="lazy" /> {team.name}</span>
                                    </Link> : <Link onClick={() => handleTeamClick(team)} to="#" key={index}>
                                        <span className="badge border border-secondary m-1 text-secondary me-1">
                                            <img src={team?.logo} style={{ width: 13 }} className="m-0" alt="team logo" loading="lazy" /> {team.name}
                                        </span>
                                    </Link>
                                })
                            }
                        </div>
                    </div>

                </Modal.Body>

            </Modal>

        </ModalContext.Provider>

    )
}