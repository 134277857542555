import { ButtonGroup, Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useStartBanter } from "../banter/startbanter2";
import { useTheme } from "../context/theme";
import { Cookie } from "../utils/cookie";
import { useStartContest } from "../cliques/startcontest";

export default function BanterButton(props:any){

  const themeContext = useTheme()
  const theme = themeContext.theme
  const cookie = Cookie()
  const isAuthenticated = cookie.getCookie('token')
  const banterPopup = useStartBanter()
  const banterStarted = banterPopup.banterPosted
  const history = useHistory()
  const startContestPopup = useStartContest()

  return (
    isAuthenticated &&
    <nav className={"fixed-bottom bg-body-tertiary bg-" + theme.name} style={{borderTop:'1px solid #353535'}}>
      <div className="container-fluid">
        <div className="d-grid gap-2 d-flex align-items-center">
          <div className="border border-0">
            <Link to="/league"><i className={"bi bi-trophy ms-4 me-4 " + (banterStarted ? 'text-warning' : '')} style={{fontSize:'2rem'}}></i></Link>
            <Link to="/contests"><i className="bi bi-award ms-4 me-4" style={{fontSize:'2rem'}}></i></Link>
          </div>
          <div className="border border-0 flex-fill d-grid mx-auto">

            <Dropdown
              as={ButtonGroup}
              drop="up-centered"
              variant="transparent"
              title="Banter">
              <Dropdown.Toggle className="btn btn-lg btn-primary rounded-pill ps-4 pe-4 pt-3 pb-3 mb-3 mt-3 fw-bold">
              Banter
              </Dropdown.Toggle>
              <Dropdown.Menu className={"border border-secondary border-bottom-0 " + theme.background}>
                <Dropdown.Item eventKey="3" className={"p-3 ps-4 pe-4 fs-5 " + theme.background}>
                  <span className={"text-light " + theme.color} onClick={() => startContestPopup.show()}>Start a contest</span>
                </Dropdown.Item>                
                <Dropdown.Item eventKey="2" className={"p-3 ps-4 pe-4 fs-5 " + theme.background}>
                  <span className={"text-light " + theme.color} onClick={() => banterPopup.startNewBanter(props.callback)}>Start a banter</span>
                </Dropdown.Item>
                <Dropdown.Item eventKey="1" to="/banters" className={"p-3 ps-4 pe-4 fs-5 " + theme.background}>
                  <span className={"text-light " + theme.color} onClick={()=>history.push('/banters')}>Banters</span>
                </Dropdown.Item>
                
              </Dropdown.Menu>              

            </Dropdown>

          </div>
        </div>
      </div>
    </nav>
  )
}