import { useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useAuth } from "../context/auth";
import { useTheme } from "../context/theme";
import { bantter, userr } from "../utils";
import useAnalytics from "../utils/analytics";
import { ShareChannel } from "../utils/enum";
import Share from "../utils/share";
import { Pluralize } from "../utils/util";
import Banter from "./banter";

export default function PreviewBanter(props: any) {

    const themeContext = useTheme()
    const theme = themeContext.theme

    const auth = useAuth()
    const authContext = auth.context
    //const user = authContext.user as userr
    const user = authContext.getUser() as userr
    
    const banter = {...props.selectedBanter, isPromoted : true} as bantter

    const analytics = useAnalytics();
    const userIsOwner = user?.id === banter.userId

    const [isActed, setIsActed] = useState(userIsOwner)

    const removeBanter = props?.removeBanter

    let closeMessage = "Closed for banters, this match is in progress."
    if(banter.fixture?.status === 1){
        closeMessage = "Closed for banters, this match has ended."
    }

    const handleChallengeClick = ()=>{
        analytics.banterAction(banter.id, 2)
        setIsActed(true)
    }

    const handleSupportClick = ()=>{
        analytics.banterAction(banter.id, 1)
        setIsActed(true)
    }

    const closeModal = (banter:bantter) => {  
        setIsActed(false)
        if (props.setShowPreviewBanter) {
            props.setShowPreviewBanter(false)
            // now remove the banter
            removeBanter(banter)
        }
    }

    return (
        <Modal show={props.showPreviewBanter} onHide={() => closeModal(banter)} contentClassName={theme.background} className="p-2" centered>
            <Modal.Header>
                {
                    banter.fixture &&
                    <Modal.Title className="p-4"></Modal.Title>
                }                
                <CloseButton className={theme.name === 'dark' ? 'btn-close-white' : ''} onClick={()=>closeModal(banter)} />
            </Modal.Header>
            <p className="bg-light text-dark pe-5 ps-5 pb-3 pt-4 mb-0 d-none">Need help? Just ask ZiVA... she may even know which banters will help you earn.</p>
            <div className="bg-light text-dark pe-5 ps-5 pb-4 pt-1 mb-3 d-none">
                <div className="row">
                    <div className="col">
                        <small>Ad by <b>Zenith Bank</b></small>
                    </div>
                    <div className="col text-end">
                        <Link className="badge bg-primary rounded-pill text-light p-2 ps-3 pe-3" to="#">Open Ad</Link>
                    </div>
                </div>
            </div>
            {
                // banter?.endMinutes > 0 && userIsOwner === false &&
                // <div className="ms-5 me-5 mb-4">
                //     Can you take a stand, earn more points and top the Heroes League?
                // </div>
            }
            
            {
                banter.fixture &&
                <div className="ps-5 pe-5">
                    {/* <div>
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="me-2">
                                <div style={{width:50, height:50, backgroundSize:'cover', backgroundRepeat: 'no-repeat', backgroundPosition:'center', backgroundImage: `url(${banter.fixture.homeTeam.logo})`}} className="ms-2 me-2 mb-1"></div>
                            </div>

                            <span>-</span>

                            <div className="ms-2">
                                <div style={{width:50, height:50, backgroundSize:'cover', backgroundRepeat: 'no-repeat', backgroundPosition:'center', backgroundImage: `url(${banter.fixture.awayTeam.logo})`}} className="ms-2 me-2 mb-1"></div>
                            </div>                        
                        </div>
                        <div className="text-center text-warning d-none"><small>{banter.fixture.homeTeam.name} is playing against {banter.fixture.awayTeam.name}.</small></div>
                    </div> */}

                    <div>

                        <div className="d-flex align-items-center mb-1">
                            <div className="me-2" style={{width:50, height:50, backgroundSize:'cover', backgroundRepeat: 'no-repeat', backgroundPosition:'center', backgroundImage: `url(${banter.fixture.homeTeam.logo})`}}></div>
                            <div className="ellipsis">{banter.fixture.homeTeam.name}</div>
                        </div>

                        <div className="d-flex align-items-center mb-1">
                            <div className="me-2" style={{width:50, height:50, backgroundSize:'cover', backgroundRepeat: 'no-repeat', backgroundPosition:'center', backgroundImage: `url(${banter.fixture.awayTeam.logo})`}}></div>
                            <div className="ellipsis">{banter.fixture.awayTeam.name}</div>
                        </div>

                    </div>
                </div>
            }
            <Modal.Body className="p-5 pt-3 pb-3">
                <div className="mb-3 mt-1">
                    {
                        banter && 
                        <div className={"" + theme.background + ' ' + theme.color} aria-current="true">
                            {
                                <Banter banter={banter} isPhraseTruncated={false} hidePhrase={true} />
                            }
                            {
                                <div className="mt-2 mb-2">
                                   
                                    { banter.views === 0 && <span>&nbsp;</span> }
                                    {
                                        banter.views > 0 && 
                                        <div className=""> 
                                            {banter.views} {<Pluralize count={banter.views} plural="views" single="view"/>}
                                        </div>
                                    }                                    
                                    {/* <span>
                                        {
                                            (banter.supports + banter.challenges) > 0 && 
                                            <Link to="#" data-testid="fansViewCount" className={theme.color}>
                                                {(banter.supports + banter.challenges)} {(banter.supports + banter.challenges) === 1 ? 'act' : 'acts'}
                                            </Link>
                                        } 
                                    </span> */}
                                </div>                                
                            }

                        </div>
                    }
                </div>
                <div className="mb-4 text-center">
                    {
                        banter?.endMinutes > 0 && isActed === false && userIsOwner === false &&
                        <div className="mt-3 d-none">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p>Challenge</p>
                                    <Link to="#" onClick={(e) => handleChallengeClick()} className={'p-3 ms-5 me-5 fs-2 ' + theme.background}>
                                        <FaRegThumbsDown className="text-primary" />
                                    </Link>
                                </div>
                                <div>
                                    <p>Support</p>
                                    <Link to="#" onClick={(e) => handleSupportClick()} className={'p-3 ms-5 me-5 fs-2 '  + theme.background}>
                                        <FaRegThumbsUp className="text-primary" />
                                    </Link>
                                </div>                                
                            </div>                                                       
                        </div>
                    }
                    {
                        banter?.endMinutes <= 0 && 
                        <div className="mt-3">
                            <div>{closeMessage}</div>
                        </div>
                    }
                    {
                        (isActed || userIsOwner) &&
                        <div className="mt-3 fade-in" onClick={() => closeModal(banter)}>
                            
                            <div className="d-flex justify-content-center">
                                <div>
                                    <Link to="#" onClick={(e) => setIsActed(false)} className={'ps-4 pe-4 fs-2 ' + theme.background}>
                                        <Share banter={banter} channel={ShareChannel.Whatsapp} />
                                    </Link>
                                </div>
                                <div>
                                    
                                    <Link to="#" onClick={(e) => setIsActed(false)} className={'ps-4 pe-4 fs-2 '  + theme.background}>
                                        <Share banter={banter} channel={ShareChannel.Twitter} />
                                    </Link>
                                </div>                                
                            </div>
                            <p className="lh-1 mt-4 fs-5">Share the excitement</p>
                        </div>
                    }                    
                </div>
            </Modal.Body>
        </Modal>
    )

}