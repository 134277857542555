import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Share from '../utils/share'
import useData from '../utils/data'
import { ShareChannel } from '../utils/enum'
import Settings from './settings'
import { useAuth } from '../context/auth'
import { useTheme } from '../context/theme'
import FantastakeLogo from '../assets/logos/fantastake_logo_text_light.png'
import PartnerMTN from '../assets/scss/homeimages/partnermtn.png'
import PartnerAirtel from '../assets/scss/homeimages/partnerairtel.png'
import MasterCard from '../assets/mastercard_color.png'
import VisaCard from '../assets/visa_color.png'

function Footer() {
    const themeContext = useTheme()
    const theme = themeContext.theme
    const auth = useAuth()
    const data = useData()
    const history = useHistory()
    const isAuthenticated = data.getCookie('token')
    const dashboard = process.env.REACT_APP_DASHBOARD_PATH ? process.env.REACT_APP_DASHBOARD_PATH : "#"
    const [showSettings, setShowSettings] = useState(false)

    return (
        <>
            <div className="pt-5 redeempartners teamlogostrip-dark d-none">

                <div className="container">
                    <div className="row">
                        <div className="col-12 p-3 p-lg-5">
                            <h1 className="text-center pe-lg-5 text-light">
                                <div className="ps-2 pe-2 fs-2">
                                    Fulfilment Partners
                                </div>
                            </h1>                            
                        </div>
                    </div>

                    <div className="row text-center">
                        <div className="col-md-6 text-lg-end">
                            <img src={PartnerMTN} alt="MTN is a fulfilment partner on fantastake" />
                        </div>
                        <div className="col-md-6 text-lg-start">
                            <img src={PartnerAirtel} alt="Airtel is a fulfilment partner on fantastake" />
                        </div>                    
                    </div>

                </div>                

            </div>

            <div className="pb-5 footer ms-0 p-3 pt-5 p-md-5">
                
                <div className="mb-4 ps-3">
                    <img className="fantastakelogo" src={FantastakeLogo} alt="Fantastake logo" loading="lazy" />
                </div>
                <div className="pt-4">                      

                    <div className="d-flex fs-5 lh-1">
                        <ul className="list-group list-group-horizontal-md">
                            <li className="text-light list-group-item border-0 d-none">{isAuthenticated ? <Link to={dashboard}>Activity</Link> : <Link to="/home/">Home</Link>}</li>
                            <li className="text-light list-group-item border-0"><Link className="text-light" to="/banters">Banters</Link></li>
                            <li className="text-light list-group-item border-0"><Link className="text-light" to="/contests">Contests</Link></li>
                            <li className="text-light list-group-item border-0"><Link className="text-light" to="/league">Heroes League</Link></li>
                            <li className="text-light list-group-item border-0 d-none"><Link className="text-light" to="/store">Store</Link></li>
                            { isAuthenticated && <li className="text-light list-group-item border-0 d-none"><Link to="/fund">Wallet</Link></li> }
                            { isAuthenticated && <li className="text-light list-group-item border-0"><Link className="text-light" to="/profile">Profile</Link></li> }
                            { isAuthenticated && <li className="text-light list-group-item border-0"><Link className="text-light" to="#" onClick={()=>setShowSettings(true)}>Settings</Link></li> }
                            <li className="text-light list-group-item border-0"><Link className="text-light" to="/terms/">Terms</Link></li>
                            { !isAuthenticated && <li className="text-light list-group-item border-0"><Link className="text-light" to="/signup">Sign up</Link></li> }
                            <li className="text-light list-group-item border-0">{isAuthenticated ? <Link className="text-light" to="#" onClick={() => auth.logout(()=>history.push('/login'))}>Log out </Link> : <Link className="text-light" to="/login">Login</Link> }</li>
                        </ul>
                    </div>

                </div>

                {/* <div className="d-flex ps-3 pe-3 mt-5 fs-3">
                    <div className="pe-3"><Link className="text-light" to="#" onClick={()=>window.open('https://www.instagram.com/fantastakeo')}><i className="bi bi-instagram"></i></Link> </div>
                    <div className="pe-3"><Link className="text-light" to="#" onClick={()=>window.open('https://twitter.com/fantastakeo')}><i className="bi bi-twitter"></i></Link> </div>                            
                    <div className="pe-3"><Link className="text-light" to="#" ><Share placeChildrenRight={false} channel={ShareChannel.Whatsapp} showShareIcon={false}><i className="bi bi-whatsapp"></i></Share></Link></div>
                </div> */}

                <div className="d-flex fs-5">
                    <ul className="list-group list-group-horizontal-md">
                        <li className="text-light list-group-item border-0 d-none">Get in touch</li>
                        <li className="text-light list-group-item border-0 mt-4">
                            <div className="d-flex d-grid gap-4 h-75 fs-3">
                                <div><Link className="text-light" to="#" onClick={()=>window.open('https://www.instagram.com/fantastakeo')}><i className="bi bi-instagram"></i></Link> </div>
                                <div><Link className="text-light" to="#" onClick={()=>window.open('https://twitter.com/fantastakeo')}><i className="bi bi-twitter"></i></Link> </div>
                                <div><Link className="text-light" to="#" ><Share placeChildrenRight={false} channel={ShareChannel.Whatsapp} showShareIcon={false}><i className="bi bi-whatsapp"></i></Share></Link></div>
                            </div>                                
                        </li>
                    </ul>
                </div>

                <div className="d-flex fs-5 lh-1">
                    <ul className="list-group list-group-horizontal-md">
                        <li className="text-light list-group-item border-0 mt-4">
                            <div className="d-flex d-grid gap-4 h-75">
                                <img src={MasterCard} alt="Pay with Mastercard" />
                                <img src={VisaCard} alt="Pay with Visa" />
                            </div>                                
                        </li>
                    </ul>
                </div>                

                <div className="mt-4 text-light">
                    <div className="mb-4 d-none">
                        <small onClick={()=>themeContext.toggleTheme()}>
                            <Link to="#" className="text-light"><i className={"fs-5 me-1 " + (theme.name === 'light' ? 'bi bi-moon-fill' : 'bi bi-moon')} /> <span>{theme.name === 'light' ? 'Dark' : 'Light'}</span></Link>
                        </small>
                    </div>
                    <div className="d-flex mb-5 lh-1">
                        <ul className="list-group list-group-horizontal-md mb-3 fs-6">
                            <li className="text-light list-group-item border-0">Copyright &copy; {new Date().getFullYear()}</li>
                            <li className="text-light list-group-item border-0">All rights reserved by Capehi</li>
                        </ul>
                    </div>
                    
                </div>
            </div>
            
            <Settings showSettings={showSettings} setShowSettings={setShowSettings} />
        
        </>
        
    )

}

export default Footer