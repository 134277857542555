import React, { useContext, useEffect, useState } from "react"
import { ButtonGroup, CloseButton, Dropdown, InputGroup, Modal } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import { useAuth } from "../context/auth"
import { useTheme } from "../context/theme"
import { useFavTeam } from "../home/favteam"
import { banterOption, bantter, brag, fixture, league, option, reach, risk } from "../utils"
import useData, { Spinner } from "../utils/data"
import { usePopup } from "../utils/popup"
import ConfirmBanter from "./confirmbanter"
import Util from "../utils/util"

type startBanterInit ={
    visible : boolean,
    hide: Function,
    show: Function,
    recentBanters : bantter[],
    updateRecentBanters: Function,
    startNewBanter: Function,
    banterPosted: boolean,
    setBanterPosted: Function,
} 

const init : startBanterInit = {
    visible : false,
    banterPosted : false,
    hide: () => {},
    show: () => {},
    recentBanters: [],
    updateRecentBanters: (recentBanters: bantter) => {},
    startNewBanter : ()=>{},
    setBanterPosted : ()=>{}
}

const ModalContext = React.createContext(init)

// Custom hook for modal
export const useStartBanter = () => {
    return useContext((ModalContext))
}

const useModal = (startNewBanter: Function) => {
    const [visible, setVisible] = useState(init.visible)
    const [recentBanters, setRecentBanters] = useState<bantter[]>([])
    const [banterPosted, setBanterPosted] = useState(false)
    const pageSize = 2

    init.visible = visible
    init.recentBanters = recentBanters
    init.banterPosted = banterPosted
    init.setBanterPosted = setBanterPosted
    init.hide = () => {
        setVisible(false)
    }

    init.show = () => {   
        setVisible(true)
    }

    init.updateRecentBanters = (item: bantter)=>{

        if (!item) return

        let newRecentBanters = [item]
        for(let i = 0; i < recentBanters.length; i++){
            newRecentBanters.push(recentBanters[i])
            if(newRecentBanters.length === pageSize) break;
        }

        setRecentBanters(newRecentBanters)
    }

    init.startNewBanter = startNewBanter

    return init;

}

export default function StartBanterProvider({children} : any){

    const themeContext = useTheme()
    const theme = themeContext.theme
    const util = Util()

    const data = useData()
    const isBusy = data.isBusy();
    const auth = useAuth()
    const authContext = auth.context
    const favTeam = authContext.favTeam
    let user = authContext.getUser()
    const history = useHistory()
    const popup = usePopup()
    const favTeamPop = useFavTeam()    

    const [leagues, setLeagues] = useState<league[]>([]);
    const [fetchingLeagues, setFetchingLeagues] = useState(true)
    const [selectedLeague, setSelectedLeague] = useState<league>()
    const [fixtures, setFixtures] = useState<fixture[]>([])
    const [selectedFixture, setSelectedFixture] = useState<fixture>()
    const [selectedOption, setSelectedOption] = useState<banterOption>()
    const [reaches, setReaches] = useState<reach[]>([])
    const [selectedReach, setSelectedReach] = useState<reach>()
    const [selectedBrag, setSelectedBrag] = useState<brag>()
    const [isSubscribed, setIsSubscribed] = useState(true)
    const [selectedFixtureRisks, setSelectedFixtureRisks] = useState<risk[]>(selectedFixture?.risks ?? [])
    const [selectedOptionBand, setSelectedOptionBand] = useState(999)
    const [showConfirmBanter, setShowConfirmBanter] = useState(false)
    const [composedBanter, setComposedBanter] = useState<bantter>()
    
    const handleStartNewBanter = () =>{

        // if user email is not verified,
        // redirect to verify email

        if(!user?.profile?.isEmailVerified){

            popup.show('Verify or update your email to start a banter.', 'Verification required')
            history.push('/profile')
            return false

        }else if(!favTeam && favTeamPop){

            // User must have set a fav team
            favTeamPop.show()
            return false
        }

        startBanter.show()

        return true
    }

    const startBanter = useModal(handleStartNewBanter)
    

    const handleFixtureChange = (fixture:fixture) => {

        clearSelectedFixture()
        if (!fixture) {
            return
        }

        // Evaluate fixture friendly date
        const matchDate = new Date(fixture.date)
        const matchDateNoYear = matchDate.toDateString().replace(matchDate.getFullYear().toString(), '').trimEnd()
        const matchTime = matchDate.toLocaleTimeString()
        const matchTimeNoSec = matchTime.replace(':00 PM', ' PM')
        fixture.friendlyDate = matchDateNoYear + ', ' + matchTimeNoSec

        setSelectedFixture(fixture)        

        setSelectedFixtureRisks(fixture.risks ?? [])
    }

    // const getMatchDate = (fixtureDate: any) =>{
    //     const matchDate = new Date(fixtureDate)
    //     let matchDateNoYear = matchDate.toDateString().replace(matchDate.getFullYear().toString(), '').trimEnd()
    //     matchDateNoYear = matchDateNoYear.replace(' ', ', ')
    //     return matchDateNoYear
    // }

    const prepareBanter = (brag: brag, fixture: fixture, option: banterOption, reach: reach): bantter | undefined => {

        if (!brag || !option || !reach || !fixture) return undefined

        // Pre phrase improvements

        const bragName = (brag.name === 'None') ? 'No' : brag.name
        let optionName = (brag.homeGoals === 1 || brag.awayGoals === 1 || brag.gameGoals === 1) ? option.name.replace(new RegExp('goals', 'gi'), 'goal',) : option.name
        optionName = optionName.toLowerCase()

        // Further improve phrase by replacing home or away with team name
        if(optionName.substring(0, 4) === 'home') optionName = optionName.replace(/home/, fixture?.homeTeam.name)
        if(optionName.substring(0, 4) === 'away') optionName = optionName.replace(/away/, fixture?.awayTeam.name)

        const banterPhrase = bragName + ' ' + optionName + ' at ' + reach.name.toLowerCase()
        const banter = {
            name: fixture.homeTeam.name + ' - ' + fixture.awayTeam.name,
            fixtureId: fixture.id,
            fixture: fixture,
            phrase: banterPhrase,
            reachId: reach.id,
            optionId: option.id,
            bragId: brag.id,
            tags: reach?.name + ', ' + fixture?.homeTeam.name + ', ' + fixture?.awayTeam.name,
            isPromoted: false,
            views:0,
            supports: 0,
            challenges:0,
            owner:'You'
        } as bantter
        return banter;
    }

    const handleBragClick = (bragId: number) => {
        if (!bragId || !selectedFixture || !selectedOption || !selectedReach) return

        // get the brag using brag id
        let brag = selectedOption.brags.filter(b=>b.id === bragId)[0]
        if(!brag) {
            return
        }

        setSelectedBrag(brag)

        // update banters list
        const banter = prepareBanter(brag, selectedFixture, selectedOption, selectedReach)

        // pass banter up for confirmation
        //if (startBanter.setComposedBanter) {
            setComposedBanter(banter)
            setShowConfirmBanter(true)

            // close new banter modal

            startBanter.hide()
        //}
    }

    const clearSelectedFixture = () => {
        // clear selected fixture
        setSelectedFixture(undefined)

        // clear selected reach and reset dropdown
        handleChangeReach(0)
        const reachSelect = document.getElementById("reach") as HTMLSelectElement
        if (reachSelect) reachSelect.selectedIndex = 0

        // clear selected option
        setSelectedOption(undefined)
    }

    const handleOptionClick = (clickedOption: option) => {
        setSelectedBrag(undefined)
        setSelectedOption(clickedOption)

        // determine option band
        let optionRisks = selectedFixtureRisks.filter(r => r.optionId === clickedOption.id).sort((a, b)=>{
            if(a.odds < b.odds) return -1;
            return 1;
        })
        let lowerBand = optionRisks[0]
        let upperBand = optionRisks[optionRisks.length - 1]

        if(lowerBand && upperBand){
            let band = (upperBand.odds - lowerBand.odds) / 3
            setSelectedOptionBand(band)
        }        
    }

    const handleLeagueClick = (league: league) => {
        if (!league) { 
            return 
        }

        if(league.id === selectedLeague?.id) { 
            return 
        }

        // clear selected fixture and brag
        clearSelectedFixture()

        setSelectedLeague(league)

        // fetch fixtures
        fetchFixtures(league.id)        
    }

    const fetchFixtures = (leagueId: number, pageSize:number = 10, callback:any = undefined) => {
        //if league id is zero return any other upcoming fixtures
        setFixtures([])
        setFetchingLeagues(true)
        data.get(process.env.REACT_APP_API_FIXTURES + '?leagueid=' + leagueId + '&pagesize='+ pageSize, (resp: any) => {
            setFetchingLeagues(false)
            if (resp && resp.data) {
                
                let fixtures = resp.data
                setFixtures(fixtures)

                // select the first fixture
                setSelectedFixture(fixtures[0])

                // set selected fixture risks
                if(fixtures[0]){
                    setSelectedFixtureRisks(fixtures[0].risks ?? [])
                }                

                if(callback) callback(fixtures)
            }
            
        }, () => setFetchingLeagues(false))
    }

    const handleChangeReach = (index: number) => {
        setSelectedReach(undefined)
        if (reaches.length === 0) return
        if (index >= 0) {
            setSelectedReach(reaches[index])
        }
    }

    const fetchLeagues = (callback: Function) => {
        data.get(process.env.REACT_APP_API_LEAGUES + '?isactive=true', (resp: any) => {
            if (resp && resp.data) {
                if(isSubscribed) {
                    let leagues = resp.data
                    setLeagues(leagues)
                    setSelectedLeague(resp.data[0])

                    if(callback){
                        callback(leagues)
                    }
                }                        
            }
        })
    }

    const fetchReaches = ()=>{
        data.get(process.env.REACT_APP_API_REACHES, (resp: any) => {
            if (resp && resp.data) {
                if(isSubscribed) {
                    setReaches(resp.data)
                    if(resp.data[0]) {
                        setSelectedReach(resp.data[0])                       
                    }
                }                        
            }
        })
    }

    const chooseDefaultFixture = (leagues:league[], fixtures: fixture[]) =>{
        let firstFixture = fixtures[0]
        
        // if leagueId is zero, then this is the first run, therefore;
        // 1. select the league of the first of the returned fixtures
        // 2. then select that first fixture as well

        if(firstFixture){
            let league = leagues.filter(l=>l.id === firstFixture.leagueId)[0]
            if(league) setSelectedLeague(league)

            setSelectedFixture(firstFixture)
        }
    }

    useEffect(() => {

        // clear any previous selections for a fresh start always
        if(selectedFixture) clearSelectedFixture()

        // preselect the first fixture        
        setSelectedFixture(fixtures[0])

        // fetch leagues
        if(leagues.length === 0) {
            fetchLeagues((leagues:league[])=>{
                
                // fetch fixtures for first time only after league have been fetched
                if(fixtures.length === 0) {
                    fetchFixtures(0, undefined, (fixturesResult:fixture[])=>{
                        chooseDefaultFixture(leagues, fixturesResult)
                    })
                }
            })
        }

        // fetch reaches
        if (reaches.length === 0 && startBanter.visible === true) fetchReaches()
        
        // eslint-disable-next-line
        return () => {  
            // unsubscribe to prevent updating state if unmounted          
            setIsSubscribed(false)
        }
    }, [startBanter.visible]) // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <ModalContext.Provider value={startBanter}>

            { children }

            <Modal show={startBanter.visible} onHide={() => { clearSelectedFixture(); startBanter.hide()}} contentClassName={"border border-secondary " + theme.background} className="p-2" centered>
                <Modal.Header>
                    <Modal.Title className="p-4 d-none">Start a banter</Modal.Title>
                    <CloseButton className={theme.name === 'dark' ? 'btn-close-white' : ''} onClick={()=>startBanter.hide()} />
                </Modal.Header>
                <Modal.Body className="ps-5 pe-5 pb-4 pt-0">
                    <p className="mb-2 lh-1">
                        Start a banter and share it with friends.
                    </p>
                    <div className="row">
                        <div className="col-12 mb-2 mt-3">
                            
                            <Spinner visibility={fetchingLeagues && data.isBusy()} />

                            {
                                data.isBusy() === false && leagues?.length !== 1 &&
                                <label htmlFor="leagueId" className="form-label">{leagues?.length > 1 ? leagues.length + ' leagues' : (leagues.length === 0) ? 'No active league' : ''}</label>
                            }

                            {
                                leagues && leagues.length > 0 &&
                                <Dropdown className={fetchingLeagues ? 'd-none' : ''} style={{ width: '100%'  }}>                            
                                    
                                    <Dropdown.Toggle variant="transparent text-secondary border border-secondary d-flex align-items-center" id="leagueId" style={{ width: '100%'  }} size="lg" className={"text-start ps-0 pb-0 pt-0 text-truncate " + theme.background + ' ' + theme.color} aria-expanded="false">
                                        
                                        <div className="flex-fill ellipsis">
                                            {selectedLeague && <img className="bg-white m-1  me-2" loading="lazy" style={{ height: 45 }} src={selectedLeague?.logo} alt="league logo" arial-label={selectedLeague?.name} />}
                                            {selectedLeague?.name}
                                        </div>
                                        
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className={"border border-secondary " + theme.background}>
                                        {
                                            leagues?.map((league: league, index) => {
                                                return <Dropdown.Item style={{whiteSpace:'break-spaces'}} className={"fs-5 pt-3 pb-2 " + theme.color} href="#/action-1" onClick={() => handleLeagueClick(league)} key={index}>
                                                    {league?.name}
                                                </Dropdown.Item>
                                            })
                                        }
                                    </Dropdown.Menu>
                                </Dropdown> 
                            }                        

                        </div>

                        <div className="col-sm-12 mb-3 mt-3 lh-1">
                            {
                                data.isBusy() === false &&
                                <label htmlFor="fixtureId" className="form-label">{fixtures?.length > 1 ? fixtures.length + ' upcoming matches' : (fixtures.length === 0) ? 'No upcoming matches this week.' : '1 upcoming match'}</label>

                            }
                            
                            {
                                fixtures && fixtures.length > 0 &&
                                // <select id="fixtureId" defaultValue={selectedFixture?.fixtureId} className={"form-select form-select-lg " + theme.background + ' ' + theme.color} onChange={(e) => handleFixtureChange(e.target as HTMLSelectElement)} aria-label="Select a match">
                                //     {/* <option value={0}>- {fixtures?.length > 1 ? fixtures.length + ' upcoming matches' : fixtures.length === 0 ? 'no upcoming matches' : '1 upcoming match'} -</option> */}
                                //     {
                                //         fixtures.map((fixture, index) => {
                                //             return <option value={fixture?.fixtureId} key={index}>{fixture?.homeTeam?.name} - {fixture?.awayTeam?.name} &bull; {getMatchDate(fixture.startDate)}</option>
                                //         })
                                //     }
                                // </select>
                                <InputGroup size="lg">
                                    <Dropdown as={ButtonGroup}>
                                        <Dropdown.Toggle className={theme.background + " border border-secondary text-start d-flex align-items-center"} style={{width:'100%'}}>
                                            
                                            <div className={"flex-fill text-secondary " + theme.color }>
                                                {
                                                    selectedFixture ?
                                                    <div>
                                                        <div>
                                                            <img loading="lazy" className="rounded-circle" style={{ width: 20, height:20 }} src={selectedFixture.homeTeam?.logo} alt="" /> {selectedFixture.homeTeam?.name}
                                                        </div>   
                                                        <div>
                                                            <img loading="lazy" className="rounded-circle" style={{ width: 20, height:20 }} src={selectedFixture.awayTeam?.logo} alt="" /> {selectedFixture.awayTeam?.name}
                                                        </div>
                                                        <div className={"lh-1 fs-6 pt-2 " + theme.color} style={{whiteSpace:'break-spaces'}}><i className="bi bi-clock"></i> { util.getFriendlyFixtureDate(selectedFixture.startDate) } </div>                                             
                                                    </div> : 
                                                    <div>
                                                        <div className="text-secondary">Choose a banter</div>
                                                    </div>                                           
                                                }
                                                
                                            </div>
                                            
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className={"border border-secondary " + theme.background} style={{maxHeight:250, overflow:'scroll'}}>
                                            {
                                                fixtures.map((fixture:fixture, index)=>{
                                                    return <Dropdown.Item onClick={() => handleFixtureChange(fixture)} className="pt-3 pb-2" key={index}>
                                                            <div>{fixture.homeTeam?.name} - {fixture.awayTeam?.name}</div>
                                                            <div>{util.getFriendlyFixtureDate(fixture.startDate)}</div>                                                    
                                                        </Dropdown.Item>
                                                })
                                            }                                    
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </InputGroup>
                            }
                        </div>

                        {
                            selectedFixture && 
                            <div>
                                <div className="col-sm-12 mb-3 d-none">
                                    <label htmlFor="fixtureId" className="form-label">Period</label>
                                    {/* <label className="form-label">{selectedReach && selectedReach.name} banter</label> */}
                                    <select id="reach" 
                                            defaultValue={selectedReach?.id} 
                                            className={"form-select form-select-lg " + theme.background + ' ' + theme.color} 
                                            aria-label="Select a match period for your banter" 
                                            onChange={(e) => handleChangeReach((e.target as HTMLSelectElement).selectedIndex)}>
                                        {
                                            reaches.map((reach: reach, index: number) => {
                                                return <option value={reach.id.toString()} key={index}>{reach.name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                {
                                    <div className="col-sm-12">

                                        <Spinner visibility={isBusy}></Spinner>

                                        {
                                            selectedReach?.options && selectedReach.options.length > 0 && isBusy === false &&
                                            <div>
                                                <label htmlFor="matchOutcomes" className="form-label">Predict outcome</label>
                                                <div className="d-grid gap-0 d-md-block" id="matchOutcomes">
                                                    {
                                                        selectedReach?.options.map((option, index) => {
                                                            return selectedOption?.id === option.id ?
                                                                <div key={index}>
                                                                    <button onClick={(e) => handleOptionClick(option)} className={selectedOption?.id === option.id ? 'btn btn-secondary me-md-2  mb-2' : 'btn btn-outline-secondary me-md-2 mb-2'}>
                                                                        {option.name}
                                                                    </button>
                                                                    <div className={"mb-2 " + theme.background}>
                                                                        {
                                                                            // Use brags if no fixture risks are available
                                                                            selectedFixtureRisks.length === 0 &&
                                                                            option.brags.map((brag, index) => {
                                                                                return <button type="button"
                                                                                    className={selectedBrag?.id === brag.id ? 'btn btn-sm btn-secondary m-1' : 'btn btn-sm btn-outline-secondary m-1'}
                                                                                    onClick={(e) => handleBragClick(brag.id)} key={index}>{brag.name}</button>
                                                                            })
                                                                        }
                                                                        {
                                                                            // Use selected fixture risks if available
                                                                            selectedFixtureRisks.filter(r => r.optionId === option.id).map((risk, index) => {
                                                                                let band = 'success'
                                                                                if(risk.odds > selectedOptionBand) band = 'warning'
                                                                                if(risk.odds >= 2 * selectedOptionBand) band = 'danger'
                                                                                return <button type="button"
                                                                                    className={selectedBrag?.id === risk.bragId ? 'btn btn-secondary m-1' : 'btn btn-outline-secondary m-1'}
                                                                                    onClick={(e) => handleBragClick(risk.bragId)} key={index}>{risk.bragName} <span className={"ms-1 text-" + band}>&#x25cf;</span></button>                                                                  
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                                :
                                                                <button onClick={(e) => handleOptionClick(option)} className={selectedOption?.id === option.id ? 'btn btn-secondary me-md-2 mb-2' : 'btn btn-outline-secondary me-md-2 mb-2'} key={index}>
                                                                    <div className="d-flex justify-content-between">
                                                                        <div className={"flex-fill text-" + (selectedFixtureRisks.length > 0 ? 'start' : 'center')}>{option.name}</div>
                                                                        {
                                                                            selectedFixtureRisks.length > 0 &&
                                                                            <div className="ms-2">
                                                                                <span className="text-success">&#x25cf;</span>
                                                                                <span className="text-warning">&#x25cf;</span>
                                                                                <span className="text-danger">&#x25cf;</span> 
                                                                            </div>
                                                                        }                                                            
                                                                        
                                                                    </div>
                                                                </button>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                        
                                        
                                        {
                                            selectedReach && selectedReach.options?.length > 0 && selectedFixtureRisks.length > 0 &&
                                            <div className="text-center">
                                                <span className="fs-6"><span className="fs-6 text-success">&#x25cf;</span> Safe</span> 
                                                <span className="fs-6"><span className="ms-2 fs-6 text-warning">&#x25cf;</span> Daring</span>
                                                <span className="fs-6"><span className="ms-2 fs-6 text-danger">&#x25cf;</span> Risky</span>
                                            </div>
                                        }
                                        
                                    </div>
                                }
                            </div>
                        }

                    </div>
                </Modal.Body>
            </Modal>

            <ConfirmBanter showConfirmBanter={showConfirmBanter}
                setShowConfirmBanter={setShowConfirmBanter}
                updateRecentBanters={startBanter.updateRecentBanters}
                composedBanter={composedBanter}
                setComposedBanter={setComposedBanter}
                setBanterPosted={startBanter.setBanterPosted} 
                />

        </ModalContext.Provider>        
    )
}