import { Link, useHistory } from 'react-router-dom'
import { useForm, SubmitHandler } from "react-hook-form"
import useData, { setCookie, Spinner } from "../utils/data"
import { useToast } from '../utils/toast'
import { login } from '../utils'
import { useEffect, useState } from 'react'
import Util from '../utils/util'
import { useTheme } from '../context/theme'
import Messages from '../utils/messages'
//import { usePopup } from '../utils/popup'
import FootballFriends from '../assets/scss/homeimages/fantastake_friends.jpg'

function Login(props:any) {

    const showLoginRequired = props.showPopup
    

    const themeContext = useTheme()
    const theme = themeContext.theme
    
    const toast = useToast()
    //const popup = usePopup()
    const data = useData()
    const history = useHistory()
    const util = Util()
    const messages =  Messages()
    const { register, handleSubmit, formState: { errors } } = useForm<login>()
    const emailPattern = util.emailRegex()
    //const dashboard = process.env.REACT_APP_DASHBOARD_PATH ? process.env.REACT_APP_DASHBOARD_PATH : "#"
    const passwordRegexp = util.passwordRegex()
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [isEmailVerified, setIsEmailVerified] = useState()
    const [loginError, setLoginError] = useState<string>()
    const showActionRequired = !isEmailVerified && isEmailVerified === false

    const loginRequiredMessage = messages.pageRequiresLogin

    const onSubmit: SubmitHandler<login> = model => {
        setLoginError('')
        data.post(process.env.REACT_APP_API_LOGIN, model, (resp: any) => {

            // If there's a failure like invalid credentials prevent continuation
            if(resp?.errors?.message[0]){
                toast.show(resp.errors.message[0], 'error')
                setLoginError(resp.errors.message[0])
                return;
            }

            // User signed in successfully, set token cookie
            setCookie('token', resp.data.token)
            setCookie('refreshToken', resp.data.refreshToken)

            // Get user's profile from token
            if (history.location.pathname === '/login') {
                // User came in normally from login page so go to banters
                history.replace('/banters')
            }else{
                // Redirect user to their intended destination e.g banters/20
                let intendedDestination = window.location;                    
                window.location = intendedDestination
            }
            
        }, (result: any) => {
            if(!result) return false;
            if (result.errors && result.errors.message) {
                toast.show(result.errors.message[0], 'error')
                setLoginError(result.errors.message[0])
            }
        })
    }

    useEffect(()=>{

        // Add meta tag to prevent search engines from indexing
        // this login page and any other pages it leads to
        let meta = document.createElement('meta')
        meta.name = "robots"
        meta.content = "noindex"
        document.getElementsByTagName('head')[0].appendChild(meta);
        
        // Set page title
        document.title = process.env.REACT_APP_WEBSITE_TITLE + util.titleSeparator() + "Login"
    })

    useEffect(()=>{

        let locationState : any = history.location.state
        if(locationState) {
            setIsEmailVerified(locationState[0]?.isEmailVerified)
        }

        if(showLoginRequired){
            //popup.show(messages.pageRequiresLogin)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (        
        <>        
            {
                (!showLoginRequired || showActionRequired) &&
                <div style={{height:250}} className="d-flex align-items-end rounded-bottom rounded-5 mb-5 beginchallenge d-md-none">
                    <div className="border border-0 w-100" style={{height:'45%', backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(14,12,14,0.8), rgba(14,12,14,1))'}}></div>
                </div>
            }

            <div className="container-fluid">

                <div className="row mb-5">

                    <div className="col-md-6 d-none d-md-flex">

                        <div className="border border-0 rounded-4 flex-fill m-3" style={{backgroundImage:`url(${FootballFriends})`, backgroundSize:'cover', backgroundPosition:'center'}}>
                        </div>

                    </div>

                    <div className="col-md-6 p-md-5 pb-md-0 border border-0">

                        <div className="ps-lg-5 pe-lg-5">

                        {
                showLoginRequired && !showActionRequired &&
                <div className="alert alert-warning lh-1" role="alert">
                    <small>{ loginRequiredMessage } If this is your first time, <Link to="/signup" className='text-primary'>sign up easily</Link>.</small>
                </div>
            }

                            <div className="p-md-5 pb-md-0">
                                <h1 className={"mb-5 text-light " + theme.color}>Login</h1>
                                <p className={"lh-1 mb-4 " + (isEmailVerified !== undefined && isEmailVerified === false ? 'd-none' : '')}>
                                    Login to your account
                                </p>
                                {
                                    !isEmailVerified && isEmailVerified === false && <div className="alert alert-warning lh-1" role="alert">
                                        <small>{ messages.activationRequired }</small>
                                    </div>
                                }
                                <form className={"pb-5 " + (isEmailVerified !== undefined && isEmailVerified === false ? 'd-none' : '')} onSubmit={handleSubmit(onSubmit)}>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input autoFocus={true} defaultValue="" type="email" {...register("username", {
                                            required: 'Email is required',
                                            pattern: { value: emailPattern, message: 'Enter a valid email address' }
                                        })} maxLength={150} className={"form-control form-control-lg " + theme.background + ' ' + theme.color} id="email" placeholder="Enter your email" />
                                        {errors.username && <small className="text-danger">{errors.username.message}</small>}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">
                                            Password
                                        </label>
                                        <div className="input-group">
                                            <input defaultValue="" type={passwordVisible ? 'text' : 'password'} {...register("password", {
                                                required: 'Password is required', minLength: { value: 8, message: 'Password must be at least 8 characters' },
                                                pattern: { value: passwordRegexp, message: 'Password must contain at least one uppercase, lowercase, digit and special character' }
                                            })} maxLength={150} className={"form-control form-control-lg border border-end-0 " + theme.background + ' ' + theme.color} id="password" placeholder="Enter your password" />
                                            <label className={"input-group-text border border-start-0 " + theme.background} htmlFor="password">
                                                <Link to="#" onClick={()=>setPasswordVisible(!passwordVisible)}>{passwordVisible ? <i className={"bi bi-eye " + theme.color} aria-hidden="true" /> : <i className={"bi bi-eye-slash " + theme.color} aria-hidden="true" />}</Link>
                                            </label>
                                        </div>                    
                                        {errors.password && <small className="text-danger">{errors.password.message}</small>}
                                    </div>
                                    <div className="mb-2 lh-1">
                                        I <Link to="/forgot">forgot</Link> my password
                                    </div>
                                    <div className="text-danger fs-5" style={{minHeight:50}}>
                                        { loginError }
                                    </div>
                                    <div className="d-grid gap-2 mb-5">
                                        <button type="submit" className="btn btn-lg btn-primary" disabled={data.isBusy()}>
                                            Login <Spinner visibility={data.isBusy()} isCentered={false} />
                                        </button>
                                    </div>
                                    <p className="mb-5 text-center lh-1">No account yet? <Link to="/signup">Sign up</Link></p>
                                </form>
                            </div>

                        </div>

                    </div>

                </div>
                                
                
            </div>
        </>
    )
}

export default Login