import { useEffect } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTheme } from "../context/theme";
import useLocalStorage from "../utils/storage";

export default function Settings(props:any) {

    const themeContext = useTheme()
    const theme = themeContext.theme

    let showSettings = props.showSettings
    let setShowSettings = props.setShowSettings    

    const [selectedTheme, setSelectedTheme] = useLocalStorage('selectedTheme', themeContext.selectedTheme)

    const closeModal = () => {
        if (setShowSettings){
            setShowSettings(false)
        }
    }

    const switchTheme = () => {

        if(themeContext.toggleTheme) {
            let selectedTheme = themeContext.toggleTheme()
            setSelectedTheme(selectedTheme)
        }
        closeModal()
    }

    useEffect(()=>{

        // Update html and body tag classes here since 
        // these tags are in index.html and do not belong in any component

        const root = document.documentElement;
        const body = root.querySelector('body') as HTMLElement
        root.setAttribute("class", theme.background + ' ' + theme.color)
        body.setAttribute("class", theme.background + ' ' + theme.color)

    }, [selectedTheme, theme])

    return (
        <Modal show={showSettings} onHide={() => closeModal()} contentClassName={theme.background} className="p-2" centered>
            <Modal.Header>
                <Modal.Title className="p-4">Settings</Modal.Title>
                <CloseButton className={theme.name === 'dark' ? 'btn-close-white' : ''} onClick={()=>closeModal()} />
            </Modal.Header>
            <Modal.Body className={"p-5 pt-4 " + theme.background}>
                <ul className="list-unstyled text-center">
                    <li className="mb-4 mt-3">
                        <Link to="#" onClick={ ()=>switchTheme() }>Switch theme</Link>
                    </li>
                    <li className="mb-2">
                        <Link to="/change" onClick={()=>closeModal()}>Change password</Link>
                    </li>
                    <li className="mb-2 d-none">
                        <Link to="#">Notification preference</Link>
                    </li>
                </ul>
            </Modal.Body>
        </Modal>
    )
    
}