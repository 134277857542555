import { Switch, Route, useLocation, Link } from 'react-router-dom'
import Home from './home/index'
import Dashboard from './member/dashboard'
import Signup from './auth/signup'
import Login from './auth/login'
import ToastProvider from './utils/toast'
import Forgot from './auth/forgot'
import Reset from './auth/reset'
import Fund from './wallet/fund'
import Topup from './wallet/topup'
import Store from './store/store'
import WalletAdmin from './admin'
import { useLayoutEffect, useState } from 'react'
import Contests from './cliques/contests'
import Banters from './banter/banters'
import HeroesLeague from './home/heroesleague'
import Footer from './home/footer'
import ChangePassword from './auth/change'
import ThemeProvider, { useTheme } from './context/theme'
import AuthProvider from './context/auth'
import { Form, Nav, Navbar, Offcanvas } from 'react-bootstrap'
import useData from './utils/data'
import Settings from './home/settings'
import FantastakeTextLogoDark from './assets/logos/fantastake_logo_text_dark.png'
import FantastakeTextLogoLight from './assets/logos/fantastake_logo_text_light.png'
import { Themes } from './utils/enum'
import Terms from './home/terms'
import Profile from './home/profile'
import PopProvider from './utils/popup'
import FavTeamProvider from './home/favteam'
import RedeemProvider from './wallet/redeem'
import StartBanterProvider from './banter/startbanter2'
import AccoladesProvider from './member/accolades'
import CliqueInviteProvider from './cliques/cliqueinvite'
import CliqueDetailsProvider from './cliques/cliquedetails'
import StartContest from './cliques/startcontest'
import BuyCoinsProvider from './wallet/buy'
import JoinContestProvider from './cliques/joincontest'

export default function App() {
  const location = useLocation()

  const themeContext = useTheme()
  const theme = themeContext.theme
  const data = useData()
  const isAuthenticated = data.getCookie('token')
  const dashboard = process.env.REACT_APP_DASHBOARD_PATH ? process.env.REACT_APP_DASHBOARD_PATH : "#"
  const [showSettings, setShowSettings] = useState(false)
  const [showMenu, setShowMenu] = useState(false)

  let FantastakeLogo = FantastakeTextLogoDark
  if(themeContext.selectedTheme === Themes.Dark) FantastakeLogo = FantastakeTextLogoLight

  // scroll to top if path changes p-3 pb-0 pt-5 p-lg-5 pb-lg-0
  useLayoutEffect(() => {
    
    // Remove robots noindex meta tag if present
    if(document.getElementsByName('robots')[0]){
      document.getElementsByTagName('head')[0].removeChild(document.getElementsByName('robots')[0])
    }

    window.scrollTo({ top:0, behavior: 'smooth'})
  }, [location.pathname])

  return (
    <div>
      <div className="container">
        <div className="row mb-5">
          <div className="col-12 pb-0 pt-lg-5 ps-lg-0 pe-lg-0 pb-lg-0 mb-5">
            <Navbar expand="lg" className={"fixed-top p-3 bg-" + theme.name} variant={theme.name} style={{borderBottom:'1px solid #353535'}}>
              <Navbar.Brand href="#" className="fs-3 fw-bold pt-0 pb-0">
                <img src={FantastakeLogo} className="fantastakelogo" alt="Fantastake logo" loading="lazy" />
              </Navbar.Brand>
              <Navbar.Toggle onClick={()=>setShowMenu(true)} aria-controls={`offcanvasNavbar-expand-lg`} />
              <Navbar.Offcanvas show={showMenu} id={`offcanvasNavbar-expand-lg`} aria-labelledby={`offcanvasNavbarLabel-expand-lg`} placement="start" onHide={()=>setShowMenu(false)}>
                {
                  theme.name === 'dark' &&
                  <Offcanvas.Header className={theme.background} closeVariant="white" onHide={()=>setShowMenu(false)} closeButton>
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                }
                {
                  theme.name === 'light' &&
                  <Offcanvas.Header className={theme.background} onHide={()=>setShowMenu(false)} closeButton>
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                }
                <Offcanvas.Body className={theme.background}>
                  <Nav className="flex-grow-1 pt-3 justify-content-end" onClick={()=>setShowMenu(false)}>
                    {isAuthenticated ? <Link className="nav-link" to={dashboard}>Home</Link> : <Link className="nav-link" to="/">Home</Link>}
                    <Link className="nav-link" to="/banters">Banters</Link>
                    <Link className="nav-link" to="/contests">Contests</Link>
                    <Link className="nav-link" to="/league">Heroes League</Link>
                    { isAuthenticated && <Link className="nav-link" to="/profile">Profile</Link> }
                    { isAuthenticated && <Link className="nav-link" to="#" onClick={()=>setShowSettings(true)}>Settings</Link> }
                    { !isAuthenticated && <Link className="m-2 ms-0 ms-lg-1 me-3 btn btn-primary ps-5 pe-5" to="/signup">Sign up</Link> }
                    { !isAuthenticated && <Link className="m-2 ms-0 me-3 btn btn-outline-primary ps-5 pe-5" to="/login">Login</Link> }
                  </Nav>
                  <Form className="d-none d-flex">
                    <Link to="#" onClick={() => {}} className="btn btn-lg btn-primary ps-4 pe-4 pt-2 pb-2">
                        Start a banter
                    </Link>
                  </Form>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Navbar>
          </div>
        </div>
      </div>      

      <ThemeProvider>
        <ToastProvider>

          <PopProvider>
            <AuthProvider>

              <Switch>
                
                <Route path="/admin/">
                  <div className="container-fluid"><WalletAdmin /></div>                                        
                </Route>
                <Route path="/contests">
                  <div className="container-fluid"><Contests /></div></Route>
                <Route path="/league">
                  <div className="container-fluid"><HeroesLeague /></div></Route>
                <Route path={["/banters/tabs/:tab/:refresh?", "/banters/action/:action", "/banters/:banterRef", "/banters"]}>
                  <div className="container-fluid"><Banters /></div></Route>
                <Route path="/store">
                  <div className="container-fluid"><Store /></div></Route>
                <Route path="/topup">
                  <div className="container-fluid"><Topup /></div></Route>
                <Route path="/fund">
                  <div className="container-fluid"><Fund /></div></Route>
                <Route path={["/iv/c/:invitecode", dashboard + "/:activation", dashboard]}>
                  <div className="container-fluid"><Dashboard /></div></Route>
                <Route path="/reset/:code">
                  <div className="container-fluid"><Reset /></div></Route>
                <Route path={["/login"]}>
                  <Login /></Route>
                <Route path="/forgot">
                  <div className="container-fluid"><Forgot /></div></Route>
                <Route path="/change">
                  <div className="container-fluid"><ChangePassword /></div></Route>
                <Route path={["/signup/:refer", "/signup"]}>
                  <>
                    <Signup />
                  </>
                </Route>                                            
                <Route path="/terms/">
                  <div className="container-fluid"><Terms /></div></Route>
                <Route path="/profile/">
                  <div className="container-fluid"><Profile /></div></Route>
                <Route path={["/home/", "/" ]}>
                  <div><Home /></div></Route>
                
              </Switch>

              <FavTeamProvider></FavTeamProvider>

              <RedeemProvider></RedeemProvider>

              <StartBanterProvider></StartBanterProvider>

              <AccoladesProvider></AccoladesProvider>

              <CliqueInviteProvider></CliqueInviteProvider>
              
              <CliqueDetailsProvider></CliqueDetailsProvider>
              
              <StartContest></StartContest>

              <BuyCoinsProvider></BuyCoinsProvider>

              <JoinContestProvider></JoinContestProvider>

            </AuthProvider>

          </PopProvider>          

        </ToastProvider>        

      </ThemeProvider>      
      
      <Settings showSettings={showSettings} setShowSettings={setShowSettings} />

      <Footer />
    
    </div>

  );
}
