
type messages = {
    loginRequired: any,
    activationRequired: any,
    aboutCoins: any,
    pageRequiresLogin: any,
    emailNotVerified: any,
    soonerFixtureTip: any,
    bankRequiredInfo: any,
    howDoesCouponWork: any,
}

export default function Messages() : messages {

    return {
        loginRequired: 'This page requires you to login.',
        activationRequired : <>Action Required. Check your <strong>inbox</strong>, <strong>promotion</strong> or <strong>spam</strong> folders for your confirmation link.</>,
        aboutCoins: 'Choose an option to redeem your coins.',
        pageRequiresLogin: <>The page you're trying to visit requires you to login.</>,
        emailNotVerified : <>Your sign up is not complete yet. To complete it, check your <strong>inbox</strong>, <strong>promotion</strong> or <strong>spam</strong> folders for your email verification link.</>,
        soonerFixtureTip: <>Start banters on an early upcoming match so that you can receive points soon after the match is played.</>,
        bankRequiredInfo:<div><div className="mb-3">This info is required to get you a suitable coupon vendor. Let us know if you don't see your bank and we will add it.</div> </div>,
        howDoesCouponWork: <div><p>1. You will receive a coupon code.</p> <p>2. Send the coupon code to your vendor.</p> <p>3. Coupon Vendors are third parties ready to redeem your coupons.</p> <p>4. Redemption charges apply.</p> <p className="fs-6">Before you begin, save the Fantastake WhatsApp self-service line to your contacts: <span className="fs-5">+23057745933</span></p></div>

    }
}