import { useEffect, useState } from "react"


export default function useRecaptcha(){
    const [loaded, setLoaded] = useState(false)
    const src = "https://www.google.com/recaptcha/api.js?render=6LfgNDAgAAAAAJPJTlPj01d7qFBGOMrAA1otgrAu"

    const getLoaded = ()=>{
        return loaded
    }

    useEffect(() => {

        let script : HTMLScriptElement;
        let existingScript = document.getElementById('recaptchaScript')

        if(!existingScript){
            script = document.createElement('script')
            script.id = 'recaptchaScript'
            script.src = src
            script.async = true
            script.addEventListener('load', ()=>{ setLoaded(true) })
            script.addEventListener('error', ()=>{ console.log('Failed to load recaptcha.')})
            document.body.appendChild(script)
        }

        // Cleanup recaptcha script and div
        return()=>{

            if(script){
                script.removeEventListener('load',()=>{})
                script.removeEventListener('error', ()=>{})            
                document.body.removeChild(script)
                delete window.grecaptcha

                // Remove div containing recaptcha
                let recaptchaNode = document.getElementsByClassName('grecaptcha-badge')[0]
                if(recaptchaNode){
                    if(recaptchaNode.parentNode) document.body.removeChild(recaptchaNode.parentNode)
                }
            }
        }

    }, [])

    return { isRecaptchaLoaded : getLoaded };
}