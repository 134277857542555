import { CloseButton, Modal } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTheme } from "../context/theme"
import { Model } from "../utils"

export default function FindBanter(props:any){

    const themeContext = useTheme()
    const theme = themeContext.theme

    const pageSize = 5
    const fetchBanters = props.fetchBanters
    const setQuery = props.setQuery
    const setShowFindBanter = props.setShowFindBanter
    
    const { register, handleSubmit, formState: { errors } } = useForm<Model>()
    //const query = watch("query")
    // const banterStatuses: banterStatus[] = [
    //     { id: 1, name: 'Promoted banters', rank: 0 },
    //     { id: 2, name: 'Banters I challenged', rank: 0 },
    //     { id: 3, name: 'Banters I supported', rank: 0 }
    // ]

    const onSubmit: SubmitHandler<Model> = model => {
        // fetch banters
        fetchBanters(0, pageSize, model.query)
        setShowFindBanter(false)
        setQuery(model.query)
    }


    return (
        <Modal show={props.showFindBanter} onHide={()=>props.setShowFindBanter(false)} contentClassName={theme.background} className="p-2" centered>
             <Modal.Header>
                <Modal.Title className="p-4">Search banters</Modal.Title>
                <CloseButton className={theme.name === 'dark' ? 'btn-close-white' : ''} onClick={()=>props.setShowFindBanter(false)} />
            </Modal.Header>
            <Modal.Body className="ps-5 pe-5 pb-4 pt-4">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                        <label htmlFor="query" className="form-label">Find a banter</label>
                        <div className="input-group">
                            <input type="text" className={"form-control form-control-lg " + theme.background + ' ' + theme.color} {...register("query")} id="query" maxLength={150} aria-describedby="searchHelp" placeholder="" />
                            <button className="btn btn-outline-secondary" type="submit" id="button-addon2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                            </button>
                        </div>
                        {errors.query && <small className="text-danger">{errors.query.message}</small>}
                        <div id="searchHelp" className="form-text"><i>For example search by team name</i></div>
                    </div>
                    {/* <div className="mb-3">
                        <label htmlFor="categoryId" className="form-label">Show only</label>
                        <select className={"form-control form-control-lg " + theme.background + ' ' + theme.color} id="categoryId" {...register("categoryId")}>
                            <option value={0}>- All banters -</option>
                            {
                                banterStatuses.map((banterStatus, index) => {
                                    return <option value={banterStatus.id} key={index}>{banterStatus.name}</option>
                                })
                            }
                        </select>
                    </div> */}
                </form>
            </Modal.Body>
        </Modal>
        
    )
}