import React, { useContext } from "react"
import { Themes } from "../utils/enum"

export const themes = {
    dark:{
        name: 'dark',
        background : 'bg-dark',
        color : 'text-secondary',
        link: 'light'
    },
    light: {
        name: 'light',
        background : 'bg-white',
        color: 'text-dark',
        link: 'dark'
    }
}

let selectedTheme = Themes.Dark
let defaultTheme = themes.dark

let userThemePreference = window.localStorage.getItem('selectedTheme')
if(userThemePreference) selectedTheme = parseInt(userThemePreference) as Themes

// Configure theme context based on user theme preference

const configureTheme = (selectedTheme:Themes)=>{

    let themeContext = {
        selectedTheme : selectedTheme,
        theme : defaultTheme,    
        toggleTheme : ()=>{
            if(themeContext.selectedTheme === Themes.Dark) {
                themeContext.theme = themes.light
                themeContext.selectedTheme = Themes.Light
            }else{
                themeContext.theme = themes.dark
                themeContext.selectedTheme = Themes.Dark
            }

            window.localStorage.setItem('selectedTheme', themeContext.selectedTheme.toString())
            
            return themeContext.selectedTheme
        }
    }
    
    if(selectedTheme === Themes.Dark) {
        themeContext.theme = themes.dark
        themeContext.selectedTheme = Themes.Dark        
    }else{
        themeContext.theme = themes.light
        themeContext.selectedTheme = Themes.Light        
    }
    
    return themeContext
}

let themeContext = configureTheme(selectedTheme)

// Create the theme context

const ThemeContext = React.createContext(themeContext)

// Custom hook for ThemeProvider

export const useTheme = ()=>{    
    return useContext(ThemeContext)
}

export default function ThemeProvider( { children } : any){

    return (
        <ThemeContext.Provider value={themeContext}>
            { children }
        </ThemeContext.Provider>
    )
}