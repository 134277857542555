import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm, SubmitHandler } from "react-hook-form"
import { useToast } from "../utils/toast"
import useData, { Spinner } from "../utils/data"
import { useTheme } from '../context/theme'
import Util from '../utils/util'
import FootballFriends from '../assets/scss/homeimages/fantastake_friends.jpg'

type forgotPassword = {
    email: string,
}

export default function Forgot() {

    const themeContext = useTheme()
    const theme = themeContext.theme
    
    const data = useData()
    const toast = useToast()
    const util = Util()
    const { register, handleSubmit, formState: { errors } } = useForm<forgotPassword>()
    const emailPattern = util.emailRegex()
    //const dashboard = process.env.REACT_APP_DASHBOARD_PATH ? process.env.REACT_APP_DASHBOARD_PATH : "#"
    const [sent, setSent] = useState(false)

    const onSubmit: SubmitHandler<forgotPassword> = model => {
        data.post(process.env.REACT_APP_API_FORGOTPASSWORD, model, (resp: any) => {
            if (resp.data) {
                // Hide password reset form
                setSent(true)
            } else {
                if (resp.message) toast.show(resp.message, 'error')
            }
        }, (result: any) => {
            if (result) {
                toast.show(result.message)
            }
        })
    }

    return (

        <>
            <div style={{height:250}} className="d-flex align-items-end rounded-bottom rounded-5 mb-5 beginchallenge d-md-none">
                <div className="border border-0 w-100" style={{height:'45%', backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(14,12,14,0.8), rgba(14,12,14,1))'}}></div>
            </div>

            <div className="container-fluid">

                <div className="row mb-5">

                    <div className="col-md-6 d-none d-md-flex">

                        <div className="border border-0 rounded-4 flex-fill m-3" style={{backgroundImage:`url(${FootballFriends})`, backgroundSize:'cover', backgroundPosition:'center'}}>
                        </div>

                    </div>

                    <div className="col-md-6 p-md-5 pb-md-0 border border-0">

                        <div className="ps-lg-5 pe-lg-5">

                            <div className="mb-5">
                                <h1 className={"mb-5 text-light " + theme.color}>Forgot password</h1>

                                {
                                    !sent ?

                                        <>
                                            <p className="mb-4 lh-1">
                                                Please enter the same email you used to sign up
                                            </p>
                                            <form className="pb-5" onSubmit={handleSubmit(onSubmit)}>
                                                <div className="mb-3">
                                                    <label htmlFor="email" className="form-label">Email</label>
                                                    <input defaultValue="" type="email" {...register("email", {
                                                        required: 'Your email used to sign up is required',
                                                        pattern: { value: emailPattern, message: 'Enter a valid email address' }
                                                    })} maxLength={150} className={"form-control form-control-lg " + theme.background + ' ' + theme.color} id="email" placeholder="Enter your email" />
                                                    {errors.email && <small className="text-danger">{errors.email.message}</small>}
                                                </div>
                                                <div className="mb-4 lh-1">
                                                    <span>If you remember your password now, you can <Link to="/login">go back to login</Link></span>
                                                </div>
                                                <div className="d-grid gap-2">
                                                    <button type="submit" className="btn btn-lg btn-primary" disabled={data.isBusy()}>
                                                        Request a reset <Spinner visibility={data.isBusy()} isCentered={false} />
                                                    </button>
                                                </div>
                                            </form>
                                        </>

                                        :

                                        <>

                                            <p className="text-success">
                                                A password reset link has just been sent to your email.
                                            </p>
                                            <p className="mb-5">
                                                <small>
                                                    Can't find it in your inbox? Sometimes, relevant emails go into spam or promotion folders, so check those too.
                                                </small>                            
                                            </p>
                                        </>
                                }

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
        
    )
}