import React, { useContext, useState } from "react"
import { CloseButton, Modal } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useTheme } from "../context/theme"
import { award, profile } from '../utils'
import Util from "../utils/util"


// const init = {
//     visible : false,
//     hide : ()=>{},
//     show: (profile:any)=>{ 
//         console.log('tapped')
//         init.visible = true
//         return profile
//     },
// }

type initType = {
    visible:boolean,
    profile?: profile,
    show: Function,
    hide:Function
}

const init:initType = {
    visible: false,
    show: (profile : profile | undefined)=>{},
    hide: ()=>{}
}

const ModalContext = React.createContext(init)

// Custom hook for the component

export const useAccolades = () => {
    return useContext(ModalContext)    
}

const useInit = ()=>{

    const [visible, setVisible] = useState(false)    

    init.visible = visible
    

    init.show = (profile:any) => { 

            setVisible(true)
            //setProfile(profile)

            init.profile = profile
        }
    init.hide = ()=>{
        setVisible(false)
    }

    return init
}

export default function AccoladesProvider({ children }: any){

    const themeContext = useTheme()
    const theme = themeContext.theme
    const util = Util()

    const init = useInit()
    let avatar = ''
    if(init.profile){
        avatar = process.env.REACT_APP_API_AVATARBASEURL + util.appendSex(init.profile.sex) + init.profile.avatarFile + '.svg?background=%231C1A1E&mouth=variant05'
    }

    let trophyChest = 0
    const awards : award[] = init.profile?.awards ?? []
    trophyChest = awards.reduce((accumulator, current)=> accumulator + current.walletReward, trophyChest) / 100

    return (
        <ModalContext.Provider value={init}>

            { children }

            <Modal show={init.visible} onHide={()=>init.hide()} contentClassName={"border border-secondary " + theme.background} className="p-1 pb-0" centered>
                {
                    init.profile && init.profile.firstName && 
                    <Modal.Header>
                        <CloseButton className={'mt-1 ' + (theme.name === 'dark' ? 'btn-close-white' : '')} onClick={()=>init.hide()} />
                    </Modal.Header>
                }
                
                <Modal.Body className="p-5 pt-0 pb-5">

                    {
                        init && init.profile &&
                        <div className="d-flex align-items-center mb-5">
                            <Link to="/profile">
                                <div className="me-2 bg bg-primary fw-bold text-light border border-primary rounded-circle d-flex justify-content-center align-items-center" 
                                    style={{background: `url(` + avatar + `)`, cursor: 'pointer', width:50, height:50, backgroundSize:'cover', backgroundRepeat: 'no-repeat', backgroundPosition:'center'}}>
                                    { 
                                        !avatar && <i className="bi bi-person-fill fs-1"></i>
                                    }
                                </div>
                            </Link>                                
                            
                            <div className={"mt-1 d-flex flex-column text-light " + theme.color}>
                                <Link to="/profile">
                                    <div className="fs-3 lh-1">
                                        { init && init.profile && init.profile.firstName } 
                                    </div>
                                </Link>
                                {
                                    init.profile.favouriteTeam && <Link to="#" className="lh-1 ellipsis"><span className="fs-6">{init.profile.favouriteTeam.name}</span></Link>
                                }
                            </div>
                        </div>
                    }

                    {
                        init.profile &&
                        <div className="d-grid gap-2 mb-4">
                            {
                                init.profile.position !== undefined &&
                                <div className="d-flex justify-content-between">
                                    <div>Position</div>
                                    <div>{init.profile.position}</div>
                                </div>
                            }
                            <div className="d-flex justify-content-between">
                                <div>Banters</div>
                                <div>{init.profile.board?.bantersStarted}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>Shares</div>
                                <div>{init.profile.board?.bantersShared}</div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>Trophies</div>
                                <div>{awards.length} <i className="bi bi-trophy fs-5 text-warning"></i></div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>Trophy Chest</div>
                                <div>{trophyChest.toLocaleString()} <i className="bi bi-coin fs-5 text-warning"></i></div>
                            </div>                                                        
                        </div>
                        
                    }
                    
                </Modal.Body>

            </Modal>            

        </ModalContext.Provider>
    )

}